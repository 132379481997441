import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
// import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
// import BusinessUserSideBar from "../components/container/SideBar";
// import ClientSideBar from "../components/container/layout/client/SideBar";
// import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';

import Headerweb from './Headerweb';
import FooterWeb from './Footerweb';
import Topmove from './Topmove';
// import ProfileImage3 from './assets/images/profile3.png';
// import myProfilePic from '../../../assets/images/profile3.png'; 

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class About extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
            <div className="">

                <div className="">
                    {/* <section className="my-head"> */}
                        {/* <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div> */}
                    {/* </section> */}
                    <div className="">
                        {/* <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div> */}

                        {/* <section className="main-body hospital-profilegap "> */}
                            {/* <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            /> */}

                            {/* <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} /> */}

                            {/* <div className="container-fluid checkout-tabs"> */}
                                {/* <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                /> */}

                                {/* <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} /> */}

<div className=''>
    <Headerweb/>
<div className='sub-banner text-center'>
                <h1>About Us</h1>
         
            </div>
            <hr className='m-0' style={{ color: '#D9F4FF' }} />
            <section className='about-section' >
                <div className='container-90 vision-section py-5' id='our_vision'>
                    <div className='row m-0 position-relative'>
                        <div className='col-lg-6 col-12 slide-left visible'>
                            <h1>Our Vision & Mission</h1>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/about1.png")} className='w-75 h-75 ml-5 mt-5 d-lg-none d-block' />
                            </div>
                            <h5>Mission</h5>
                            <p>Nucleo Software Solutions development methodology advocates a result-driven collaborative approach and ensures the best customer service in terms of quality, on-time delivery, and cost.
                                Nucleo Software Solutions continuously improves methodology to meet customer expectations by providing high-quality results and an enhanced delivery approach to improve responsiveness, market challenges, and competition for the business</p>
                            <h5>Vision</h5>
                            <p>Our goal is to add business value to customers in Information Technology </p>
                        </div>
                        <div className='col-lg-6 col-12 ps-5 '>
                            <img src={require("../../src/assets/images/website/about1.png")} className='w-75 h-75 ml-5 mt-3 d-lg-block d-none' />
                            {/* <div className='total-vision'>
                                <img src={vision1} />
                            </div> */}
                            {/* <h2>25</h2>
                            <h3>Years of Experience</h3> */}
                        </div>
                    </div>
                </div>
                <hr className='m-0' style={{ color: '#D9F4FF' }} />
                <div className='company-section container-90' id="company_profile">
                    <div className='row m-0'>
                        <div className='col-lg-6 col-12 slide-left visible'>
                        <img src={require("../../src/assets/images/website/company.png")} className='w70 h70 ml-5 mt-50 d-lg-block d-none' alt="Profile" />
                        </div>
                        <div className='col-lg-6 col-12 slide-right visible content-company ps-lg-5 ps-0'>
                            <h1>Company Profile</h1>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/company.png")} className='w70 h70 ml-5 mt-50 d-lg-none d-block' />
                            </div>
                            <p>Nucleo Software Solutions is an IT firm providing business consulting and Information Technology services. It consistently delivers the best value in empowered business solutions in the life sciences and healthcare industries.</p>
                            <p>Nucleo Software Solutions is a pioneer in translating advanced technologies and sophisticated theories into workable solutions and creating business value to benefit its valuable customers.</p>
                            <p>It provides cost-effective solutions to healthcare industries in implementing business solutions that meet diverse client needs in this modern age of converging technologies. The business community desires to harness the power of technology for the benefit of business and people.</p>
                            <p>Nucleo Software Solutions provides services with proven and time-tested enterprise solutions that best meet specific organizational needs. </p>
                        </div>
                    </div>
                </div>
                <div className='company-section container-90' id='about_product'>
                    <div className='row m-0'>
                        <div className='col-lg-6 col-12 slide-left visible content-company'>
                            <h1>About KlinicQ</h1>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/profile2.png")}  className='w70 h70 ml-5 mt-50 d-lg-none d-block' />
                            </div>
                            <p>	KlinicQ is our Electronic Clinical Management System (e CMS) application. It is user-friendly, convenient, comprehensive, and integrated to manage all hospital operations safely and with no manual error usually associated with handwriting & paper-based records.</p>
                            <p>KlinicQ application has been developed based on the best practices around the world. KlinicQ algorithm is designed to solve a fundamental and global human problem, which is wasting time waiting in line. The convenience of saving time is one of the best aspects of modern technology. It provides a solution to the outdated practice of waiting in line. </p>
                            <p>KlinicQ application helps to organize the doctor schedules, collate patient notes, health history, medical records, patient records, and other critical metrics in real-time, and handling payment is effortless. Patients can find doctors based on their specialization and book online appointments through mobile apps, phone calls, or walk-ins based on specialty and waiting time. </p>
                        </div>
                        <div className='col-lg-6 col-12 slide-right visible'>
                            <img src={require("../../src/assets/images/website/profile2.png")} className='w70 h70 ml-5 mt-50 d-lg-block d-none' />
                        </div>
                    </div>
                </div>
                <div className='company-section container-90'>
                    <div className='row m-0'>
                        <div className='col-lg-6 col-12 slide-left visible'>
                            <img src={require("../../src/assets/images/website/profile3.png")} className='w70 h70 ml-5 mt-45 d-lg-block d-none' />
                        </div>
                        <div className='col-lg-6 col-12 slide-right visible content-company'>
                            <h1>Why KlinicQ ?</h1>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/profile3.png")} className='w70 h70 ml-5 mt-50 d-lg-none d-block' />
                            </div>
                            <p>Doctors and Patients can check their schedules, and it improves the quality of service by minimal waiting time and accessing the medical records electronically. </p>
                            <p>KlinicQ application can be accessed through the cloud on any type of mobile device anywhere & anytime and on desktop at on-premises. </p>
                            <p>KlinicQ flawlessly integrates with existing appointments with walk-in patients to eliminate he scheduling gaps when appointments are canceled or rescheduled. </p>
                            <p>KlinicQ' s appointment scheduling solutions where appointments that are updated automatically so that every patient arriving late will be automatically pushed back in the queue. Medical records can be accessed from any place & any time.  </p>
                        </div>
                    </div>
                </div>
                {/* <div className='container-90 time-line position-relative d-lg-block d-md-block d-none'>
                    <h1 className='py-5 text-center'>Product Road Map</h1>
                    <div className='time-content'>
                    <img src={require("../../src/assets/images/website/time.png")} className='w-100' alt="Timeline" />
                        <h1 className='year1 slide-left visible'>2022</h1>
                        <div className='content1 slide-left visible'>
                            <p>KlinicQ web application for Clinical management system is developed</p>
                        </div>

                        <h1 className='year2 slide-left visible'>2023</h1>
                        <div className='content2 slide-left visible'>
                            <p>KlinicQ andorid & IOS application is developed for Physician, Hospital & Doctors</p>
                        </div>

                        <h1 className='year3'>2024</h1>
                        <div className='content3'>
                            <p>In-pateint, Pharmacy & Laboratory application will be deployed</p>
                        </div>

                        <h1 className='year4 slide-right visible'>2025</h1>
                        <div className='content4 slide-right visible'>
                            <p>e-Commerce application for Hospitals, Pharmacy & Laboratory</p>
                        </div>

                        <h1 className='year5 slide-right visible'>2026</h1>
                        <div className='content5 slide-right visible'>
                            <p>One in All solution for healthcare services</p>
                        </div>

                    </div>

                </div> */}
            </section>


                                {/* <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="font-weight-bold">QR Code</h4>
                                    </div> */}


                                {/* </div> */}

                                {/* <div className="card pt-4">
                                    <div className="card-body">
                                        <div className="tab-content text-center" id="v-pills-tabContent">
                                            <h5 className="hedtxt2 ">Open the built-in camera app and point the camera at the QR Code Scanner for Check-in purposes.</h5>

                                            <div
                                                className="tab-pane fade show active qrcode pd-15"
                                                id="v-pills-shipping"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-shipping-tab"

                                            >


                                                <QRCode value={this.props.userdata.phone_number} />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <Footerad /> */}
                            {/* </div>
                          
                        </section> */}
                         <Topmove />
                        <FooterWeb className="qrCodeFooter" />
                    </div>
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount = () => {
     /*    console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(About);
