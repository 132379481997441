import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import logo from '../assets/image/logo.webp';
// import '../assets/css/auth.css';
// import { setClass1Hide } from '../../Redux/CreateSlice';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight, faArrowRightArrowLeft, faBars, faChevronDown, faRightLeft, faXmark, faPlus, faMinus, faClose } from '@fortawesome/free-solid-svg-icons';



class Headerweb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSticky: false,
            isAboutView: false,
            isProductView: false,
            isBenifitView: false,
            isContactView: false,
            isSolutionView: false,
            isSolutionClinical: false,
            isSolutionAppointment: false,
            isExpanded: false,
            showMenu: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY >= 50) {
            this.setState({ isSticky: true });
        } else {
            this.setState({ isSticky: false });
        }
    };

    // navButton = () => {
    //     const { isClass1Show } = this.props;
    //     this.props.dispatch(setClass1Hide(!isClass1Show));
    // };

    toggleMenu = () => {
        this.setState(prevState => ({ showMenu: !prevState.showMenu }));
    };

    toggleDropdownAbout = () => {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded,
            isAboutView: !prevState.isAboutView,
        }));
    };

    toggleDropdownProduct = () => {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded,
            isProductView: !prevState.isProductView,
        }));
    };

    toggleDropdownBenifit = () => {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded,
            isBenifitView: !prevState.isBenifitView,
        }));
    };

    toggleDropdownContact = () => {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded,
            isContactView: !prevState.isContactView,
        }));
    };

    toggleDropdownSolution = (event) => {
        event.preventDefault();
        this.setState(prevState => ({
            isSolutionView: !prevState.isSolutionView,
        }));
    };

    toggleDropdownClinical = () => {
        this.setState(prevState => ({
            isSolutionClinical: !prevState.isSolutionClinical,
        }));
    };

    toggleDropdownAppointment = () => {
        this.setState(prevState => ({
            isSolutionAppointment: !prevState.isSolutionAppointment,
        }));
    };

    userAuth = () => {
        this.props.history.push('/login');
    };

    bookademo = () => {
        this.props.history.push('/bookdemo');
    };

    render() {
        const { isSticky, showMenu, isExpanded, isAboutView, isProductView, isBenifitView, isContactView, isSolutionView, isSolutionClinical, isSolutionAppointment } = this.state;
        const { isClass1Show } = this.props;

        return (
            <div className='header-section'>
            <header className={isSticky ? 'sticky-element' : 'nav-element'}>
                <div className='nav-section'>
                    <nav className="navbar navbar-expand-lg navbar-light bg-white">
                        <div className="container-fluid">
                            <div className='d-flex'>
                                <a className="navbar-brand klinic-logo" href="/testhome">
                                    <img src={require("../assets/images/logo.png")} alt="Sahyatri-Matrimony-Logo" className='nav-logo' />
                                </a>
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
                            {/* <div className='d-lg-none d-block'>
                                <div className='d-flex'>
                                    {showMenu ?
                                        <> */}
                                            {/* <div className='col-6 align-self-center text-end'> */}
                                                {/* <span className="" onClick={toggleMenu}><FontAwesomeIcon icon={faClose} style={{ color: '#000', fontSize: '20px' }} /></span> */}
                                                {/* <span className="" > <i className="fa-regular fa-closed-captioning" style={{ color: '#000', fontSize: '20px' }}></i></span>
                                            </div> */}
                                        {/* </>
                                        :
                                        <>
                                            <div className='col-6 align-self-center text-end'>
                                                <span className="" > */}
                                                    {/* <FontAwesomeIcon icon={faBars} style={{ color: '#000', fontSize: '20px' }} /> */}
                                                    {/* <i class="fa-solid fa-bars" style={{ color: '#000', fontSize: '20px' }}></i>
                                                    </span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div> */}

                            {/* {showMenu ?
                                <>
                                    <div className={`offcanvas offcanvas-start mobile-view ${showMenu ? 'show overflow-auto' : 'collapse navbar-collapse nav-list'}`} id="navbarNavDropdown">
                                        <div className={`offcanvas offcanvas-start ${showMenu ? 'show overflow-auto' : ''}`} tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                                            <div className="offcanvas-header">
                                                <img src={require("../../src/assets/images/website/Logo.png")} />
                                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                                            </div>
                                            <div className="offcanvas-body position-sticky">
                                                <ul className="navbar-nav py-2 nav-content">
                                                    <li className="nav-item dropdown mega-menu-li"> */}
                                                        {/* <a className="nav-link dropdown-toggle option-list" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded={isAboutView} onClick={toggleDropdownAbout}>
                                                            About Us {isAboutView ? <><FontAwesomeIcon icon={faMinus} style={{ color: "#000", }} className='ps-2' /></> : <><FontAwesomeIcon icon={faPlus} style={{ color: "#000", }} className='ps-2' /></>}
                                                        </a> */}
                                                        {/* <div class={`dropdown-menu drop-width w-100 ${isAboutView ? 'show' : ''}`} aria-labelledby="dropdownMenuLink">
                                                            <div class="container-fluid">
                                                                <div class="row m-0">
                                                                    <>
                                                                        <div className="col-12 mb-3 mb-lg-0">
                                                                            <div className="list-group list-group-flush">
                                                                                <ul className="ps-0">
                                                                                    <li className='nav-item mb-1'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: '#our_vision' }} className={` text-decoration-none`}>
                                                                                            Vision & Mission
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item mb-1'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: '#company_profile' }} className={`text-decoration-none`}>
                                                                                            Company profile
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item mb-1'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: '#about_product' }} className={`text-decoration-none`}>
                                                                                            About Product
                                                                                        </NavLink>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown mega-menu-li"> */}
                                                        {/* <a className="nav-link dropdown-toggle option-list" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded={isProductView} onClick={toggleDropdownProduct}>
                                                            Product Feature {isProductView ? <><FontAwesomeIcon icon={faMinus} style={{ color: "#000", }} className='ps-2' /></> : <><FontAwesomeIcon icon={faPlus} style={{ color: "#000", }} className='ps-2' /></>}
                                                        </a> */}
                                                        {/* <div class={`dropdown-menu drop-width w-100 ${isProductView ? 'show' : ''}`} aria-labelledby="dropdownMenuLink">
                                                            <div class="container-fluid">
                                                                <div class="row m-0">
                                                                    <>
                                                                        <div className="col-12 mb-3 mb-lg-0">
                                                                            <div className="list-group list-group-flush">
                                                                                <ul className="ps-0">
                                                                                    <li className='nav-item mb-2'>
                                                                                        <NavLink exact to={{ pathname: '/' }} className={` text-decoration-none`}>
                                                                                            Electronic Medical Records
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: '#integrated_solution' }} className={`text-decoration-none`}>
                                                                                            Integrated Solution
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: "#no_expensive_hardware" }} className={` text-decoration-none`}>
                                                                                            No expensive Hardware
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: '#easy_communication' }} className={`text-decoration-none`}>
                                                                                            Easy Communication
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: '#user_friendly' }} className={`text-decoration-none`}>
                                                                                            User Friendly application
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: '#privacy_security' }} className={` text-decoration-none`}>
                                                                                            Privacy & Security
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'resource_planning' }} className={` text-decoration-none`}>
                                                                                            Resource planning
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'billing_payment' }} className={`text-decoration-none`}>
                                                                                            Billing and Payment
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'data_analytics' }} className={` text-decoration-none`}>
                                                                                            Data Analytics
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'appointment_scheduling' }} className={`text-decoration-none`}>
                                                                                            Appointment Scheduling
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'kisok_integration' }} className={`text-decoration-none`}>
                                                                                            Kisok Integration
                                                                                        </NavLink>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown mega-menu-li"> */}
                                                        {/* <a
                                                            className="nav-link dropdown-toggle option-list"
                                                            href="#"
                                                            role="button"
                                                            id="dropdownMenuLink"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded={isSolutionView}
                                                            onClick={toggleDropdownSolution}
                                                        >
                                                            Solutions
                                                            {isSolutionView ? (
                                                                <FontAwesomeIcon icon={faMinus} style={{ color: '#000' }} className="ps-2" />
                                                            ) : (
                                                                <FontAwesomeIcon icon={faPlus} style={{ color: '#000' }} className="ps-2" />
                                                            )}
                                                        </a> */}

                                                        {/* <div className={`dropdown-menu drop-width w-100 ${isSolutionView ? 'show' : ''}`} aria-labelledby="dropdownMenuLink">
                                                            <div className="container-fluid">
                                                                <div className="row m-0">
                                                                    <>
                                                                        <div className="col-12 mb-3 mb-lg-0">
                                                                            <div className="list-group list-group-flush">
                                                                                <ul className="ps-0 ">
                                                                                    <li className="nav-item dropdown-submenu mb-2 px-0"> */}
                                                                                        {/* <a
                                                                                            className="nav-link dropdown-toggle option-list"
                                                                                            href="#"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded={isSolutionClinical}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                toggleDropdownClinical();
                                                                                            }}
                                                                                        >
                                                                                            Clinical Management System
                                                                                            {isSolutionClinical ? (
                                                                                                <FontAwesomeIcon icon={faMinus} style={{ color: '#000' }} className="ps-2" />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon icon={faPlus} style={{ color: '#000' }} className="ps-2" />
                                                                                            )}
                                                                                        </a> */}

                                                                                        {/* <div className={`dropdown-menu drop-width w-100 ${isSolutionClinical ? 'show' : ''}`} aria-labelledby="dropdownMenuLink">
                                                                                            <div className="container-fluid">
                                                                                                <div className="row m-0">
                                                                                                    <>
                                                                                                        <div className="col-12 mb-3 mb-lg-0">
                                                                                                            <div className="list-group list-group-flush">
                                                                                                                <ul className="ps-0">
                                                                                                                    <li className='nav-item'>
                                                                                                                        <NavLink exact to={{ pathname: '/', hash: 'hospitals_clinics' }} className={`text-decoration-none`}>
                                                                                                                            For Hospitals / Clinics
                                                                                                                        </NavLink>
                                                                                                                    </li>
                                                                                                                    <li className='nav-item'>
                                                                                                                        <NavLink exact to={{ pathname: '/', hash: 'for_physicians' }} className={`text-decoration-none`}>
                                                                                                                            For Physicians
                                                                                                                        </NavLink>
                                                                                                                    </li>
                                                                                                                    <li className='nav-item'>
                                                                                                                        <NavLink exact to={{ pathname: '/', hash: 'for_patients' }} className={`text-decoration-none`}>
                                                                                                                            For Patients
                                                                                                                        </NavLink>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className="nav-item dropdown-submenu mb-2 px-0"> */}
                                                                                        {/* <a
                                                                                            className="nav-link dropdown-toggle option-list"
                                                                                            href="#"
                                                                                            role="button"
                                                                                            id="dropdownMenuLink"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded={isSolutionClinical}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                toggleDropdownAppointment();
                                                                                            }}
                                                                                        >
                                                                                            Appointment Management System
                                                                                            {isSolutionAppointment ? (
                                                                                                <FontAwesomeIcon icon={faMinus} style={{ color: '#000' }} className="ps-2" />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon icon={faPlus} style={{ color: '#000' }} className="ps-2" />
                                                                                            )}
                                                                                        </a> */}

                                                                                        {/* <div className={`dropdown-menu drop-width w-100 ${isSolutionAppointment ? 'show' : ''}`} aria-labelledby="dropdownMenuLink">
                                                                                            <div className="container-fluid">
                                                                                                <div className="row m-0">
                                                                                                    <>
                                                                                                        <div className="col-12 mb-3 mb-lg-0">
                                                                                                            <div className="list-group list-group-flush">
                                                                                                                <ul className="ps-0">
                                                                                                                    <li className='nav-item'>
                                                                                                                        <NavLink exact to={{ pathname: '/', hash: 'token_system' }} className={`text-decoration-none`}>
                                                                                                                            Token System
                                                                                                                        </NavLink>
                                                                                                                    </li>
                                                                                                                    <li className='nav-item'>
                                                                                                                        <NavLink exact to={{ pathname: '/', hash: 'appointment_schedule' }} className={`text-decoration-none`}>
                                                                                                                            Appointment Schedule System
                                                                                                                        </NavLink>
                                                                                                                    </li>
                                                                                                                    <li className='nav-item'>
                                                                                                                        <NavLink exact to={{ pathname: '/', hash: 'tele_consultaion' }} className={`text-decoration-none`}>
                                                                                                                            Tele consultaion
                                                                                                                        </NavLink>
                                                                                                                    </li>
                                                                                                                    <li className='nav-item'>
                                                                                                                        <NavLink exact to={{ pathname: '/', hash: 'outpatient_management' }} className={`text-decoration-none`}>
                                                                                                                            In/ Out-patient management
                                                                                                                        </NavLink>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown mega-menu-li"> */}
                                                        {/* <a className="nav-link dropdown-toggle option-list" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded={isBenifitView} onClick={toggleDropdownBenifit}>
                                                            Benefits {isBenifitView ? <><FontAwesomeIcon icon={faMinus} style={{ color: "#000", }} className='ps-2' /></> : <><FontAwesomeIcon icon={faPlus} style={{ color: "#000", }} className='ps-2' /></>}
                                                        </a> */}
                                                        {/* <div class={`dropdown-menu drop-width w-100 ${isBenifitView ? 'show' : ''}`} aria-labelledby="dropdownMenuLink">
                                                            <div class="container-fluid">
                                                                <div class="row m-0">
                                                                    <>
                                                                        <div className="col-12 mb-3 mb-lg-0">
                                                                            <div className="list-group list-group-flush">
                                                                                <ul className="ps-0">
                                                                                    <li className='nav-item mb-2'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'increase_revenue' }} className={`text-decoration-none`}>
                                                                                            Increase revenue
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'increase_productivity' }} className={`text-decoration-none`}>
                                                                                            Increase productivity
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'increase_servicequality' }} className={`text-decoration-none`}>
                                                                                            Increase Service Quality
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'improve_patientsatisfaction' }} className={`  text-decoration-none`}>
                                                                                            Improve patient satisfaction
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'no_waitingroom' }} className={` text-decoration-none`}>
                                                                                            No wait at waiting room
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'improve_datasecurity' }} className={`text-decoration-none`}>
                                                                                            Improve data Security
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'improve_dataanalytics' }} className={`text-decoration-none`}>
                                                                                            Improve data analytics
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'improve_sustainablility' }} className={`text-decoration-none`}>
                                                                                            Improve sustainablility
                                                                                        </NavLink>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item dropdown mega-menu-li"> */}
                                                        {/* <a className="nav-link dropdown-toggle option-list" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded={isContactView} onClick={toggleDropdownContact}>
                                                            Contact {isContactView ? <><FontAwesomeIcon icon={faMinus} style={{ color: "#000", }} className='ps-2' /></> : <><FontAwesomeIcon icon={faPlus} style={{ color: "#000", }} className='ps-2' /></>}
                                                        </a> */}
                                                        {/* <div class={`dropdown-menu drop-width w-100 ${isContactView ? 'show' : ''}`} aria-labelledby="dropdownMenuLink">
                                                            <div class="container-fluid">
                                                                <div class="row m-0">
                                                                    <>
                                                                        <div className="col-12 mb-3 mb-lg-0">
                                                                            <div className="list-group list-group-flush">
                                                                                <ul className="ps-0">
                                                                                    <li className='nav-item mb-2'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'free_demo' }} className={`  text-decoration-none`}>
                                                                                            Free Demo
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'testimonials' }} className={`text-decoration-none`}>
                                                                                            Testimonials
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'careers' }} className={`text-decoration-none`}>
                                                                                            Careers
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'franchise' }} className={` text-decoration-none`}>
                                                                                            Franchise
                                                                                        </NavLink>
                                                                                    </li>
                                                                                    <li className='nav-item'>
                                                                                        <NavLink exact to={{ pathname: '/', hash: 'contact_us' }} className={`  text-decoration-none`}>
                                                                                            Contact Us
                                                                                        </NavLink>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className='book-btn m-2 d-flex'>
                                                    <button className='p-3' >Book a Demo</button>
                                                    <div className='book-btn mx-2'>
                                                        <button type='button' className='p-3' >Login / Signup</button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </> :
                                <>

                                </>
                            } */}
                            <div className="collapse navbar-collapse d-lg-block d-none" id="navbarNavAltMarkup">
                                <div className={`${isClass1Show == true ? 'nav-show' : ''} collapse navbar-collapse navbarNav justify-content-end mr-3 navlist`} id="navbarSupportedContent">
                                    <ul className="navbar-nav width-navbar">

                                        <li className="nav-item dropdown">
                                            {/* <a className='dropdown-toggle option-list text-decoration-none custom-active' exact to={{ pathname: '/about' }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                About us
                                            </a> */}
                                             <NavLink exact to={{ pathname: '/website' }} className={` dropdown-toggle option-list text-decoration-none custom-active`}>
                                             About us
                                                    </NavLink>
                                            {/* <ul className="dropdown-menu ">
                                                <li className='nav-item mb-1'>
                                                    <NavLink exact to={{ pathname: '/about', hash: '#our_vision' }} className={`${location.hash === '#our_vision' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Vision & Mission
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item mb-1'>
                                                    <NavLink exact to={{ pathname: '/about', hash: '#company_profile' }} className={`${location.hash === '#company_profile' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Company profile
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item mb-1'>
                                                    <NavLink exact to={{ pathname: '/about', hash: '#about_product' }} className={`${location.hash === '#about_product' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        About Product
                                                    </NavLink>
                                                </li>
                                            </ul> */}
                                        </li>

                                        <li class="nav-item dropdown">
    <a class="dropdown-toggle option-list text-decoration-none custom-active" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Solution</a>
    <div class="dropdown-menu">
        <a class="dropdown-item" style={{padding: '30px 25px'}} href="/hospitalmanagment">
            <i class="fas fa-hospital"></i> Hospital Management System
        </a>
        <a class="dropdown-item text-decoration-none" style={{padding: '30px 25px'}} href="/doctormanagment">
            <i class="fas fa-user-md"></i> Doctor Management System
        </a>
        <a class="dropdown-item text-decoration-none" style={{padding: '30px 25px'}} href="/patientmanagment">
            <i class="fas fa-procedures"></i> Patients Management System
        </a>
    </div>
</li>
                                        
                                        {/* <li className="nav-item dropdown">
                                            <a className='dropdown-toggle option-list text-decoration-none custom-active' href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Solutions
                                            </a> */}
                                            
                                            
                                                    {/* <ul className='dropdown-menu'>
                                                        <li className='nav-item'>
                                                            <NavLink exact to={{ pathname: '/' }} className={`text-decoration-none`}>
                                                            Hospital Management System
                                                            </NavLink>
                                                        </li>
                                                        <li className='nav-item'>
                                                            <NavLink exact to={{ pathname: '/' }} className={` text-decoration-none`}>
                                                            Doctor Management System
                                                            </NavLink>
                                                        </li>
                                                        <li className='nav-item'>
                                                            <NavLink exact to={{ pathname: '/' }} className={`text-decoration-none`}>
                                                            Patients Management System
                                                            </NavLink>
                                                        </li> */}
                                                   
                                                {/* <li className='nav-item dropdown-submenu'>
                                                    <NavLink exact to={{ pathname: '/' }} className={` text-decoration-none`}>
                                                        Appointment Management System
                                                      
                                                    </NavLink> */}
                                                    {/* <ul className='dropdown-menu'>
                                                        <li className='nav-item'>
                                                            <NavLink exact to={{ pathname: '/appointment', hash: 'token_system' }} className={`${location.hash === '#token_system' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                                Token System
                                                            </NavLink>
                                                        </li>
                                                        <li className='nav-item'>
                                                            <NavLink exact to={{ pathname: '/appointment', hash: 'appointment_schedule' }} className={`${location.hash === '#appointment_schedule' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                                Appointment Schedule System
                                                            </NavLink>
                                                        </li>
                                                        <li className='nav-item'>
                                                            <NavLink exact to={{ pathname: '/appointment', hash: 'tele_consultaion' }} className={`${location.hash === '#tele_consultaion' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                                Tele consultaion
                                                            </NavLink>
                                                        </li>
                                                        <li className='nav-item'>
                                                            <NavLink exact to={{ pathname: '/appointment', hash: 'outpatient_management' }} className={`${location.hash === '#outpatient_management' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                                In/ Out-patient management
                                                            </NavLink>
                                                        </li>
                                                    </ul> */}
                                                {/* </li> */}

                                            {/* </ul> */}
                                        {/* </li> */}
                                        <li className="nav-item1 dropdown">
                                            <a className='dropdown-toggle option-list text-decoration-none custom-active' href="/Webproduct" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Product Feature
                                            </a>
                                            </li>
                                        {/* <li className="nav-item dropdown"> */}
                                            {/* <a className='dropdown-toggle option-list text-decoration-none custom-active' href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Product Feature
                                            </a> */}
                                            {/* <NavLink exact to={{ pathname: '' }} className={`dropdown-toggle option-list text-decoration-none custom-active`}>
                                            Product Feature
                                                    </NavLink> */}
                                                    {/* <a class="dropdown-toggle option-list text-decoration-none custom-active" href="/Webproduct" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Product Feature
                                                        </a> */}
                                                    {/* <div className="dropdown-menu">
                                                    <a class="nav-item" href="/webhospital">Hospital</a>
                                                    <a class="nav-item" href="/webphysician">Physician</a>
                                                    <a class="nav-item" href="/webpatient">Patient</a>
                                                    </div>     */}
                                            {/* <ul className="dropdown-menu  ">
                                                <li className='nav-item mb-2'>
                                                    <NavLink exact to={{ pathname: '/product' }} className={`${pathname === '/product' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Electronic Medical Records
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/product', hash: '#integrated_solution' }} className={`${location.hash === '#integrated_solution' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Integrated Solution
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/product', hash: "#no_expensive_hardware" }} className={`${location.hash === '#no_expensive_hardware' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        No expensive Hardware
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/product', hash: '#easy_communication' }} className={`${location.hash == '#easy_communicationss' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Easy Communication
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/product', hash: '#user_friendly' }} className={`${location.hash === '#user_friendly' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        User Friendly application
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/feature', hash: '#privacy_security' }} className={`${location.hash === '#privacy_security' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Privacy & Security
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/feature', hash: 'resource_planning' }} className={`${location.hash === '#resource_planning' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Resource planning
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/feature', hash: 'billing_payment' }} className={`${location.hash === '#billing_payment' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Billing and Payment
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/feature', hash: 'data_analytics' }} className={`${location.hash === '#data_analytics' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Data Analytics
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/feature', hash: 'appointment_scheduling' }} className={`${location.hash === '#appointment_scheduling' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Appointment Scheduling
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/feature', hash: 'kisok_integration' }} className={`${location.hash === '#kisok_integration' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Kisok Integration
                                                    </NavLink>
                                                </li>
                                            </ul> */}
                                        {/* </li> */}
                                     
                                        <li className="nav-item dropdown">
                                            {/* <a className='dropdown-toggle option-list text-decoration-none custom-active' href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Benefits
                                            </a> */}
                                            <NavLink exact to={{ pathname: '/benifits',  }} className={`dropdown-toggle option-list text-decoration-none custom-active`}>
                                            Benefits
                                                    </NavLink>
                                            {/* <ul className="dropdown-menu">
                                                <li className='nav-item mb-2'>
                                                    <NavLink exact to={{ pathname: '/benefits', hash: 'increase_revenue' }} className={`${location.hash === '#increase_revenue' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Increase revenue
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/benefits', hash: 'increase_productivity' }} className={`${location.hash === '#increase_productivity' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Increase productivity
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/benefits', hash: 'increase_servicequality' }} className={`${location.hash === '#increase_servicequality' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Increase Service Quality
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/benefits', hash: 'improve_patientsatisfaction' }} className={`${location.hash === '#improve_patientsatisfaction' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Improve patient satisfaction
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/benefits', hash: 'no_waitingroom' }} className={`${location.hash === '#no_waitingroom' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        No wait at waiting room
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/benefits', hash: 'improve_datasecurity' }} className={`${location.hash === '#improve_datasecurity' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Improve data Security
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/benefits', hash: 'improve_dataanalytics' }} className={`${location.hash === '#improve_dataanalytics' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Improve data analytics
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/benefits', hash: 'improve_sustainablility' }} className={`${location.hash === '#improve_sustainablility' ? 'dropmenu' : 'custom-active'} text-decoration-none`}>
                                                        Improve sustainablility
                                                    </NavLink>
                                                </li>
                                            </ul> */}
                                        </li>
                                        <li className="nav-item1 dropdown">
                                            <a className='dropdown-toggle option-list text-decoration-none custom-active' href="/Webcontact" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Contact
                                            </a>
                                            <ul className="dropdown-menu ">
                                                <li className='nav-item mb-2'>
                                                    <NavLink exact to={{ pathname: '/', hash: 'free_demo' }} className={`text-decoration-none`}>
                                                        Free Demo
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/', hash: 'testimonials' }} className={`text-decoration-none`}>
                                                        Testimonials
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/', hash: 'careers' }} className={`text-decoration-none`}>
                                                        Careers
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/', hash: 'franchise' }} className={`text-decoration-none`}>
                                                        Franchise
                                                    </NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink exact to={{ pathname: '/', hash: 'contact_us' }} className={`text-decoration-none`}>
                                                        Contact Us
                                                    </NavLink>
                                                </li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div >
                                    <button className='btn-ghost'><a href='/bookdemo'style={{ textDecoration: 'none', color: 'white', }} >Get Our App</a></button>
                                </div>
                                <div className=' mx-2'>
                                <button className='btn-ghost1' type='button' style={{ backgroundColor: 'white', border: 'none', fontSize: '18px' }}>
                                    <a href='/login' style={{ textDecoration: 'none', color: '#0C5B98' }}>
                                        <i className="fa-regular fa-user"></i> Login
                                    </a>
                                    </button>
                                </div>
                            </div>

                        </div>

                    </nav>

                </div>
            </header>

        </div>
        ) }};
        export default Headerweb;