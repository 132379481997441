import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import FooterWeb from './Footerweb';
import Headerweb from './Headerweb';
import SimpleSlider from './Simpleslider';
import LogoSlider from './LogoSlider';
import Topmove from './Topmove';
import Whatsapp from './Whatsapp';
import Webadslider from './Webadslider';
import Webblog from './Webblog';
import Webbenifits from './Webbenifits';

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class Home extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
            visible: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

       

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    componentDidMount() {
      // Simulate a delay before adding the visible class
      this.timer = setTimeout(() => {
          this.setState({ visible: true });
      }, this.props.delay || 1000); // Default delay is 1000ms
  }

  componentWillUnmount() {
      clearTimeout(this.timer);
  }
    
    render() {
        const { hospitaltimeout } = this.state;
        return (

<div className=''>
      <Headerweb />
     
      <section className='position-relative section-start'>
        <div className='position-relative'style={{ background: '#ADD8E6' }}>
          <div className='row m-0 align-items-center'>
            <div className='col-lg-7 col-12 h-50 '>
              <div className={`container-75 section-top visible slide-left `} >
                <h3 style={{ color: '#0C101B' }}>KlinicQ is  a renowned clinic providing exemplary online medical treatment services.</h3>
                <p>leading clinic offering exceptional online medical treatment service,<br/>
ensuring the convenient and expert care from the comfort of home</p>
                
                <div className='d-flex align-items-center scheduel-section'>
                  <div>
                    <button type='button' className='me-4 ' >Schedule</button>
                    {/* <button type='button' className='me-4' onClick={booklogin} >Schedule</button> */}
                  </div>
                  <div className='play-around ml-3'>
    <a href="https://www.youtube.com/watch?v=qPbaZgYzeYs" target="_blank" rel="noopener noreferrer">
        <i className="fa-solid fa-play fa-xl icons" style={{ color: '#FFF' }}></i>
    </a>
</div>
<div className='px-3'>
    <p className='m-0' style={{ color: '#808080' }}>See Demo</p>
</div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-12 right-img d-flex align-items-center'>
    <img 
        src={require("../../src/assets/images/website/smiling-doctor.png")}  
        className={`pt-lg-5 pt-0 visible slide-right`} 
        style={{ backgroundColor: 'transparent', marginRight: '10px' }} // Add margin to create space between images
    />
    {/* <img 
        src={require("../../src/assets/images/website/Vector1.png")} 
        className='pt-lg-5 pt-0 ' 
    /> */}
</div>
          </div>
                              
          {/* <div className='goal-section'>
            <img src={goal} />
            <h1>100%</h1>
            <p>Sucess rate This Year</p>
          </div> */}
      
        </div>
        <div className='company-section container-90'>
                    <div className='row m-0'>
                        <div className='col-lg-6 col-12 slide-left visible'>
                        <div className='img-responsive1'>
                        <div className='row1'>
        <div className='column1'>
            <img src={require("../../src/assets/images/website/nurse.jpg")} className='img-item1' />
            <img src={require("../../src/assets/images/website/image2.png")} className='img-item1' />
        </div>
        <div className='column1 center1'>
            <img src={require("../../src/assets/images/website/image1.png")} className='img-item1 ml-2' />
           
            <div className='rectangle-container1'>
            <img src={require("../../src/assets/images/website/Rectangle1.png")} className='img-item1 ml-2' />
        <div className='text-overlay1'>100% Success Rate This Year</div>
        
      </div>
           

            {/* <div className='find-doctor'>
            <img src={require("../../src/assets/images/website/Rectangle1.png")} className='img-item1' />
                    
                  </div> */}
        </div>
        
    </div>
    </div>
                        </div>
                        <div className='col-lg-6 col-12 slide-right visible content-company'>
                            <h1 style={{ color: '#0C101B' }}>KlinicQ Revolutionizing How You Manage Your Health
                            </h1>
                            {/* <div className='img-responsive'>
    <div className='row'>
        <div className='column'>
            <img src={require("../../src/assets/images/website/nurse.jpg")} className='img-item' />
            <img src={require("../../src/assets/images/website/company.png")} className='img-item' />
        </div>
        <div className='column center'>
            <img src={require("../../src/assets/images/website/doctor7.jpg")} className='img-item' />
        </div>
    </div>
</div> */}\

                            <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                            <i class="fa-regular fa-circle-check fa-2xl" style={{ marginRight: "10px"}}></i><p style={{margin: "0px"}}>KlinicQ application is a cloud-based Electronic Clinical Management <br/> System (e CMS) that can be accessed from anywhere and anytime</p>
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                            <i class="fa-regular fa-circle-check fa-2xl" style={{ marginRight: "10px"}}></i><p style={{margin: "0px"}}>KlinicQ application is a comprehensive solution for managing Clinic <br/> operations safely and without manual error usually associated with<br/> handwriting and paper-based records.</p>
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                            <i class="fa-regular fa-circle-check fa-2xl" style={{ marginRight: "10px"}}></i> <p  style={{margin: "0px"}}>It has been developed to address operational issues ,streamline the<br/> process,and improve efficiency with proactive engagement &<br/> Improved medication adherence rate by 15%</p>
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                            <i class="fa-regular fa-circle-check fa-2xl" style={{ marginRight: "10px"}}></i><p  style={{margin: "0px"}}>It helps organize doctor scheduels,collate patient notes,health<br/> history,medical records,and other critical metrics in real-time,and<br/> handle payment effortlessly & improved patient satisfaction by 75%</p>
                            </div>
                            <div className='find-doctor text-center py-3'>
                    <button>KlinicQ More<img src={require("../../src/assets/images/website/arrowup.png")} className='ml-2'/></button>
                    
                  </div>
                        </div>
                    </div>
                </div>
        <div className='single-click py-5'>
          <div className='container'>
            <div className='row m-0'>
              {/* <div className='col-lg-4 col-12 mb-lg-0 mb-3 slide-left visible'>
                
              </div> */}
              <div className='col-lg-12 col-12 py-lg-0 py-md-0 py-2 slide-right visible text-center'>
              <h1 style={{ color: '#0C101B', fontWeight: 'bold' }}>Fostering Wellness with a Single Click </h1>
                <p>KlinicQ is our Electronic Clinical Management System (e CMS) application.Electronic Clinical Management System<br/> (e CMS) application. It is user-friendly, convenient, comprehensive, and integrated to manage all hospital<br/> operations safely and with no manual error usually associated with handwriting & paper-based records.</p>
              </div>
              {/* <div className='col-lg-1 d-lg-block d-none slide-right visible'> */}
                {/* <div className='arrow-section'> */}
                  {/* <FontAwesomeIcon icon={faArrowRight}  style={{ color: '#FFF' }} className='icon' /> */}
                  {/* <i class="fa-solid fa-arrow-right-long icons" style={{ color: '#FFF' }}></i> */}
                {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <hr className='m-0' style={{ background: '#D3F2FF' }} />
        <div className='hospital-details py-5'>
          <div className='container col-lg-11'>
            <div className='row m-0 align-items-stretch justify-content-center'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 slide-left visible'>
                <div className='patient '>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/patient8.png")} className='w-100 ' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content '>
                    <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Patient</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>The patient portal is designed to manage primary & family members under the same account with a unique ID for each member.</p>
                      </div>
                      </div>
                      {/* <div className=''> */}
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <i class="fa-solid fa-arrow-right"  style={{ color: '#00275A' }} ></i> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img className='icons' /> */}
                        {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-0 my-sm-0 my-2'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/profile3.png")} className='w-100' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content'>
                  <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Doctor</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>A doctor portal is designed based on the physician's specialty and can work for any specialization.</p>
                      </div>
                      </div>
                      <div className=''>
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img  className='icon' /> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-2 my-sm-2 slide-right visible'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/profile1.webp")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                  <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Hospital</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>A hospital portal is designed to manage multi-specialty and different consultation modes.</p>
                      </div>
                      </div>
                      <div className=''>
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                        </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='profile-section ps-lg-5 ps-0'>
        <Webadslider />
        </div>

        
{/*        
        <div className='fliter-section py-5 slide-left visible'>
          <div className='container-85'>
            <div className='box'>
              <div className='search-list search-option'>
                <div className='d-flex align-items-center'> */}
                  {/* <FontAwesomeIcon icon={faSearch} style={{ color: '#01285C', fontSize: '30px' }} /> */}
                  {/* <i class="fa-solid fa-magnifying-glass"  style={{ color: '#01285C', fontSize: '30px' }}></i>
                  <div className='ps-3'>
                    <label className=''>Apollo hospital</label><br />
                    <input placeholder='Apollo hospital' className='border-0 mt-2' />
                  </div>
                </div>
              </div>
              <div className='search-list search-option '>
                <div>
                  <label className=''>Distance Type</label><br />
                  <select className='border-0  mt-2' placeholder="Kilometer">
                    <option className='option'>Kilometer</option>
                  </select>
                </div>
              </div>
              <div className='search-list search-option '>
                <div>
                  <label className=''>Distance Length</label><br />
                  <select className='border-0  mt-2'>
                    <option className='option'>20</option>
                  </select>
                </div>
              </div>
              <div className='search-list search-option '>
                <div>
                  <label className=''>Gender</label><br />
                  <select className='border-0  mt-2'>
                    <option className='option'>Male</option>
                  </select>
                </div>
              </div>
              <div className='search-list search-option '>
                <div>
                  <label className=''>City</label><br />
                  <select className='border-0  mt-2'>
                    <option className='option'>Madurai</option>
                  </select>
                </div>
              </div>
              <div className='search-option text-center lastbtn'>
                <button className='btn-ghost btn_sec'>Search</button>

              </div> */}
              {/* <div className='search-lists mx-3 d-flex align-items-center'>
                <button>Search</button>
              </div> */}
            {/* </div>
          </div>
        </div> */}
        
        <div className='logo-slider container-90 slide-right visible'>
          <LogoSlider />
        
        </div>

        <div className='map-location container-85 py-5 slide-left visible'>
    <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2999410087273!2d77.57502788110729!3d12.952648982725313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e8d9e80459%3A0x1895528330682b65!2s131%2C%20Rashtriya%20Vidyalaya%20Rd%2C%20Vishweshwarapura%2C%20Basavanagudi%2C%20Bengaluru%2C%20Karnataka%20560004!5e0!3m2!1sen!2sin!4v1718691093610!5m2!1sen!2sin" 
        width="100%" 
        height="450" 
        style={{ border: 0 }} 
        allowFullScreen 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade">
    </iframe>

    <div style={{ display: 'flex', marginTop: '20px', width: '80%', margin: '0 auto' }}>
    <i className="fa-regular fa-magnifying-glass fa-2xl" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none', color: "#b0b4ba" }}></i>
  
                <input 
                    type="text" 
                    placeholder="Apollo Hospital" 
                    style={{ flex: 1, padding: '10px 10px 10px 40px', border: '1px solid #ccc', borderRadius: '4px' }} 
                />
        <input 
            type="text" 
            placeholder="Apollo Hospital" 
            style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '0 4px 4px 0' }} 
        />
    </div>
</div>
        {/* <div className='map-location container-85 py-5 slide-left visible'>
         

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2999410087273!2d77.57502788110729!3d12.952648982725313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e8d9e80459%3A0x1895528330682b65!2s131%2C%20Rashtriya%20Vidyalaya%20Rd%2C%20Vishweshwarapura%2C%20Basavanagudi%2C%20Bengaluru%2C%20Karnataka%20560004!5e0!3m2!1sen!2sin!4v1718691093610!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0, width: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div> */}
        <hr />
        <div className='profile-section ps-lg-5 ps-0'>
          <SimpleSlider />
        </div>
              
          <div className='position-relative'style={{ background: '' }}>
          <div className='row m-0 align-items-center'>
          <div className='col-lg-5 col-12 right-img1 d-flex align-items-center'>
    <img 
        src={require("../../src/assets/images/website/image3.png")}  
        className={`pt-lg-5 pt-0 visible slide-left`} 
       
    />
   
</div>
            <div className='col-lg-7 col-12'>
              <div className={`container-86 section-top section-top1 visible slide-right `} >
                
       
              
                
                <div className='d-flex align-items-center scheduel-section'>
                  <div className='p-5'>
                  <img 
        src={require("../../src/assets/images/website/Group2.png")}  
        

    />
                  <h4 className='mt-3' style={{color:'#FFF'}}>Vision</h4>
                  <p style={{color:'#FFF'}}>Our goal is to add business value to customers in information <br/> Technology </p>
                  <br/>
                  <img 
        src={require("../../src/assets/images/website/mission 1.png")}  
        className='mt-4'
       
    />
                  <h4 className='mt-3' style={{color:'#FFF'}}>Mission</h4>
                  <p style={{color:'#FFF'}}>To build customer value by delevering software products and  <br/> solution is innovation and cost-effective ways with the highest <br/> standards in security ,delivery and customer service for the <br/> health industry. </p>
                    
                  </div>
                  <div>
                  
                  </div>
                 
                </div>
              </div>
            </div>
       
          </div>
          </div>

         

          <div className='single-click py-5'>
          <div className='container'>
            <div className='row m-0'>
              {/* <div className='col-lg-4 col-12 mb-lg-0 mb-3 slide-left visible'>
                
              </div> */}
              <div className='col-lg-12 col-12 py-lg-0 py-md-0 py-2 slide-right visible text-center'>
              <h1 style={{ fontSize: '48px', fontWeight: 'bold' }}>
  <span style={{ color: '#0C5B98' }}>Why KlinicQ?</span> Empowering Your Health Journey
</h1>
                <p>KlinicQ gives you control over your health with easy access to except care,personalized <br/>insights and seamless Wellness management.Empowering you to make informed <br/>decision,anytime,anywhere. Your health,Simplified</p>
              </div>
              {/* <div className='col-lg-1 d-lg-block d-none slide-right visible'> */}
                {/* <div className='arrow-section'> */}
                  {/* <FontAwesomeIcon icon={faArrowRight}  style={{ color: '#FFF' }} className='icon' /> */}
                  {/* <i class="fa-solid fa-arrow-right-long icons" style={{ color: '#FFF' }}></i> */}
                {/* </div> */}
              {/* </div> */}
            </div>
            
        <div className='body1'>
        <div class="container95">
        <div class="item3">
            <img src={require("../../src/assets/images/website/Vector.jpg")} alt="Image 1"/>
            <div class="text-container">
                <h6>Personalized</h6>
                <p>Tailored products and servicing offers.</p>
            </div>
        </div>
        <div class="item3">
            <img src={require("../../src/assets/images/website/goals1.jpg")} alt="Image 2"/>
            <div class="text-container">
                <h6>Simple</h6>
                <p>Convenient, accurate and accessible.</p>
            </div>
        </div>
        <div class="item3">
            <img src={require("../../src/assets/images/website/efficacy1.jpg")} alt="Image 3"/>
            <div class="text-container">
                <h6>Efficient</h6>
                <p>Rapid service patient inquiries are resolved on.</p>
            </div>
        </div>
        <div class="item3">
            <img src={require("../../src/assets/images/website/goals1.jpg")} alt="Image 4"/>
            <div class="text-container">
                <h6>Consistent</h6>
                <p>Reliable clear communication and seamless service.</p>
            </div>
        </div>
    </div>
    </div>
    </div>
        </div>
        <Webbenifits />
        {/* <hr className='m-0' style={{ background: '#D3F2FF' }} />
        <div className='hospital-details py-5'>
          <div className='container col-lg-11'>
            <div className='row m-0 align-items-stretch justify-content-center'>
            <div className='col-12'>
        <div className='scroll-container'>
          <div className='row m-0 align-items-stretch justify-content-start'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 slide-left visible'>
                <div className='patient '>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/doctor2.png")} className='w-100 ' /> */}
                    {/* <img className='w-100' /> */}
                  {/* </div>
                  <div className='doctor-content'>
                    <h1> Benifits of Patient</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p>The patient portal is designed to manage primary & family members under the same account with a unique ID for each member.</p>
                      </div>
                      <div className=''>
                        <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <i class="fa-solid fa-arrow-right"  style={{ color: '#00275A' }} ></i> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img className='icons' /> */}
                        {/* </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-0 my-sm-0 my-2'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/doctor5.png")} className='w-100' /> */}
                    {/* <img className='w-100' /> */}
                  {/* </div>
                  <div className='doctor-content'>
                    <h1> Benifits of Physician</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p>A doctor portal is designed based on the physician's specialty and can work for any specialization.</p>
                      </div>
                      <div className=''>
                        <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img  className='icon' /> */}
                        {/* </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-0 my-sm-0 my-2'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/patient2.png")} className='w-100' /> */}
                    {/* <img className='w-100' /> */}
                  {/* </div> */}
                  {/* <div className='doctor-content'>
                    <h1> Benifits of Pharmacy</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p>A doctor portal is designed based on the physician's specialty and can work for any specialization.</p>
                      </div>
                      <div className=''>
                        <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img  className='icon' /> */}
                        {/* </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-2 my-sm-2 slide-right visible'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/doctor4.png")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                    <h1>Benifits of Hospital</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p>A hospital portal is designed to manage multi-specialty and different consultation modes.</p>
                      </div>
                      <div className=''>
                        <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className='row m-0 justify-content-center mt-4'>
              <div className='col-auto '>
        <div className='arrow-button' style={{ backgroundColor: '#D3D3D3', padding: '10px', borderRadius: '5px' }}>
          <img src={require("../../src/assets/images/website/Frame1.png")} className='icons' alt='Left Arrow' />
        </div>
      </div>
      <div className='col-auto'>
        <div className='arrow-button' style={{ backgroundColor: '#D3D3D3', padding: '10px', borderRadius: '5px' }}>
          <img src={require("../../src/assets/images/website/Frame.png")} className='icons' alt='Right Arrow' />
        </div>
      </div>
    </div>
             */}
    {/* </div>
    </div>
    </div>
            </div>
            
          </div>
        </div> */}

        <div className='single-click py-5'>
          <div className='container'>
            <div className='row m-0'>
              {/* <div className='col-lg-4 col-12 mb-lg-0 mb-3 slide-left visible'>
                
              </div> */}
              <div className='col-lg-12 col-12 py-lg-0 py-md-0 py-2 slide-right visible text-center'>

              <h1 style={{ fontSize: '48px', fontWeight: 'bold' }}>Expert Care, Anytime, Anywhere </h1>
                <p>Access expert care anytime,anywhere.Consult licensed doctor,get personalized <br/>advice, and manage your health on the go-no waiting rooms,no appointment.</p>
              </div>
              {/* <div class="download-btn1">
                           
                                <img src={require("../../src/assets/images/play1.png")} className='' />
                               
                            
                           
                                <img src={require("../../src/assets/images/play2.png")} className='' />
                               
                        </div> */}
                          <div class="download-btn2">
                            <a target="_blank" href="#" class="app-store-btn1">
                                <img src={require("../../src/assets/images/website/apple.svg")} className='' />
                                <span>Download on the</span>
                                App Store
                            </a>
                            <a target="_blank" href="#" class="google-play-btn">
                                <img src={require("../../src/assets/images/website/android1.png")} className='' />
                                <span>Get it on</span>
                                Google Play
                            </a>
                        </div>
                         <div className="image-container1">
    {/* <img src={require("../../src/assets/images/website/Rectangle19.png")} className="background-image1" alt="Background" /> */}
    <img src={require("../../src/assets/images/website/Group 75.png")} className="foreground-image1" alt="Foreground" />
</div>
            </div>
          </div>
        </div>
        <div>
      <Webblog />
      </div>
        {/* <div className='rest-section py-lg-5 py-md-4 py-0'>
          <div className='container-90'>
            <div className='row m-0 align-items-center'>
              <div className='col-lg-6 col-12 slide-left visible'>
                <h1 className='mb-4'>Rest assured, your health is in the most capable hands.</h1>
                <p className='mb-3'>With medical professionals in more than 100+ countries and a 98% customer satisfaction rate, Doctoralia offers reliable health information. With an extensive network of doctors, we are able to offer a wide range of high quality medical tests. What's more, our prices are up to 70% cheaper than other online providers.</p>
             
                <div className='d-lg-none d-md-none d-sm-none d-block'>
                  <div className='d-flex align-items-center'>
                    <img src={require("../../src/assets/images/website/rest3.png")} />
                    <div className='mar-top'>
                      <label className='ps-lg-3 ps-md-3 ps-0'>Available</label><br />
                      <p className='mb-0 ps-lg-3 ps-md-3 ps-0'>900+ specialists</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center pb-3'>
                    <img src={require("../../src/assets/images/website/rest1.png")} />
                    <div className='ms-2'>
                      <label className='ps-lg-3 ps-md-3 ps-0'>Available</label><br />
                      <p className='mb-0 ps-lg-3 ps-md-3 ps-0'>900+ specialists</p>
                    </div>
                  </div>
                </div>
                <div className='book-appointment'>
                  <div className='row m-0'>
                    <div className='col-9'>
                      <h1>Book an Appointment</h1>
                      <p>Book an Appointment</p>
                    </div>
                    <div className='col-3 d-flex align-items-center'>
                      <div className='calender'>
                        <img src={require("../../src/assets/images/website/calender.png")} />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className='col-lg-6 col-12 text-end position-relative slide-right visible'>
                <img src={require("../../src/assets/images/website/rest3.png")} className='rest3' />
                <div className='available'>
                  <div className='d-flex align-items-center p-lg-3 p-md-3 p-1 '> */}
                    {/* <FontAwesomeIcon icon={faCircleDot} beat style={{ color: '#46E826', fontSize: '20px' }} className='beat-icon' /> */}
                    {/* <i class="fa-solid fa-circle-dot"  beat style={{ color: '#46E826', fontSize: '20px' }} className='beat-icon'></i>
                    <div className='mar-top'>
                      <label className=''>Available Specialist</label><br />
                      <p className='mb-0 ps-3'>Select Specialist </p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center p-lg-3 p-md-3 p-1'>
                    <img src={require("../../src/assets/images/website/rest4.png")} />
                    <div className='doctor-name'>
                      <h2 className='ps-3'>Dr Adinda</h2>
                      <p className='mb-0 text-start ps-3'>Eye Specialist</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center p-lg-3 p-md-3 p-1'>
                    <img src={require("../../src/assets/images/website/rest5.png")} />
                    <div className='doctor-name'>
                      <h2 className='ps-3'>Dr Jackson</h2>
                      <p className='mb-0 text-start ps-3'>Ear Specialist</p>
                    </div>
                  </div>
                  <div className='find-doctor text-center my-3'>
                    <button>Find Doctor <img src={require("../../src/assets/images/website/arrowup.png")} /></button> */}
                    {/* <button>Find Doctor </button> */}
                  {/* </div>
                </div>
              </div> */}
            {/* </div>
          </div>
        </div> */}
        {/* <div className='online-section py-5'>
          <div className='container'>
            <div className='row m-0 align-items-center'>
              <div className='col-lg-6 col-12 slide-left visible'>
                <h1>Get 15-Minutes Complimentary online session.</h1>
                <p>Limited Period Offer. Claim Now.</p> */}
                {/* <button type='button' className='btn_sec' onClick={booklogin}>Book Appoinment <img src={arrowup} /></button> */}
                {/* <button type='button' className='btn_sec'  >Book Appoinment<i class="fa-solid fa-arrow-right-long ml-2" style={{color: '#FFF'}}></i> </button>
              </div>
              <div className='col-lg-6  col-12 my-lg-0 mt-4 slide-right visible'>
                <img src={require("../../src/assets/images/website/online.png")} className='w-100' />
              </div>
            </div>
          </div>
        </div> */}
        {/* <hr className='m-0' style={{ background: '#D3F2FF' }} />
        <div className='single-click py-5'>
          <div className='container'>
            <div className='row m-0'>
              <div className='col-lg-4 col-12 mb-lg-0 mb-3 slide-left visible'>
                <h1>Fostering Wellness with a Single Click </h1>
              </div>
              <div className='col-lg-7 col-12 py-lg-0 py-md-0 py-2'>
                <p>Electronic Clinical Management System (e CMS) application. It is user-friendly, convenient, comprehensive, and integrated to manage all hospital operations safely and with no manual error usually associated with handwriting & paper-based records.</p>
              </div>
              <div className='col-lg-1 d-lg-block d-none slide-right visible'>
                <div className='arrow-section'> */}
                  {/* <FontAwesomeIcon icon={faArrowRight}  style={{ color: '#FFF' }} className='icon' /> */}
                  {/* <i class="fa-solid fa-arrow-right-long icons" style={{color:'#FFF'}} ></i>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className='patient-section py-5'>
          <div className='container'>
            <div className='row m-0 '> */}
              {/* <div className='col-lg-6  col-12 d-flex align-items-stretch slide-left visible'>
                <div class="card" >
                  <img src={require("../../src/assets/images/website/patient4.png")} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h2 class="card-text">What to do if you think your child has the flu</h2>
                    <div className='row m-0'>
                      <div className='col-lg-11 col-md-11 col-12 p-0'>
                        <p>If you hear your child start coughing, it's natural to wonder: could this be the flu, or is it another virus like COVID or RSV, or even a common cold? Here's what to know about similarities between these illnesses and what to do if your child does have the flu.</p>
                      </div> */}
                      {/* <div className='col-1 p-0 d-flex align-items-center'>
                        <div className='d-lg-block d-md-block d-none'>
                          <img src={arrowupblack} className='' />
                        </div>
                      </div> */}
                    {/* </div> */}
                    {/* <a href="#" class="btn-text">Read more
                    
<svg width="14" height="35" className='ml-2' viewBox="0 0 80 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M54 0.075C53.8 -0.025 53.6 -0.025 53.5 0.075C53.3 0.175 53.2 0.374998 53.2 0.474998V10.475H0.5C0.2 10.475 0 10.675 0 10.975V23.575C0 23.875 0.2 24.075 0.5 24.075H53.2V34.075C53.2 34.275 53.3 34.475 53.5 34.475C53.6 34.475 53.7 34.575 53.7 34.575C53.8 34.575 53.9 34.575 54 34.475L79.8 17.575C79.9 17.475 80 17.275 80 17.175C80 17.075 79.9 16.875 79.8 16.775L54 0.075Z" fill="#5a49f8"/>
</svg>

                    </a> */}
                  {/* </div>
                </div>
              </div> */}
              {/* <div className='col-lg-6 col-12 my-lg-0 my-2 slide-right visible'>
                <div class="card" >
                  <img src={require("../../src/assets/images/website/patient5.png")} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h2 class="card-text">What to do if you think your child has the flu</h2>
                    <div className='row m-0'>
                      <div className='col-lg-11 col-md-11 col-12 p-0'>
                        <p>If you hear your child start coughing, it's natural to wonder: could this be the flu, or is it another virus like COVID or RSV, or even a common cold? Here's what to know about similarities between these illnesses and what to do if your child does have the flu.</p> */}
                      {/* </div> */}
                      {/* <div className='col-lg-1 col-md-1 col-0 p-0 e d-flex align-items-center'>
                        <div className='d-lg-block d-md-block d-none'>
                          <img src={arrowupblack} className='' />
                        </div>
                      </div> */}
                    {/* </div> */}
                    {/* <a href="#" class="btn-text">Read more
                    
                    <svg width="14" height="35" className='ml-2' viewBox="0 0 80 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M54 0.075C53.8 -0.025 53.6 -0.025 53.5 0.075C53.3 0.175 53.2 0.374998 53.2 0.474998V10.475H0.5C0.2 10.475 0 10.675 0 10.975V23.575C0 23.875 0.2 24.075 0.5 24.075H53.2V34.075C53.2 34.275 53.3 34.475 53.5 34.475C53.6 34.475 53.7 34.575 53.7 34.575C53.8 34.575 53.9 34.575 54 34.475L79.8 17.575C79.9 17.475 80 17.275 80 17.175C80 17.075 79.9 16.875 79.8 16.775L54 0.075Z" fill="#5a49f8"/>
                    </svg>
                    
                                        </a> */}
                  {/* </div>
                </div>
              </div> */}
            {/* </div>
          </div>
        </div> */}
        <Whatsapp />
        <Topmove />
        <FooterWeb className="qrCodeFooter" />
      </section>
      </div>

                        

                    
                 
        );
    }
    componentDidMount = () => {
     /*    console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Home);
