import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import axios from "axios";
import { Popup } from '../components/UI/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getPharmacyList,
  getAllPharmacyDrugs,
  getAllPharmacyItemList,
  getAllPharmacyVendorList,
  addPharmacy,
  addVendor,
  addSaleEntryData,
  updatePharmacy,
  deletePharmacy,
  getTimeoutbycategory,
  searchPharmacy,
  hospitalpharmacyimport,
  getitemlistforPharmacyDrug,
  getBatchnobydrugnameandExpDate,
  getBatchandExpDateBydrugname,
  getgststatebyVendorname,
  getPharmacyShortDetail,
  getclientbyphonenumber
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";
import Header from "../components/container/layout/pharmacy/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import Validation from "../actions/Validation";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Tooltip from '@mui/material/Tooltip';
import Label from "../components/UI/Label";
import PharmacyListExport from "./Business/PharmacyListExport";
import PharmacyListTemplate from "./Business/PharmacyListTemplate";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import readXlsxFile from 'read-excel-file';
import { Popupinfo } from "../components/UI/Popupinfo";
import FooterSection from "../components/UI/FooterSection";
import { renameKey } from "../constants/globalLevelFunctions";
import DatePicker from "react-date-picker";
import "../assets/css/mystyle.css";
import * as moment from "moment";
import { Restaurant } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import BatchSelectionModal from "./BatchSelectModal";
var converter = require("number-to-words");
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}
const google = window.google;
const onlineOptions = [{ value: 1, label: "Yes" }, { value: 0, label: "No" }];
let record = {
  s_no: "",
  drug_id:"",
  generic_name: "",
  pack: "",
  batch_no: "",
  exp_date: "",
  hsn_code: "",
  storage_bin: "",
  quantity: "",
  free: "",
  rate: 0,
  mrp: "",
  disc: "",
  sub_total: 0,
  cgst: "",
  sgst: "",
  igst: "",
  total: 0,
  discAmt: 0,
  taxrate: 0,
  cgstAmt: 0,
  sgstAmt: 0,
};
class SaleEntryVoucher extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;
    this.autocomplete = null;
    this.searchData = {
      phone_number: "",
      error: "",
    };
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      showModel: false,
      selectedBatches: [],
      currentBatchinrow: null,
      currentRow: null,
      discountPercentage: '',
      batches: [],
      records: [],
      searchrecords: [],
      addPopup: false,
      actions: "add",
      search_action: false,
      newDatatoAdd: {},
      searchErrorMessage: "",
      searchData: this.searchData,
      add_btn: false,
      purchaseEntryVoucher: {
        drug_name: "",
        // defaultEnterSize: 20,
        records: [],
        supplies: [],
      },

      saleEntryData:
      {
        phone_number: "",
        patient_name: "",
        address: "",
        remarks: "",
        bill_no: "",
        bill_date: new Date(),
        doctorreg_no: "",
        doctor_name: "",
        created_by: "",
        payment_mode: "",
      },

      totalAmtSec: {

        discAmt: 0,
        cgstAmt: 0,
        sgstAmt: 0,
        gstAmt: 0,
        total: 0,
        sub_total: 0,
        totalAmtWords: "",
        taxrate: 0,
      },
      totalTaxAmtSec: {
        cgstAmt5: 0,
        sgstAmt5: 0,
        sub_total5: 0,
        taxAmt5: 0,
        cgstAmt12: 0,
        sgstAmt12: 0,
        sub_total12: 0,
        taxAmt12: 0,
        cgstAmt18: 0,
        sgstAmt18: 0,
        sub_total18: 0,
        taxAmt18: 0,
        sub_total: {
          5: 0,   // Taxable amount for 5%
          12: 0,  // Taxable amount for 12%
          18: 0,  // Taxable amount for 18%
        },
        cgstAmt: {
          5: 0,   // CGST amount for 5%
          12: 0,  // CGST amount for 12%
          18: 0,  // CGST amount for 18%
        },
        sgstAmt: {
          5: 0,   // SGST amount for 5%
          12: 0,  // SGST amount for 12%
          18: 0,  // SGST amount for 18%
        },
        total: {
          5: 0,   // Total amount for 5%
          12: 0,  // Total amount for 12%
          18: 0,  // Total amount for 18%
        },
      },
      /*  taxRates: [
         {
           percentage: 5,
           taxableAmt: this.state.totalAmtSec.taxableAmt5, 
           cgstAmt: this.state.totalAmtSec.cgstAmt5, 
           sgstAmt: this.state.totalAmtSec.sgstAmt5, 
           totalAmt: this.state.totalAmtSec.totalAmt5 
         },
         {
           percentage: 12,
           taxableAmt: this.state.totalAmtSec.taxableAmt12,
           cgstAmt: this.state.totalAmtSec.cgstAmt12, 
           sgstAmt: this.state.totalAmtSec.sgstAmt12, 
           totalAmt: this.state.totalAmtSec.totalAmt12 
         },
         {
           percentage: 18,
           taxableAmt: this.state.totalAmtSec.taxableAmt18, 
           cgstAmt: this.state.totalAmtSec.cgstAmt18, 
           sgstAmt: this.state.totalAmtSec.sgstAmt18, 
           totalAmt: this.state.totalAmtSec.totalAmt18 
         }
       ], */


      errors: {
        purchaseentry_id: "",
        vendor_name: "",
        address: "",
        created_by: "",
        remarks: "",
        voucher_no: "",
        voucher_date: "",
        vendorinvoice_no: "",
        invoice_date: "",
        poref_no: ""
      },
      tableSuccess: {
        message: "",
        status: ""
      },
      formSuccess: {
        message: "",
        status: ""
      },
      defaultEnterSize: 25,
      records: [],
      supplies: [],
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        align: "left", // Set alignment to center
        sortable: true,
        cell: (row, index) => {
          return (
            <div className="s_no">
              {index + 1} {/* Display the serial number based on the index */}
            </div>
          );
        },
      },
      {
        key: "action",
        text: "Action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => this.onDeleteClick(record)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </Fragment>
          );
        },
      },

      {
        key: "generic_name",
        text: "Item Name",
        className: "generic_name sform-control",
        sortable: true,
        align: "left",
        width: '450px',
        cell: (row, index) => {
          return (
            <div >


              {row.generic_name && (row.batch_no !== '' || row.batch_no !== null) ? (

                row.generic_name

              ) : (
                <Select
                  maxMenuHeight={120}
                  isClearable={true}
                  openMenuOnClick={false}
                  className="customselectbilling"
                  name="generic_name"
                  isMandatory={true}
                  value={this.state.supplies.find(option => option.label === row.generic_name)} // Ensure value is null if not found
                  onChange={this.handleselectChange(index, 'generic_name')}
                  options={this.state.supplies}
                />
              )}
            </div>
          );
        },
      },
      {
        key: "pack",
        text: "Pack",
        className: "pack",
        align: "left",
        sortable: true,
        width: '200px',
        cell: (row, index) => row.pack
      },

      /*   {
            key: "batch_no",
            text: "Batch No",
            className: "batch_no",
            sortable: true,
            align: "left",
            width:'200px',
            cell: (row, index) => row.batch_no 
           
           
          }, */
      {
        key: "batch_no",
        text: "Batch No",
        className: "batch_no",
        sortable: true,
        align: "left",
        width: '200px',
        cell: (row, index) => (
          <div>
            {row.generic_name ? (
              row.batch_no ? (
                row.batch_no
              ) : (
                <Link to="#" onClick={() => this.handleShow(index)}>Select Batch</Link>
              )
            ) : null}
          </div>
        ),
      },
      {
        key: "storage_bin",
        text: "Storage Bin",
        className: "storage_bin",
        sortable: true,
        align: "left",
        width: '200px',
        cell: (row, index) => row.storage_bin

      },

      /*  {
         key: "manu_date",
         text: "Mfg.Date",
         className: "manu_date",
         sortable: true,
         align: "left",
         width: 20,
         cell: (row,index) => {
             return (
               <Fragment>
                 <InputField
                   inputType={"date"}
                   isMandatory={true}
                   name={"manu_date"}
                   controlFunc={this.handleInput(index,'manu_date')}
                   content={row.manu_date}
                   placeholder={""}
                 />
                  <SpanField
                   classname="text-danger"
                   title={''}
                 /> 
               </Fragment>
             );
           },
       }, 
       {
         key: "exp_date",
         text:"Exp. Date",
         className: "exp_date",
         sortable: true,
         align: "left",
         width: 20,
         cell: (row,index) => {
             return (
               <Fragment>
                 <InputField
                   inputType={"date"}
                   isMandatory={true}
                   name={"exp_date"}
                   controlFunc={this.handleInput(index,'exp_date')}
                   content={row.exp_date}
                   placeholder={""}
                 />
                  <SpanField
                   classname="text-danger"
                   title={''}
                 /> 
               </Fragment>
             );
           },
       }, */
      {
        key: "quantity",
        text: "Qty",
        className: "quantity",
        align: "left",
        sortable: true,
        width: '200px',
        cell: (row, index) => {
          return (
            <Fragment>
              <InputField
                inputType={"number"}
                name={"quantity"}
                className="newform-control"
                controlFunc={this.handleQuantityInputChange(index, 'quantity')}
                content={row.quantity}
                placeholder={""}
                step="any"
              />
              <SpanField
                classname="text-danger"
                title={''}
              />
            </Fragment>
          );
        },
      },
      {
        key: "loose",
        text: "Loose",
        className: "loose",
        align: "left",
        sortable: true,
        width: 200,
        cell: (row, index) => {
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"loose"}
                controlFunc={this.handleQuantityInputChange(index, 'loose')}
                content={row.loose}
                placeholder={""}
                step="any"
              />
              <SpanField
                classname="text-danger"
                title={''}
              />
            </Fragment>
          );
        },
      },

      {
        key: "mrp",
        text: "MRP",
        className: "mrp",
        sortable: true,
        align: "left",
        width: 200,
        cell: (row, index) => row.mrp


      },
      {
        key: "rate",
        text: "Rate",
        className: "rate",
        align: "left",
        sortable: true,
        width: 200,
        cell: (row, index) => row.rate.toFixed(2)
      },
      /*  {
         key: "disc",
         text: "Disc%",
         className: "disc",
         sortable: true,
         align: "left",
         width:200,
         cell: (row,index) => {
             return (
               <Fragment>
                 <InputField
                   inputType="number"
                   isMandatory={true}
                   name={"disc"}
                   controlFunc={this.handleInputChange(index,'disc')}
                   content={row.disc}
                   step="any"
                   placeholder={""}
                 />
                  <SpanField
                   classname="text-danger"
                   title={''}
                 /> 
               </Fragment>
             );
           },
         
     }, */
      {
        key: "sub_total",
        text: "Amount",
        className: "cellTextRight",
        sortable: true,
        align: "left",
        width: 200,
        cell: (row, index) => row.sub_total.toFixed(2)
      },


      /*  {
            key: "cgst",
            text: "CGST%",
            className: "cellTextRight",
             sortable: true,
              align: "left",
               width: 200,
                cell: (row, index) => row.cgst 
       },
           {
           key: "sgst",
           text: "SGST%",
           className: "cellTextRight",
           sortable: true,
           align: "left",
           width:200,
          cell: (row, index) => row.sgst 
       }, */
      /*  {
           key: "igst",
           text: "IGST%",
           className: "cellTextRight",
           sortable: true,
           align: "left",
           width:200,
        cell: (row, index) => row.igst 
       }, */
      /*  {
           key: "total",
           text: "Total",
           className: "cellTextRight",
           align: "left",
           sortable: true,
           right: true,
           width:200,
           cell: (row, index) => row.total.toFixed(2) 
         }, */
    ];
    this.extraButtons = [
      {
        className: "btn btn-primary buttons-pdf",
        title: "Export TEst",
        children: [
          <span>
            <i
              className="glyphicon glyphicon-print fa fa-print"
              aria-hidden="true"
            ></i>
          </span>,
        ],
        onClick: (event) => {
          console.log(event);
        },
      },
      {
        className: "btn btn-primary buttons-pdf",
        title: "Export TEst",
        children: [
          <span>
            <i
              className="glyphicon glyphicon-print fa fa-print"
              aria-hidden="true"
            ></i>
          </span>,
        ],
        onClick: (event) => {
          console.log(event);
        },
        onDoubleClick: (event) => {
          console.log("doubleClick");
        },
      },
    ];

    this.config = {
      page_size: 25,
      show_first: false,
      show_info: false,
      show_last: false,
      show_filter: false,
      show_length_menu: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchcolumns = [

      {
        key: "pharmacy_id",
        text: "Pharmacy ID",
        className: "searchpharmacy_id",
        align: "left",
        sortable: true,
        width: 10,
        cell: (record) => {
          // let obj = {
          //   label: record.availability,
          //   value: "Yes" === record.availability ? 1 : 0,
          // };
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"searchpharmacy_id"}
                controlFunc={this.handleInputsearch.bind(this, record)}
                content={record.searchpharmacy_id}
                placeholder={""}
                max={5}
              />
              <SpanField classname="text-danger" title={record.pharmacyid_error} />
            </Fragment>
          );
        },
      },
      {
        key: "pharmacy_name",
        text: "Pharmacy Name",
        className: "pharmacy_name",
        align: "left",
        sortable: true,
      },
      {
        key: "pharmacy_location",
        text: "Address",
        className: "pharmacy_location",
        sortable: true,
        align: "left",
      },
      {
        key: "phone_number",
        text: "Phone Number",
        className: "phone_number",
        sortable: true,
        align: "left",
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        sortable: true,
        align: "left",
      },
      {
        key: "online_prescription",
        text: "Online Prescription",
        className: "online_prescription",
        sortable: true,
        align: "left",
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.addRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus"></i>
              </button>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button> */}
            </Fragment>
          );
        },
      },
    ];
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }
  handlePhoneInput = (event) => {
    let pharmacyData = this.state.pharmacyData;
    let errors = this.state.errors;
    pharmacyData.phone_number = event;
    errors.phone_number = "";
    this.setState({ pharmacyData, errors });
  };

  handleClose() {
    this.setState({ showModal: false })
  }

  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  };

  _onAction(e) {
    // console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {


    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {

    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;

    if (isTimedOut === false) {
      // console.log("the time is over");
      this.setState({ showModal: true })
      console.log(this.state.showModal);
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })

    }
    if (isTimedOut === true) {
      localStorage.removeItem("token");
      this.props.logout();
    }
  }
  /* 
    handleInput =(index,record) => {
      console.log(e);
      console.log(index);
      console.log(record);
      record[e.target.name] = e.target.value;
    }; */
  handlePhoneInput = (event) => {
    event.preventDefault();
    let data = this.state.searchData;
    let errors = this.state.errors;
    data[event.target.name] = event.target.value
      .replace(/\s/g, "")
      .replace(/[^0-9+]+/g, "");
    data.error = "";
    this.setState({ searchData: data, errors });
  };

  handleAdd = (e) => {
    let data = this.state.saleEntryData;
    let errors = this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (


      name === "patient_name" ||
      name === "address" ||
      name === "created_by" ||
      name === "remarks"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = ""
    }
    this.setState({ saleEntryData: data, errors, searchErrorMessage: "" });
  };

  handleInput = (index, field) => (event) => {
    const { value } = event.target;
    const records = [...this.state.records]; // Create a copy of the records
    records[index][field] = value; // Update the specific field for the specific index
    // console.log(value);
    //  console.log(index,field);
    console.log(records);
    this.setState({ records }); // Update the state with the new records
  };

  handleDatePickerChange = (date, field) => {
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    this.setState(prevState => ({
      saleEntryData: {
        ...prevState.saleEntryData,
        [field]: offsetDate, // Update the specific expiry date field
      },
    }));
  };


  SalesB2B = () => {
    this.props.history.push("/saleentryvoucherb2b/");
  }

  SearchPatient = () => {
    // event.preventDefault();

    let searchData = this.state.searchData;
    if (searchData.phone_number === "") {
      searchData.error = "Phone number is required";
      this.setState({ searchData: searchData });
      return;
    }
    if (searchData.phone_number.length !== 10) {
      searchData.error = "Enter a valid phone number";
      this.setState({ searchData: searchData });
      return;
    }


    if (searchData.phone_number !== "" && searchData.phone_number.length === 10) {
      this.setState({ loading: true });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(
          getclientbyphonenumber,
          { phone_number: searchData.phone_number },
          CONFIG
        )
        .then((result) => {
          this.setState({ loading: false });

          if (result.data.status === "1") {
            let data = result.data.client;

            console.log(data);

            let patient_name = data.first_name + ' ' + data.last_name;
            let patient_address = data.address1 + ',' + data.city + ',' + data.state + ',' + data.country + '-' + data.zip_code;

            console.log(patient_name);
            console.log(patient_address);
            this.setState({
              clientData: data,
              patient_name: patient_name,
              patient_address: patient_address,
              add_btn: true,

            });


          } else {

            let formSuccess = {
              message: "No Client Found",
              status: "0",
            };
            searchData.error = result.data.message;
            this.setState({
              searchData,
              is_add_btn: true,
              clientData: {},
              patientBasicData: [],
              loading: false,
              selectedPerson: "",
              formSuccess,

            });
          }
        })
        .catch((e) => {
          console.log(e);
          console.log(e.data);
          console.log("business user getbyid failed");
          this.setState({ loading: false });
        });
    }
  };

  handleQuantityInputChange = (index, key) => event => {
    let updatedData = [...this.state.records];
    updatedData[index][key] = event.target.value ? parseFloat(event.target.value) : '';
    this.setState({ records: updatedData }, () => {
      this.calculateRate(index);

    });
  };
  handleInputChange = (e) => {
    const value = e.target.value;
    const discountPercentage = value ? parseFloat(value) : 0; // Parse the input value

    // Update the discount percentage in the state
    this.setState({ discountPercentage }, () => {
      this.calculateDiscountAmounts();

      // Calculate discount amounts for all records
    });
  };
  calculateDiscountAmounts = () => {
    const { records, discountPercentage } = this.state;

    // Calculate discount amounts for all records
    const updatedRecords = records.map((record) => {
      const sub_total = record.sub_total || 0; // Default to 0 if undefined
      const discAmt = (sub_total * (discountPercentage / 100)); // Calculate discount amount
      return { ...record, discAmt }; // Return updated record
    });

    // Update the state with the new records
    this.setState({ records: updatedRecords }, () => {
      this.calculateTotals();
      //  this.calculateSection();


    });
  };
  calculateTotals = () => {
    const updatedData = this.state.records.map((record) => {
      const sub_total = record.sub_total || 0; // Default to 0 if undefined
      const discAmt = record.discAmt || 0; // Default to 0 if undefined
      const cgstAmt = record.cgstAmt || 0; // Default to 0 if undefined
      const sgstAmt = record.sgstAmt || 0; // Default to 0 if undefined
      const gstAmt = cgstAmt + sgstAmt;
      console.log(sub_total);
      console.log(discAmt);
      console.log(gstAmt);
      // Calculate the total
      const total = (sub_total - discAmt) + gstAmt;
      return { ...record, total }; // Return updated record with total
    });

    // Update the state with the new records
    this.setState({ records: updatedData }, () => {

      this.calculateSection();


    });
  };


  calculateRate = (index) => {
    let updatedData = [...this.state.records];
    let mrp = updatedData[index].mrp || 0;
    let quantity = updatedData[index].quantity || 0;
    let loose = updatedData[index].loose || 0;
    let conversion_factor = updatedData[index].conversion_factor || 1;
    let cgst = updatedData[index].cgst || 0;
    let sgst = updatedData[index].cgst || 0;
    let gst = Number(cgst) + Number(sgst);
    console.log(cgst);
    console.log(sgst);
    console.log(gst);
    if (quantity != null && quantity !== 0) {
      if (gst == 5) {
        updatedData[index].rate = mrp / 1.05;
      } else if (gst == 12) {
        updatedData[index].rate = mrp / 1.12;
      }
      else if (gst == 18) {
        updatedData[index].rate = mrp / 1.18;
      }

    } else if (loose != null && loose !== 0) {
      updatedData[index].rate = mrp / conversion_factor;

    }

    this.setState({ records: updatedData }, () => {
      this.calculateSubTotal(index);
      this.calculateSubTotals();
      //this.calculateTotals();
    });
  };


  calculateSubTotal = (index) => {
    let updatedData = [...this.state.records];
    let rate = updatedData[index].rate || 0;
    let quantity = updatedData[index].quantity || 0;
    let loose = updatedData[index].loose || 0;
    if (quantity !== null && quantity !== 0) {
      updatedData[index].sub_total = quantity * rate;
    }
    else if (loose !== null && loose !== 0) {
      updatedData[index].sub_total = loose * rate;
    }
    this.setState({ records: updatedData }, () => {

      // this.calculateTotal(index);
      //  this.calculateIgstAmount(index);
      this.calculateCgstAmount(index);
      this.calculateSgstAmount(index);
      this.calculateGstAmount(index);
    });
  };

  calculateSubTotals = () => {
    const updatedData = this.state.records.map((record) => {
      const rate = record.rate || 0;
      const quantity = record.quantity || 0;
      const loose = record.loose || 0;
      let sub_total = 0;
      if (quantity > 0) {
        sub_total = quantity * rate;
      } else if (loose > 0) {
        sub_total = loose * rate;
      }

      // Return the updated record with the calculated subtotal
      return { ...record, sub_total };


    });

    // Update the state with the new records
    this.setState({ records: updatedData }, () => {

      this.calculateSection();
      this.calculateTotals();

    });
  };

  calculateTaxAmounts = () => {
    const updatedData = this.state.records.map((record) => {
      const taxrate = record.taxrate || 0;
      let sub_total = 0; // Initialize sub_total

      // Assuming you have a quantity and rate in each record
      const quantity = record.quantity || 0; // Default to 0 if undefined
      const rate = record.rate || 0; // Default to 0 if undefined

      // Calculate subtotal based on the tax rate
      if (taxrate === 5) {
        // Example calculation for 5% tax
        sub_total = quantity * rate * 1.05; // Including tax
      } else if (taxrate === 12) {
        // Example calculation for 12% tax
        sub_total = quantity * rate * 1.12; // Including tax
      } else if (taxrate === 18) {
        // Example calculation for 18% tax
        sub_total = quantity * rate * 1.18; // Including tax
      } else {
        // If tax rate is not recognized, just calculate the base subtotal
        sub_total = quantity * rate; // No tax applied
      }

      // Return the updated record with the calculated subtotal
      return { ...record, sub_total };
    });

    // Update the state with the new records
    this.setState({ records: updatedData });
  };


  calculateTotal = (index) => {
    let updatedData = [...this.state.records];
    let sub_total = updatedData[index].sub_total || 0;
    let discAmt = updatedData[index].discAmt || 0;
    let gstAmt = updatedData[index].gstAmt || 0;
    console.log(sub_total);
    console.log(discAmt);
    console.log(gstAmt);
    updatedData[index].total = sub_total - discAmt + gstAmt;
    console.log(updatedData[index].total);
    this.setState({ records: updatedData }, () => {
      this.calculateSection();
      this.calculateTaxAmountSection();
    });
  };

  calculateDiscountAmount = (index) => {
    let updatedData = [...this.state.records];
    let sub_total = updatedData[index].sub_total || 0;
    let discAmt = updatedData[index].discAmt || 0;
    let disc = updatedData[index].disc;
    updatedData[index].discAmt = (sub_total * (disc / 100));
    this.setState({ records: updatedData }, () => {
      this.calculateSection();

    });
  };
  calculateCgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let cgst = updatedData[index].cgst;
    let sub_total = updatedData[index].sub_total;
    updatedData[index].cgstAmt = (sub_total * (cgst / 100));
    this.setState({ records: updatedData }, () => {
      this.calculateSection();
      this.calculateTaxAmountSection();
    });
  };
  calculateSgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let sgst = updatedData[index].sgst;
    let sub_total = updatedData[index].sub_total;
    updatedData[index].sgstAmt = (sub_total * (sgst / 100));
    this.setState({ records: updatedData }, () => {
      this.calculateSection();
      this.calculateTaxAmountSection();
    });
  };
  calculateIgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let igst = updatedData[index].igst;
    console.log(igst);
    let sub_total = updatedData[index].sub_total;
    updatedData[index].igstAmt = (sub_total * (igst / 100));
    this.setState({ records: updatedData }, () => {
      this.calculateSection();

    });
  };

  calculateGstAmount = (index) => {
    let updatedData = [...this.state.records];
    let cgst = updatedData[index].cgst;
    let sgst = updatedData[index].sgst;
    let sub_total = updatedData[index].sub_total;
    let cgstAmt = updatedData[index].cgstAmt;
    let sgstAmt = updatedData[index].sgstAmt;
    updatedData[index].cgstAmt = (sub_total * (cgst / 100));
    updatedData[index].sgstAmt = (sub_total * (sgst / 100));
    updatedData[index].gstAmt = (cgstAmt + sgstAmt);
    this.setState({ records: updatedData }, () => {
      this.calculateSection();
      this.calculateTaxAmountSection();
    });
  };
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;
    let max = event.target.max;
    let value = event.target.value;
    if (
      event.target.name === "searchpharmacy_id"

    ) {
      if (value.length > max) {
        const value1 = value.slice(0, max);
        record.pharmacyid_error = "Maximum Length has exceeded.";
        record[event.target.name] = value1;
      }
      else {
        record.pharmacyid_error = "";
        record[event.target.name] = event.target.value;
      }
    }
    this.UpdateSearchColumnChange(record);
  };





  UpdateColumnChange = (record) => {

    let records = this.state.records;
    var recIndex = findWithAttr(records, "id", record.id);

    records[recIndex].pharmacy_id = record.pharmacy_id ? record.pharmacy_id : "";
    records[recIndex].pharmacy_name = record.pharmacy_name;
    records[recIndex].pharmacy_location = record.pharmacy_location;
    records[recIndex].address = record.address;
    records[recIndex].city = record.city;
    records[recIndex].country = record.country;
    records[recIndex].state = record.state;
    records[recIndex].zip_code = record.zip_code;
    // records[recIndex].phone_number = record.phone_number;

    this.setState({ records: records });
  }


  clearErrors = (errors) => {


    errors.address = ""
    errors.created_by = ""
    errors.voucher_no = ""
    errors.voucher_date = ""
    errors.invoice_date = ""
    errors.remarks = ""
    errors.vendorinvoice_no = ""
    errors.poref_no = ""
    return errors
  }

  clear = () => {

    let data = this.state.saleEntryData;

    data.phone_number = "";
    data.patient_name = "";
    data.address = "";
    this.state.patient_address = "";
    this.state.patient_name = "";
    this.state.searchData.phone_number = "";
    this.setState({ saleEntryData: data, add_btn: false, formSuccess: { message: "", }, });

  }

  cancel = () => {

    let data = this.state.saleEntryData;

    data.id = ""
    data.user_id = ""

    data.vendor_name = {
      label: "", value: ""
    }
    data.address = ""
    data.created_by = ""
    data.voucher_no = ""
    data.voucher_date = ""
    data.invoice_date = ""
    data.remarks = ""
    data.vendorinvoice_no = ""
    data.poref_no = ""


    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({
      saleEntryData: data, actions: "add", voucher_no: "", errors: errors, searchErrorMessage: "", records: [],
      search_action: false,
      totalAmtSec: {
        discAmt: 0,
        cgstAmt: 0,
        sgstAmt: 0,
        igstAmt: 0,
        total: 0,
        sub_total: 0,


      },

    });
  }

  clearimport = () => {
    this.setState({ file: "" });
  };

  clearMessage = () => {
    setTimeout(() => this.setState({
      formSuccess: { message: "", },
      tableSuccess: { message: "" }
    }), 5000);
  }

  /*  calculateSection = () => {
     let records = this.state.records;
     let totalAmtSec = this.state.totalAmtSec;
     let amt = 0.0;
     let taxAmt = 0.0;
     let taxVal = 0.0;
     let totalAmt = 0.0;
     let sub_total=0.0;
    console.log(records);
 
     records.map((data, i) => {
       if (undefined !== data.fee) {
         amt = Number(amt) + Number(data.fee);
       }
       if (undefined !== data.gst_amount) {
         taxAmt = Number(taxAmt) + Number(data.gst_amount);
       }
       if (undefined !== data.taxable_value) {
         taxVal = Number(taxVal) + Number(data.taxable_value);
       }
       if (undefined !== data.total_amount) {
         totalAmt = Number(totalAmt) + Number(data.total_amount);
       }
       if (undefined !== data.quantity) {
         sub_total = Number(data.quantity) * Number(data.p_rate);
       }
     });
 
     totalAmtSec.amt = amt;
     totalAmtSec.taxAmt = taxAmt;
     totalAmtSec.taxVal = taxVal;
     totalAmtSec.totalAmt = totalAmt;
     totalAmtSec.sub_total=sub_total;
     totalAmtSec.totalAmtWords = converter.toWords(totalAmt)+" rupees only";
     this.setState({ totalAmtSec });
 
     console.log(totalAmtSec);
   }; 
  */

  calculateSection = () => {
    let records = this.state.records;
    let totalAmtSec = { ...this.state.totalAmtSec };

    let discAmt = 0.0;
    let cgstAmt = 0.0;
    let sgstAmt = 0.0;
    let gstAmt = 0.0;
    let total = 0.0;
    let sub_total = 0.0;
    let roundtotal = 0.0;
    let rounddiff = 0.0;
    console.log(records);

    records.forEach((data, i) => {

      if (data.discAmt !== undefined) {
        discAmt += Number(data.discAmt);
      }
      if (data.cgstAmt !== undefined) {
        cgstAmt += Number(data.cgstAmt);
      }
      if (data.sgstAmt !== undefined) {
        sgstAmt += Number(data.sgstAmt);
      }

      if (data.gstAmt !== undefined) {
        gstAmt += Number(data.gstAmt);
      }
      if (data.total !== undefined) {
        total += Number(data.total);
      }
      if (data.sub_total !== undefined) {
        sub_total += Number(data.sub_total); // Sum up the sub_total for all records
      }
    });

    totalAmtSec.discAmt = discAmt;
    totalAmtSec.cgstAmt = cgstAmt;
    totalAmtSec.sgstAmt = sgstAmt;
    totalAmtSec.gstAmt = gstAmt;
    totalAmtSec.total = total;
    totalAmtSec.sub_total = sub_total;
    totalAmtSec.roundtotal = Math.round(total);
    totalAmtSec.rounddiff = total - totalAmtSec.roundtotal;
    totalAmtSec.totalAmtWords = converter.toWords(total) + " rupees only";

    this.setState({ totalAmtSec });

    console.log(totalAmtSec);
  };

  calculateTaxAmountSection = () => {
    let records = this.state.records;
    let totalTaxAmtSec = { ...this.state.totalTaxAmtSec };

    // Initialize tax amounts and totals for each tax rate
    let taxSummary = {
      5: { cgstAmt: 0.0, sgstAmt: 0.0, taxAmt: 0.0, sub_total: 0.0 },
      12: { cgstAmt: 0.0, sgstAmt: 0.0, taxAmt: 0.0, sub_total: 0.0 },
      18: { cgstAmt: 0.0, sgstAmt: 0.0, taxAmt: 0.0, sub_total: 0.0 },
    };


    records.forEach((data) => {
      const taxRate = data.taxrate;


      if (taxSummary[taxRate]) {
        console.log("Current Summary for Rate:", taxRate, taxSummary[taxRate]);

        taxSummary[taxRate].cgstAmt += Number(data.cgstAmt) || 0;
        taxSummary[taxRate].sgstAmt += Number(data.sgstAmt) || 0;
        taxSummary[taxRate].taxAmt += Number(data.cgstAmt) + Number(data.sgstAmt) || 0;
        taxSummary[taxRate].sub_total += Number(data.sub_total) || 0;

        console.log("Updated Summary for Rate:", taxRate, taxSummary[taxRate]);
      }
    });

    // Ensure totalTaxAmtSec has the necessary properties initialized
    totalTaxAmtSec = {
      cgstAmt5: totalTaxAmtSec.cgstAmt5 || 0,
      sgstAmt5: totalTaxAmtSec.sgstAmt5 || 0,
      taxAmt5: totalTaxAmtSec.taxAmt5 || 0,
      sub_total5: totalTaxAmtSec.sub_total5 || 0,
      cgstAmt12: totalTaxAmtSec.cgstAmt12 || 0,
      sgstAmt12: totalTaxAmtSec.sgstAmt12 || 0,
      taxAmt12: totalTaxAmtSec.taxAmt12 || 0,
      sub_total12: totalTaxAmtSec.sub_total12 || 0,
      cgstAmt18: totalTaxAmtSec.cgstAmt18 || 0,
      sgstAmt18: totalTaxAmtSec.sgstAmt18 || 0,
      taxAmt18: totalTaxAmtSec.taxAmt18 || 0,
      sub_total18: totalTaxAmtSec.sub_total18 || 0,
    };



    // Update the totalTaxAmtSec object with calculated values
    totalTaxAmtSec.cgstAmt5 = taxSummary[5].cgstAmt;
    totalTaxAmtSec.sgstAmt5 = taxSummary[5].sgstAmt;
    totalTaxAmtSec.taxAmt5 = taxSummary[5].taxAmt;
    totalTaxAmtSec.sub_total5 = taxSummary[5].sub_total;

    totalTaxAmtSec.cgstAmt12 = taxSummary[12].cgstAmt;
    totalTaxAmtSec.sgstAmt12 = taxSummary[12].sgstAmt;
    totalTaxAmtSec.taxAmt12 = taxSummary[12].taxAmt;
    totalTaxAmtSec.sub_total12 = taxSummary[12].sub_total;

    totalTaxAmtSec.cgstAmt18 = taxSummary[18].cgstAmt;
    totalTaxAmtSec.sgstAmt18 = taxSummary[18].sgstAmt;
    totalTaxAmtSec.taxAmt18 = taxSummary[18].taxAmt;
    totalTaxAmtSec.sub_total18 = taxSummary[18].sub_total;

    console.log("Final Total Tax Amount Section:", totalTaxAmtSec);

    // Update the state with the new total tax amounts
    this.setState({ totalTaxAmtSec });
  };

  generate() {
    let { saleEntryData, errors } = this.state;
    saleEntryData.type = "saleEntryData";
    errors = Validation(saleEntryData, errors);
    this.setState({ errors: errors });

    if (errors.formIsValid) {
      this.setState({ loading: true });
      let data = {
        user_id: this.props.userdata.id,
        phone_number: saleEntryData.phone_number || this.state.searchData.phone_number,
        patient_name: saleEntryData.patient_name || this.state.patient_name,
        address: saleEntryData.address || this.state.patient_address,
        remarks: saleEntryData.remarks,
        bill_date: saleEntryData.bill_date,
        doctorreg_no: saleEntryData.doctorreg_no,
        doctor_name: saleEntryData.doctor_name,
        created_by: saleEntryData.created_by,
        records: this.state.records
      };
      console.log(data);
      console.log(this.state.bill_no);
      console.log(this.state.saleEntryData.bill_date);
      this.props.history.push({
        pathname: "/saleinvoice/" + this.state.bill_no,
      });
      this.props.history.replace({
        pathname: "/saleinvoice/" + this.state.bill_no,
        state: {
          bill_no: this.state.bill_no,
          bill_date: this.state.saleEntryData.bill_date.toString(),
          patient_name: this.state.patient_name,
          patient_address: this.state.patient_address,
          doctorreg_no: this.state.saleEntryData.doctorreg_no,
          doctor_name: this.state.saleEntryData.doctor_name,
          sub_total: this.state.totalAmtSec.sub_total.toFixed(2),
          gstAmt: this.state.totalAmtSec.gstAmt.toFixed(2),
          discAmt: this.state.totalAmtSec.discAmt.toFixed(2),
          total: this.state.totalAmtSec.total.toFixed(2),
          totalAmtWords: this.state.totalAmtSec.totalAmtWords,
          roundtotal: this.state.totalAmtSec.roundtotal.toFixed(2),
          rounddiff: this.state.totalAmtSec.rounddiff.toFixed(2),
          sub_total5: this.state.totalTaxAmtSec.sub_total5,
          cgstAmt5: this.state.totalTaxAmtSec.cgstAmt5,
          sgstAmt5: this.state.totalTaxAmtSec.sgstAmt5,
          taxAmt5: this.state.totalTaxAmtSec.taxAmt5,
          sub_total12: this.state.totalTaxAmtSec.sub_total12,
          cgstAmt12: this.state.totalTaxAmtSec.cgstAmt12,
          sgstAmt12: this.state.totalTaxAmtSec.sgstAmt12,
          taxAmt12: this.state.totalTaxAmtSec.taxAmt12,
          sub_total18: this.state.totalTaxAmtSec.sub_total18,
          cgstAmt18: this.state.totalTaxAmtSec.cgstAmt18,
          sgstAmt18: this.state.totalTaxAmtSec.sgstAmt18,
          taxAmt18: this.state.totalTaxAmtSec.taxAmt18,
        }
      });
    }
  }
  addSaleEntryData() {


    let { saleEntryData, errors } = this.state;
    saleEntryData.type = "saleEntryData";
    errors = Validation(saleEntryData, errors);
    this.setState({ errors: errors });

    if (errors.formIsValid) {
      this.setState({ loading: true });

      let data = {
        user_id: this.props.userdata.id,
        phone_number: saleEntryData.phone_number || this.state.searchData.phone_number,
        patient_name: saleEntryData.patient_name || this.state.patient_name,
        address: saleEntryData.address || this.state.patient_address,
        remarks: saleEntryData.remarks,
        bill_date: saleEntryData.bill_date,
        doctorreg_no: saleEntryData.doctorreg_no,
        doctor_name: saleEntryData.doctor_name,
        created_by: saleEntryData.created_by,
        records: this.state.records,
        discPercent: this.state.discountPercentage,
        subtotalAmount: this.state.totalAmtSec.sub_total.toFixed(2),
        totalAmount: this.state.totalAmtSec.total.toFixed(2),
        discountAmt: this.state.totalAmtSec.discAmt.toFixed(2),
        gstAmt: this.state.totalAmtSec.gstAmt.toFixed(2),
        cgstAmt5:this.state.totalTaxAmtSec.cgstAmt5.toFixed(2),
        sgstAmt5:this.state.totalTaxAmtSec.sgstAmt5.toFixed(2),
        taxAmt5:this.state.totalTaxAmtSec.taxAmt5.toFixed(2),
        sub_total5:this.state.totalTaxAmtSec.sub_total5.toFixed(2),
        cgstAmt12:this.state.totalTaxAmtSec.cgstAmt12.toFixed(2),
        sgstAmt12:this.state.totalTaxAmtSec.sgstAmt12.toFixed(2),
        taxAmt12:this.state.totalTaxAmtSec.taxAmt12.toFixed(2),
        sub_total12:this.state.totalTaxAmtSec.sub_total12.toFixed(2),
        cgstAmt18:this.state.totalTaxAmtSec.cgstAmt18.toFixed(2),
        sgstAmt18:this.state.totalTaxAmtSec.sgstAmt18.toFixed(2),
        taxAmt18:this.state.totalTaxAmtSec.taxAmt18.toFixed(2),
        sub_total18:this.state.totalTaxAmtSec.sub_total18.toFixed(2),
        payment_mode: saleEntryData.payment_mode.label,
        customer_type: 'patient',
      };
      console.log(data);
      let records = this.state.records;

      axios

        .post(addSaleEntryData, data, CONFIG)
        .then((addVendorResponse) => {
          let res = addVendorResponse.data;
          console.log(res)
          if (res.status === "1") {
            //  console.log(res.data[0].voucher_no);

            this.setState({
              records: records,
              searchTableSuccess: res,
              loading: false,
              editformSuccess: res,
              bill_no: res.data[0].bill_no,
              bill_date: this.state.bill_date
            });
            toast.success("The Sale Entry voucher have been added successfully.", {
              className: "toast-success"
            });

            this.clearMessage();
            //this.clearAll();
          } else {
            this.setState({
              searchrecords: records,
              searchTableSuccess: res,
              editformSuccess: res,
              loading: false,
            });
            toast.error("Sale Entry voucher could not be Added. ", {
              className: "toast-error"
            });
            this.clearMessage();
          }
        })
        .catch(() => { });

    }
  }

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { purchaseEntryData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    console.log(place);
    purchaseEntryData.address = place.formatted_address;


    this.setState({
      purchaseEntryData,
    });
  }
  componentDidMount = () => {

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllPharmacyItemList, { user_id: this.props.userdata.id }, CONFIG)
      .then((result) => {
        let supplies = result.data.data;
        console.log(supplies);
        //to rename json key object
        supplies.forEach((obj) => renameKey(obj, "drug_id", "value"));
        supplies.forEach((obj) => renameKey(obj, "generic_name", "label"));

        //set first record as consultation fee by default
        /*  let consultation_fee_record = {};
         consultation_fee_record.s_no = 1;
         consultation_fee_record.description = "Consultation Fee";
         consultation_fee_record.fee = completion_data.consultation_fee.replace(/[^0-9]/g, '');
         consultation_fee_record.disc = "";
         consultation_fee_record.taxable_value = "";
         consultation_fee_record.gst_rate = "";
         consultation_fee_record.gst_amount = "";
         consultation_fee_record.total_amount = completion_data.consultation_fee.replace(/[^0-9]/g, '');
*/
        let records = [];
        // records.push(consultation_fee_record); 

        let defaultEnterSize = this.state.defaultEnterSize;

        for (let i = 1; i <= defaultEnterSize; i++) {
          let default_rec = Object.assign({}, record);
          default_rec.s_no = i;
          records.push(default_rec);
        }

        this.setState(
          {
            supplies: supplies,
            loading: false,
            records: records,
          },
          () => {
            this.calculateSection();
          }
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
      });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllPharmacyVendorList, { user_id: this.props.userdata.id }, CONFIG)
      .then(({ data }) => {
        const mastervendorNameList = data.data;
        const uniqueDrugNames = new Set();
        const mastervendorNames = mastervendorNameList
          .filter((element) => {

            if (!uniqueDrugNames.has(element.vendor_name)) {
              uniqueDrugNames.add(element.vendor_name);
              return true;
            }
            return false;
          })
          .map((element) => ({
            value: element.vendor_id,
            label: element.vendor_name,

          }));

        this.setState({ mastervendorNames });
      })
      .catch((error) => {
        console.error("Error fetching drug data:", error);

      });

    axios
      .post(getPharmacyShortDetail, { user_id: this.props.userdata.id }, CONFIG)
      .then((Respons) => {
        let data = Respons.data.data[0];
        console.log(data.state);

        if (data.status == "1")
          this.setState({
            State_value: data.state

          });
      }).catch((e) => { });




  };
  onDeleteClick(record) {

    this.setState({ addPopup: true, record: record });
  }
  deleteRecord(record) {
    // Filter out the record to delete
    const updatedRecords = this.state.records.filter(item => item.s_no !== record.s_no);

    // Update the state with the new records
    this.setState({ records: updatedRecords, addPopup: false }, () => {
      toast.success("The Sale Entry detail has been deleted successfully.", {
        className: "toast-success"
      });
      this.calculateSubTotals();
      console.log(updatedRecords);
    });
  }
  handlepaymentselectChange = (selectedOptions, e) => {
    let data = this.state.saleEntryData;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;

    this.setState({ saleEntryData: data, errors, searchErrorMessage: "" });
  }
  /*  handleselectChange = (index, key) => selectedOption => {
    
     // Create a copy of the current table data
     let updatedData = [...this.state.records];
     
     // Update the specific row's key with the selected option value
     updatedData[index][key] = selectedOption ? selectedOption.label : "";
 
     // Set the state with the updated data
     this.setState({ records: updatedData });
 
     axios
     .post(getitemlistforPharmacyDrug,{user_id:this.props.userdata.id,drug_name:selectedOption.label}, CONFIG)
     .then((drugRespons) => {
       let ItemList = drugRespons.data.data;
     
       ItemList.forEach((element, index) => {
         ItemList.push({
          
           pack:element.pack,
           cgst: element.cgst,
           sgst:element.sgst,
           igst:element.igst
           
         });
         let records = this.state.records;
         var recIndex = findWithAttr(records, "s_no", record.s_no);
         if (null != record) {
         
           records[recIndex].pack = element.unit_of_measure;
           records[recIndex].cgst = element.cgst + " %";
           records[recIndex].gst_amount =
             (record.tax_percentage / 100) * record.amount;
           records[recIndex].total_amount =
             Number(records[recIndex].fee) +
             Number(records[recIndex].gst_amount);
         } else {
          
           records[recIndex].fee = "";
           records[recIndex].gst_rate = "";
           records[recIndex].gst_amount = "";
           records[recIndex].total_amount = "";
         }
   
         console.log(recIndex);
         console.log(records.length - 1);
         if (recIndex === records.length - 1) {
           let default_rec = Object.assign({}, record);
           default_rec.s_no = records.length + 1;
           records.push(default_rec);
         }
   
       });
      
     })
        
       
 
   
 
 
   }; */

  /*   handleselectChange = (index, key) => selectedOption => {
      // Create a copy of the current table data
      let updatedData = [...this.state.records];
      
      // Update the specific row's key with the selected option value
      updatedData[index][key] = selectedOption ? selectedOption.label : "";
  
      // Set the state with the updated data
      this.setState({ records: updatedData });
  
       axios
      .post(getPharmacyShortDetail,{user_id:this.props.userdata.id}, CONFIG)
      .then((Respons) => {
        let data = Respons.data.data[0];
        console.log(data.state);
     
        if (data.status == "1")
          this.setState({
         State_value:data.state
  
          });
      }).catch((e) => { });
    
  
      // Post request to fetch item details
      axios
      .post(getitemlistforPharmacyDrug, { user_id: this.props.userdata.id, drug_name: selectedOption.label }, CONFIG)
      .then((drugResponse) => {
          let itemList = drugResponse.data.data;
          console.log(itemList); // Verify the API response
  
          // Ensure the API response contains the `pack` value
          let item = itemList.find(item => item.drug_name === selectedOption.label);
          if (item) {
              // Update the specific row with additional details
              updatedData[index] = {
                  ...updatedData[index],
                  pack: item.unit_of_measure,
                  cgst: this.state.State_value === this.state.gst_state ? item.cgst + " %" : "",
                  sgst: this.state.State_value === this.state.gst_state ? item.sgst + " %" : "",
                  igst: item.igst + " %",
                  sub_total:updatedData[index].quantity*(updatedData[index].p_rate-(updatedData[index].disc*updatedData[index].p_rate)),
                 // gst_amount: (updatedData[index].tax_percentage / 100) * updatedData[index].amount,
                //  total_amount: Number(updatedData[index].fee) + Number(updatedData[index].gst_amount)
              };
              console.log(updatedData[index]); // Verify the updated record
          }
  
          // Set the state with the updated records
          this.setState({ records: updatedData });
      })
      .catch((error) => {
          console.error("Error fetching item details:", error);
      });
  }; */

  /*   handleselectChange = (index, key) => selectedOption => {
      // Create a copy of the current table data
      let updatedData = [...this.state.records];
      
      // Update the specific row's key with the selected option value
      updatedData[index][key] = selectedOption ? selectedOption.label : "";
  
      // Set the state with the updated data
      this.setState({ records: updatedData });
  
      // Fetch the state value
      axios
      .post(getPharmacyShortDetail, { user_id: this.props.userdata.id }, CONFIG)
      .then((response) => {
          let data = response.data.data[0];
        
          if (response.data.status == "1") {
              this.setState({ State_value: data.state }, () => {
                  // Fetch item details after state is updated
                    console.log(this.state.gst_state);
  
                  axios
                  .post(getitemlistforPharmacyDrug, { user_id: this.props.userdata.id, drug_name: selectedOption.label }, CONFIG)
                  .then((drugResponse) => {
                      let itemList = drugResponse.data.data;
                      console.log(itemList); // Verify the API response
  
                      // Ensure the API response contains the `pack` value
                      let item = itemList.find(item => item.drug_name === selectedOption.label);
                      if (item) {
                          // Update the specific row with additional details
                          updatedData[index] = {
                              ...updatedData[index],
                              pack: item.unit_of_measure,
                              cgst: this.state.State_value === this.state.gst_state ? item.cgst + " %" : "",
                              sgst: this.state.State_value === this.state.gst_state ? item.sgst + " %" : "",
                              igst: item.igst + " %",
                              sub_total: updatedData[index].quantity * (updatedData[index].p_rate - (updatedData[index].disc * updatedData[index].p_rate)),
                          };
                          console.log(updatedData[index]); // Verify the updated record
                      }
  
                      // Set the state with the updated records
                      this.setState({ records: updatedData });
                  })
                  .catch((error) => {
                      console.error("Error fetching item details:", error);
                  });
              });
          }
      })
      .catch((error) => {
          console.error("Error fetching state details:", error);
      });
  }; */
  /* handleselectChange = (selectedOption) => {
    const updatedRecords = this.state.records.map(record => ({
      ...record,
      drug_name: selectedOption ? selectedOption.value : '', // Set drug_name for all records
    }));
    this.setState({ records: updatedRecords });
  }; */

  handleselectChange = (index, key) => selectedOption => {
    // Create a copy of the current table data
    let updatedData = [...this.state.records];
    const drugNameToSet = selectedOption ? selectedOption.label : ''; // Get the selected drug name

    // Update the drug_name for the specific record
    updatedData[index].generic_name = drugNameToSet;


    // Update the specific row's key with the selected option value
    updatedData[index][key] = selectedOption ? selectedOption.label : "";
    console.log(updatedData[index][key])
    // Set the state with the updated data
    this.setState({ records: updatedData, selectedBatches: [], batches: [] });

    // Fetch the state value
    axios
      .post(getPharmacyShortDetail, { user_id: this.props.userdata.id }, CONFIG)
      .then((response) => {
        let data = response.data.data[0];


        if (response.data.status === "1") {
          this.setState({ State_value: data.state }, () => {
            // Fetch item details after state is updated
            console.log("State : ", this.state.State_value);
            console.log("GST State: ", this.state.gst_state);
            this.fetchItemDetails(index, selectedOption);
            this.getBatchandExpDateBydrugname(index, selectedOption);
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching state details:", error);
      });
  };

  handleShow = (index) => {
    const currentRecord = this.state.records[index];
    console.log(currentRecord);
    console.log("curent index", index);
    console.log(this.state.selectedBatches);

    // Create the current batch object
    const currentBatchInRow = {
      batch_no: currentRecord.batch_no || 'Select Batch',
      exp_date: currentRecord.exp_date || 'N/A',
    };
    console.log(currentBatchInRow);

    // Determine the selectedBatch based on the existence of batch_no
    const selectedBatch = currentRecord.batch_no
      ? currentBatchInRow // If batch_no exists, use currentBatchInRow
      : { batch_no: 'Select Batch', exp_date: 'N/A' }; // If not, use default

    // Set the current row index and selected batch in a single setState call
    this.setState({
      currentRow: index,
      selectedBatch: currentBatchInRow, // Set the selected batch based on the logic
      showModel: true,
      selectedBatches: [], // Show the modal
      //batches:[],
    });

  };
  handleBatchClose = () => {
    this.setState({ showModel: false });

  };
  handleSelectBatchClick = () => {
    const { selectedBatches, selectedExpdates, currentRow } = this.state;

    if (selectedBatches.length > 0) {
      console.log("Remove previous batch", selectedBatches.shift()); // Remove the first element from selectedBatches
      console.log("Remove previous exp_date", selectedExpdates.shift()); // Remove the first element from selectedExpdates

      this.selectBatch(selectedBatches, selectedExpdates, currentRow);

      // Clear selections
      this.setState({
        selectedBatches: [],
        selectedExpdates: []
      });
    } else {
      console.log('No batches selected.');
    }
  };

  selectBatch = (batches, expdates, selectedIndex) => {
    const updatedRecords = [...this.state.records];
    const batcheslength = batches.length;
    console.log("Selected Index:", selectedIndex);
    console.log("Batches:", batches);
    console.log("old batch length", batcheslength);
    console.log("New batches length", batches.length);
    console.log("First Record:", updatedRecords[0]);
    console.log("Current Row:", this.state.currentRow);

    const drugNameToSet = updatedRecords[this.state.currentRow]?.generic_name;
    const packToSet = updatedRecords[this.state.currentRow]?.pack;
    const storagebin = updatedRecords[this.state.currentRow]?.storage_bin;
    const mrp = updatedRecords[this.state.currentRow]?.mrp;
    const cgst = updatedRecords[this.state.currentRow]?.cgst;
    const sgst = updatedRecords[this.state.currentRow]?.sgst;
    const hsn_code = updatedRecords[this.state.currentRow]?.hsn_code;

    console.log("Drug Name to Set:", drugNameToSet);

    if (this.state.currentRow === 0) {
      // Loop through each selected batch and update the corresponding row
      batches.forEach((batch, index) => {
        if (updatedRecords[this.state.currentRow + index]) {
          updatedRecords[this.state.currentRow + index] = {
            ...updatedRecords[this.state.currentRow + index],
            batch_no: batch,
            generic_name: drugNameToSet,
            pack: packToSet,
            storage_bin: storagebin,
            mrp: mrp,
            cgst: cgst,
            sgst: sgst,
            hsn_code: hsn_code,
            taxrate: (Number(cgst) || 0) + (Number(sgst) || 0),
          };
          console.log(this.state.currentRow + index);
          console.log("Updated Record:", updatedRecords[this.state.currentRow + index]);
        }
      });

      expdates.forEach((exp_date, index) => {
        if (updatedRecords[this.state.currentRow + index]) {
          updatedRecords[this.state.currentRow + index].exp_date = exp_date;
          console.log("Updated Record with Exp Date:", updatedRecords[this.state.currentRow + index]);
        }
      });
    } else {
      // Ensure selectedBatch is defined
      console.log(batches.length);

      const selectedBatch = batches.length > 0 ? batches[batches.length - 1] : null;
      if (updatedRecords[this.state.currentRow]) {
        updatedRecords[this.state.currentRow] = {
          ...updatedRecords[this.state.currentRow],
          batch_no: selectedBatch,
          generic_name: drugNameToSet,
          pack: packToSet,
          storage_bin: storagebin,
          mrp: mrp,
          cgst: cgst,
          sgst: sgst,
          hsn_code: hsn_code,
          taxrate: (Number(cgst) || 0) + (Number(sgst) || 0),
        };
        console.log("Updated Record:", updatedRecords[this.state.currentRow]);
      }
    }

    this.setState({ records: updatedRecords }, () => {
      console.log('Updated Records after Set State:', this.state.records);
      this.calculateTaxAmountSection();
      this.setState({ selectedBatches: [], selectedExpdates: [] });
    });
  };


  getBatchnobyDrugname = (index, selectedOption) => {
    axios.post(getBatchnobydrugnameandExpDate, { generic_name: selectedOption.label }, CONFIG)
      .then((drugResponse) => {

        let batch_no = drugResponse.data.data;
        console.log("Fetched batch: ", batch_no[0]);
      })
  }

  getBatchandExpDateBydrugname = (index, selectedOption) => {
    if (selectedOption != null) {
      this.setState({ batches: [] });
      axios.post(getBatchandExpDateBydrugname, { generic_name: selectedOption.label }, CONFIG)
        .then((drugResponse) => {
          // Ensure batches is always an array
          let batches = Array.isArray(drugResponse.data.data) ? drugResponse.data.data : [];
          console.log("Fetched batches: ", batches);
          this.setState({ batches });
        })
        .catch(error => {
          console.error('Error fetching batch data:', error);
          this.setState({ batches: [] }); // Set to empty array on error
        });
    }
  }
  fetchItemDetails = (index, selectedOption) => {
    // Fetch item list for the selected drug
    if (selectedOption != null) {

      axios
        .post(getitemlistforPharmacyDrug, { user_id: this.props.userdata.id, generic_name: selectedOption.label }, CONFIG)
        .then((drugResponse) => {
          const itemList = drugResponse.data.data;
          console.log("Fetched Item List: ", itemList); // Verify the API response

          // Fetch batch numbers and expiry dates for the selected drug
          return axios.post(getBatchnobydrugnameandExpDate, { generic_name: selectedOption.label }, CONFIG)
            .then((batchResponse) => {
              const batch_no = batchResponse.data.data;
              console.log("Fetched Batch Numbers: ", batch_no); // Verify the batch response

              // Find the item in the item list
              const item = itemList.find(item => item.generic_name === selectedOption.label);
              if (item) {
                // Create a copy of the current table data
                const updatedData = [...this.state.records];

                // Update the specific row with additional details
                updatedData[index] = {
                  ...updatedData[index],
                  drug_id:selectedOption.value,
                  pack: item.unit_of_measure,
                  batch_no: item.batch_no, // Ensure batch_no is a string
                  mrp: batch_no.length > 0 ? batch_no[0].mrp : "", // Handle case where batch_no might be empty
                  storage_bin: item.storage_bin,
                  conversion_factor: item.conversion_factor,
                  cgst: item.cgst,
                  sgst: item.sgst,
                  hsn_code: item.hsn_code,

                  // igst: this.state.State_value === this.state.gst_state ? item.igst : "",
                  // sub_total: updatedData[index].quantity * updatedData[index].rate,
                };

                console.log("Updated Record: ", updatedData[index]); // Verify the updated record

                // Set the state with the updated records
                this.setState({ records: updatedData }, () => {
                  this.calculateSection(); // Call calculateSection after state update
                  this.calculateTaxAmountSection();
                });
              }
            });
        })

        .catch((error) => {
          console.error("Error fetching item details:", error);
        });
    }
  };

  handleColumnChange = (record) => {
    record.online_prescription = ("Yes" === record.online_prescription) ? "No" : "Yes";
    this.UpdateColumnChange(record);
  }
  validateMaxLength(name, value, max) {
    let data = this.state.saleEntryData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ saleEntryData: data, errors: errors });
  }


  onFileChange = event => {

    // Update the state
    this.setState({ file: event.target.files[0] });
    readXlsxFile(event.target.files[0]).then((rows) => {
      console.log(rows);
      console.log("rows");
      this.setState({ pharmacyData: rows });
      // `rows` is an array of rows
      // each row being an array of cells.
    })

  };


  handlevendorselectChange = async (selectedOptions, e) => {
    let data = this.state.purchaseEntryData;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;


    this.setState({ purchaseEntryData: data, errors, searchErrorMessage: "" });

    axios
      .post(getgststatebyVendorname, { user_id: this.props.userdata.id, vendor_name: selectedOptions.label }, CONFIG)
      .then((Respons) => {
        let resdata = Respons.data;
        let data = resdata.data[0];
        console.log(resdata.status);

        if (resdata.status == "1") {
          this.setState({ gst_state: data.gst_state }, () => {
            console.log("Updated GST State: ", this.state.gst_state);
          });
        }

      }).catch((e) => { });

  }
  render() {

    const payment_mode = [
      { value: 0, label: "Cash" },
      { value: 1, label: "UPI" },
      { value: 2, label: "Credit card" },
      { value: 3, label: "Debit card" },

    ];
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup: true })

    };
    const { saleEntryData, records, batches, bill_no, bill_date, selectedBatch, currentBatchinrow, showModel, errors, formSuccess, tableSuccess, hospitaltimeout, mastervendorNames, gst_state, State_value, searchErrorMessage, searchTableSuccess, loading } = this.state;
    const formattedSubTotal12 = this.state.totalTaxAmtSec.sub_total12.toFixed(2);

    return (

      <div className="col-sm-12">
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <BatchSelectionModal
          show={showModel}
          handleBatchClose={this.handleBatchClose}
          batches={batches}
          selectBatch={this.selectBatch}
        //selectedBatch={selectedBatch}
        //handleSelectBatchClick={this.handleSelectBatchClick}
        />
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />
        <div className="main-content bacfot">
          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <BusinessUserSideBar />
              </div>
              {
                (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                  <div className="container-fluid">
                    <div className="checkout-tabs">

                      <div className="row">
                        <div className="col-sm-12 pr0">
                          <div className="card">
                            <div className="card-body a-page-1-hight-right ">
                              {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                              <Popupinfo
                                show={!this.state.hospitalpopup}
                                onHide={PopupHospitalClose}
                                title={"Alert!!"}
                                message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>) :
                  (


                    <section className="main-body hospital-profilegap">
                      <div className="container-fluid">
                        <div className="row checkout-tabs">
                          <div className="col-sm-12">
                            <h4 className="font-weight-bold"> Sale Entry Voucher </h4>
                          </div>
                          <div className="col-sm-12 pr0">
                            <div className="card b-12-hite">
                              <div class="card-header">
                                <div class="card-title text-center fs-2">
                                  Sale Entry Voucher
                                  <button className="signupbtn abtn button float-right" onClick={this.SalesB2B}>B2B</button>

                                </div>

                              </div>
                              <div className="card-body">
                                <div className="user-notification">
                                  <SpanField
                                    classname={(0 == formSuccess.status) ? "text-danger user-notification" :
                                      "text-success user-notification"}
                                    title={formSuccess.message}
                                  />
                                </div>
                                <div>



                                  <div className="row">
                                    {/* <div className="solid">     */}
                                    <div className="col-sm-6">

                                      <div className="frllftfull">
                                        <div className="form-group frllft mbbre">
                                          <Label title="Patient Mobile No." isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"phone_number"}
                                                controlFunc={this.handlePhoneInput}
                                                content={this.state.searchData.phone_number}
                                                placeholder={""}
                                                max={50}

                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.phone_number}
                                            />
                                          </div>
                                        </div>
<div>
  
</div>
                                        <div className="form-group">
                                          <div >
                                            <div className="d-flex justify-content-left">
                                              <button
                                                className="signupbtn abtn btdpml ml-3 mr-2 "
                                                onClick={this.SearchPatient} aria-label="Search Patient"
                                              >
                                                Search
                                              </button>
                                              <button
                                                className="signupbtn abtn btdpml "
                                                onClick={this.clear} aria-label="Reset Search"
                                              >
                                                Reset
                                              </button>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft mbbre">
                                          <Label title="Patient Name" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf salemarginright">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"patient_name"}
                                                controlFunc={this.handleAdd}
                                                content={saleEntryData.patient_name || '' || this.state.patient_name}
                                                placeholder={""}
                                                max={50}

                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.patient_name}
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group frlrclfgf">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <button className="signupbtn abtn btdpml hidecontent " onClick={this.addPatient} disabled={this.state.add_btn}>Add {" "}</button>
                                            </div>

                                          </div>
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft mbbre">
                                          <Label title="Address" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrit">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"address"}
                                                controlFunc={this.handleAdd}
                                                content={saleEntryData.address || '' || this.state.patient_address}
                                                placeholder={""}
                                                id="address"
                                                max={50}
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.address}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft ">
                                          <Label title="Remarks" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrit">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"remarks"}
                                                controlFunc={this.handleAdd}
                                                content={saleEntryData.remarks || ''}
                                                placeholder={""}
                                                max={200}
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.remarks}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="frllftfull">
                                        <div className="form-group frllft mbbre">
                                          <Label title="Bill No" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"bill_no"}
                                                controlFunc={this.handleAdd}
                                                content={bill_no || ''}
                                                readOnly={true}
                                                placeholder={""}
                                                max={50}
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.bill_no}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group frlrclfgf-1">
                                          <Label title="Bill Date" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <DatePicker
                                                className="form-control"
                                                value={saleEntryData.bill_date}
                                                format="dd-MM-yyyy"
                                                onChange={(date) => this.handleDatePickerChange(date, 'bill_date')}
                                                yearPlaceholder='YYYY'
                                                monthPlaceholder='MM'
                                                dayPlaceholder='DD'
                                                disabled={true}
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.bill_date}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft mbbre">
                                          <Label title="Doctor RegNo." isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"doctorreg_no"}
                                                controlFunc={this.handleAdd}
                                                content={saleEntryData.doctorreg_no || ''}
                                                placeholder={""}
                                                max={50}
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.doctorreg_no}
                                            />
                                          </div>
                                        </div>
                                        {/* <div className="form-group frlrclfgf-1">
      <Label title="Invoice Date" isMandatory={true} />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
        <DatePicker
            className="form-control"
            value={saleEntryData.invoice_date}
            format="dd-MM-yyyy"
            onChange={(date) => this.handleDatePickerChange(date, 'invoice_date')} 
            yearPlaceholder='YYYY'
            monthPlaceholder='MM'
            dayPlaceholder='DD'
           
          />         
        </div>
        <SpanField
          classname="text-danger"
          title={errors.invoice_date}
        />
      </div>
    </div> */}
                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft mbbre">
                                          <Label title="Doctor Name" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <div >
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"doctor_name"}
                                                controlFunc={this.handleAdd}
                                                content={saleEntryData.doctor_name || ''}
                                                placeholder={""}
                                                max={50}
                                              />

                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.doctor_name}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="frllftfull">
                                        <div className="form-group frllft mbbre">
                                          <Label title="Created By" isMandatory={true} />
                                        </div>
                                        <div className="form-group frlrclfgf">
                                          <div className="position-relative">
                                            <div className="input-group">
                                              <InputField
                                                inputType={"text"}
                                                name={"created_by"}
                                                controlFunc={this.handleAdd}
                                                content={saleEntryData.created_by || ''}
                                                placeholder={""}
                                                max={50}
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.created_by}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* </div>    */}

                                    <div className="col-sm-12">
                                      <div className="card ">
                                        <div className="card-body ">
                                          <div
                                            className="tab-content"
                                            id="v-pills-tabContent"
                                          >


                                            <div
                                              className="tab-pane fade show active"
                                              id="v-pills-shipping"
                                              role="tabpanel"
                                              aria-labelledby="v-pills-shipping-tab"
                                            >
                                              <div className="user-notification">
                                                <SpanField
                                                  classname={
                                                    "0" === tableSuccess.status
                                                      ? "text-danger user-notification"
                                                      : "text-success user-notification"
                                                  }
                                                  title={tableSuccess.message}
                                                />
                                              </div>
                                              <div style={{ overflowY: 'auto' }}>
                                                <ReactDatatable
                                                  key={0}
                                                  config={this.config}
                                                  records={this.state.records}
                                                  columns={this.columns}
                                                  extraButtons={this.extraButtons}
                                                  className="purchaseentrytable table-bordered table-striped kas "

                                                />
                                              </div>
                                              <table
                                                className="saleright_bill_section"
                                                cellPadding="10%"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td width="10%" >
                                                      Sub Total
                                                    </td>
                                                    <td
                                                      width="10%"
                                                      className="cellTextRight"
                                                    >
                                                      {this.state.totalAmtSec.sub_total.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td width="10%">
                                                      Disc Amt
                                                    </td>
                                                    <td
                                                      width="10%"
                                                      className="cellTextRight"
                                                    >
                                                      {this.state.totalAmtSec.discAmt.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td width="10%" >
                                                      GST Amt
                                                    </td>
                                                    <td
                                                      width="10%"
                                                      className="cellTextRight"
                                                    >
                                                      {this.state.totalAmtSec.gstAmt ? this.state.totalAmtSec.gstAmt.toFixed(2) : '0.00'}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td width="10%" >
                                                      Invoice value
                                                    </td>
                                                    <td
                                                      width="10%"
                                                      colSpan="2"
                                                      className="cellTextRight"
                                                    >
                                                      {this.state.totalAmtSec.total.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                  {/*  <tr>
                                            <td width="10%" >
                                            IGST Amt
                                            </td> 
                                            <td
                                              width="10%"
                                              colSpan="2"
                                              className="cellTextRight"
                                            >
                                           {this.state.totalAmtSec.igstAmt.toFixed(2)}
                                            </td>
                                            </tr> */}


                                                </tbody>
                                              </table>
                                              <div>
                                                {/* Add other content above the table if needed */}
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                                <table className="saleright_bill_section">
                                                  <tbody>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* This is the empty row */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* This is the empty row */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td width="20%">
                                                        Disc %
                                                      </td>
                                                      <td className="cellTextRight">
                                                        <InputField
                                                          inputType="number"
                                                          isMandatory={true}
                                                          name={"disc"}
                                                          controlFunc={this.handleInputChange}
                                                          content={this.state.discountPercentage}
                                                          step="any"
                                                          placeholder={""}
                                                        />
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colSpan="2">
                                                        {/* You can add any content here if needed */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td width="50%">
                                                        Payment Mode
                                                      </td>
                                                      <td className="cellTextRight">
                                                        <Select maxMenuHeight={120}
                                                          className="selectcls p-0"
                                                          name="payment_mode"
                                                          isMandatory={true}
                                                          value={saleEntryData.payment_mode}
                                                          onChange={this.handlepaymentselectChange}
                                                          options={payment_mode}
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div className="col-sm-4">
                                                <table
                                                  className="total_bill_section"
                                                  cellPadding="10%"
                                                >

                                                  <tbody>
                                                    <tr>
                                                      <td width="10%" colSpan="2">
                                                        GST
                                                      </td>
                                                      <td
                                                        width="30%"
                                                        className="cellTextRight"
                                                      >
                                                        Taxable Amt
                                                      </td>
                                                      <td
                                                        width="30%"
                                                        className="cellTextRight"
                                                      >
                                                        CGST Amt
                                                      </td>
                                                      <td
                                                        width="30%"
                                                        colSpan="2"
                                                        className="cellTextRight"
                                                      >
                                                        SGST Amt
                                                      </td>
                                                      <td
                                                        width="15%"
                                                        className="cellTextRight"
                                                      >
                                                        Tax Amt
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td width="30%" colSpan="2">
                                                        5%
                                                      </td>
                                                      <td
                                                        width="20%"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.sub_total5.toFixed(2)}
                                                      </td>
                                                      <td
                                                        width="15%"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.cgstAmt5.toFixed(2)}
                                                      </td>
                                                      <td
                                                        width="20%"
                                                        colSpan="2"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.sgstAmt5.toFixed(2)}
                                                      </td>
                                                      <td
                                                        width="15%"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.taxAmt5.toFixed(2)}
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td width="30%" colSpan="2">
                                                        12%
                                                      </td>
                                                      <td
                                                        width="20%"
                                                        className="cellTextRight"
                                                      >
                                                        {formattedSubTotal12}
                                                      </td>
                                                      <td
                                                        width="15%"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.cgstAmt12.toFixed(2)}
                                                      </td>
                                                      <td
                                                        width="20%"
                                                        colSpan="2"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.sgstAmt12.toFixed(2)}
                                                      </td>
                                                      <td
                                                        width="15%"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.taxAmt12.toFixed(2)}
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td width="30%" colSpan="2">
                                                        18%
                                                      </td>
                                                      <td
                                                        width="20%"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.sub_total18.toFixed(2)}
                                                      </td>
                                                      <td
                                                        width="15%"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.cgstAmt18.toFixed(2)}
                                                      </td>
                                                      <td
                                                        width="20%"
                                                        colSpan="2"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.sgstAmt18.toFixed(2)}
                                                      </td>
                                                      <td
                                                        width="15%"
                                                        className="cellTextRight"
                                                      >
                                                        {this.state.totalTaxAmtSec.taxAmt18.toFixed(2)}
                                                      </td>
                                                    </tr>

                                                  </tbody>
                                                </table>
                                              </div>




                                              <Popup
                                                show={this.state.addPopup}
                                                onHide={PopupClose}
                                                onConfirm={PopupConfirm}
                                                title={"Confirmation"}
                                                message={
                                                  "Are you sure you want to delete this record ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12">


                                      <div className=" float-right mr-2">



                                        {/*       <tbody>
                                        <tr>
                                            <td width="40%" colSpan="2">
                                                Sub Total
                                            </td>
                                            <td colSpan="4"></td>
                                            <td className="cellTextRight">
                                                <input
                                                type="text"
                                                value={this.state.totalAmtSec.totalAmt}
                                                onChange={(e) => this.setState({ totalAmtSec: { totalAmt: e.target.value } })}
                                                />
                                            </td>
                                            </tr>
                                            <tr>
                                            <td width="30%" colSpan="2">
                                              Discount Amount
                                            </td>
                                            <td colSpan="4"></td>
                                            <td className="cellTextRight">
                                            <input
                                                type="text"
                                                value={this.state.totalAmtSec.totalAmt}
                                                onChange={(e) => this.setState({ totalAmtSec: { totalAmt: e.target.value } })}
                                                />
                                            </td>
                                          </tr>
                                          

                                          <tr>
                                            <td width="30%" colSpan="2">
                                              CGST%
                                            </td>
                                            <td colSpan="4"></td>
                                            <td className="cellTextRight">
                                            <input
                                                type="text"
                                                value={this.state.totalAmtSec.totalAmt}
                                                onChange={(e) => this.setState({ totalAmtSec: { totalAmt: e.target.value } })}
                                                />
                                            </td>
                                          </tr>
                                          <td width="30%" colSpan="2">
                                              SGST%
                                            </td>
                                            <td colSpan="4"></td>
                                            <td className="cellTextRight">
                                            <input
                                                type="text"
                                                value={this.state.totalAmtSec.totalAmt}
                                                onChange={(e) => this.setState({ totalAmtSec: { totalAmt: e.target.value } })}
                                                />
                                            </td>
                                          
                                          <tr>
                                            <td width="30%" colSpan="2">
                                              Grand Total
                                            </td>
                                            <td colSpan="4"></td>
                                            <td className="cellTextRight">
                                            <input
                                                type="text"
                                                value={this.state.totalAmtSec.totalAmt}
                                                onChange={(e) => this.setState({ totalAmtSec: { totalAmt: e.target.value } })}
                                                />
                                            </td>
                                          </tr>
                                        
                                        </tbody> */}

                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>




                              <div>


                                <label style={{ margin: "5px" }}><strong>Attach Prescription</strong></label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="uploadedimage"
                                  id="fileInput"
                                  onChange={this.imageUpload}
                                  style={{ display: 'none' }} // Hide the default file input
                                />
                                <label htmlFor="fileInput" className="custom-file-upload signupbtn">
                                  Choose File
                                </label>
                                  <button
                                  type="button"
                                  style={{ width: 92, float: "right", margin: 20 }}
                                  className="signupbtn abtn btdpml hidecontent"
                                  onClick={this.addSaleEntryData.bind(this)}
                                  id="pay"
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  style={{ width: 92, float: "right", margin: 20 }}
                                  className="signupbtn abtn btdpml hidecontent"
                                  onClick={this.generate.bind(this)}
                                  id="pay"
                                  disabled={!this.state.bill_no}
                                >
                                  Generate
                                </button>
                                {/*  <button
                                  type="button"
                                  style={{ width: 150, float: "right", margin: 20 }}
                                  className="signupbtn abtn btdpml hidecontent"

                                  id="pay"
                                >
                                  Attach Prescription
                                </button> */}
                                <button
                                  type="button"
                                  style={{ width: 92, float: "right", margin: 20 }}
                                  className="signupbtn abtn btdpml hidecontent"
                                  onClick={this.cancel}
                                  id="pay"
                                >
                                  clear
                                </button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </section>)}
              <FooterSection />

            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaleEntryVoucher));

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
