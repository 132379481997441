import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
// import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
// import BusinessUserSideBar from "../components/container/SideBar";
// import ClientSideBar from "../components/container/layout/client/SideBar";
// import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import Headerweb from './Headerweb';
import FooterWeb from './Footerweb';
import Topmove from './Topmove';

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class Benifits extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
            <div className="">

                <div className="">
                    {/* <section className="my-head"> */}
                        {/* <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div> */}
                    {/* </section> */}
                    <div className="">
                        {/* <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div> */}

                        {/* <section className="main-body hospital-profilegap "> */}
                            {/* <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            /> */}

                            {/* <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} /> */}

                            {/* <div className="container-fluid checkout-tabs"> */}
                                {/* <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                /> */}

                                {/* <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} /> */}

<div className='product-section'>
    <Headerweb/>
  
    
    <div className='sub-banner text-center'>
                <h1>Benefits</h1>
            </div>
            <hr className='m-0' style={{ color: '#D9F4FF' }} />
            <section className='pt-5'>
            <div className='body1'>
        <div class="container95">
        <div class="item3">
            <img src={require("../../src/assets/images/website/Benifits8.png")} alt="Image 1"/>
            <div class="text-container">
                <h6>Increase Revenue</h6>
                <p>Optimize operational expenses & increase revenue.</p>
            </div>
        </div>
        <div class="item3">
            <img src={require("../../src/assets/images/website/Benifits9.png")} alt="Image 2"/>
            <div class="text-container">
                <h6>Increase Productivity</h6>
                <p>Improve operational efficiency & productivity.</p>
            </div>
        </div>
        <div class="item3">
            <img src={require("../../src/assets/images/website/Benifits10.png")} alt="Image 3"/>
            <div class="text-container">
                <h6>Improve Service Quality</h6>
                <p>Building a trust worthiness to the patient</p>
            </div>
        </div>
        <div class="item3">
            <img src={require("../../src/assets/images/website/Benifits11.png")} alt="Image 4"/>
            <div class="text-container">
                <h6>Improve Patient Satisfaction</h6>
                <p>Improve the quality of service to boost patient satisfaction</p>
            </div>
        </div>
    </div>
    </div>
                <div className='container-90' id='increase_revenue'>
                    <div className='row m-0 align-items-center'>
                        <div className='col-lg-6 col-12  slide-left visible'>
                            <img src={require("../../src/assets/images/website/Benifits1.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            
                        </div>
                        <div className='col-lg-6 col-12'>
                            <h5>Increase Revenue</h5>
                            <h1>Optimize operational <br /> expenses & increase revenue</h1>
                            {/* <div className='img-responsive'>
                            <img src={require("../../src/assets/images/website/Benifits1.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div> */}
                            <p>Data is the fuel for any process improvement; using the data collected at the Hospital/Clinic, our Clinical Management System could help identify the non-value-added activities and eliminate the waste & idle time causing bottlenecks.</p>
                            <p>Maintaining the data related to hospital operations (Patient records, Health information records, Medicine records, Pharmacy and Laboratory details, Supplier details, etc.) takes a lot of work. It requires more staffing to maintain the paperwork and store it.</p>
                            <p>As per the study, every human average spends 6.5 months of their lives, equivalent to 5,000 hours in searching. One of the most time-consuming jobs and most significant challenges in daily life at hospitals /Clinics is searching for the records when required.</p>
                            <p>Our Clinical Management System is designed with Amazon Web Services cloud-based architecture so that all the records will be maintained digitally online, and it can be retrieved and downloaded easily in Excel sheets in a few seconds.</p>
                        </div>
                    </div>
                </div>
                <div className='bg-section'>
                    <div className='container-90' id='increase_productivity'>
                        <div className='row m-0 align-items-center'>
                            <div className='col-lg-6 col-12 slide-left visible'>
                                <h5>Increase Productivity</h5>
                                <h1>Improve operational <br /> efficiency & Productivity</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/Benifits2.png")} className='w70 h70 ml-5 d-lg-none d-block' />
                                </div>
                                <p>The traditional token system requires more staff involvement in managing the visitors in the waiting room. Every time, Staff must announce the current token number in the waiting room to call the visitors. Also, performing paperwork during the check-in & check-out process is time-consuming and fatiguing for the Staff, which leads to dissatisfaction and poor performance.</p>
                                <p>KlinicQ CMS application shift planner function supports allocating the Staff effectively & all the records are maintained digitally in the CMS application online so that they no longer have to manage the paperwork.</p>
                                <p>The token algorithm takes care of the check-in &amp; check-out functions and the user queue list displayed on the monitor or TV with who is next & the waiting time for their turn so that there is no need to announce every time.</p>
                                <p>Managing social distancing and limiting the crowd by reducing the wait in the waiting room could reduce frustration and help improve staff satisfaction. The satisfied Staff help produce happy customers and deliver excellent service.</p>

                            </div>
                            <div className='col-lg-6 col-12 slide-right visible'>
                                <img src={require("../../src/assets/images/website/Benifits2.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-90 mt-5' id='increase_servicequality'>
                    <div className='row m-0 align-items-center'>
                        <div className='col-lg-6 col-12 slide-left visible'>
                            <img src={require("../../src/assets/images/website/Benifits3.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                        </div>
                        <div className='col-lg-6 col-12 slide-right visible'>
                            <h5>Improve Service Quality</h5>
                            <h1>Building a trust worthiness <br /> to the Patients</h1>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/Benifits3.png")} className='w70 h70 ml-5 d-lg-none d-block' />
                            </div>
                            <p>In most cases, Patients would like to consult with their preferred hospital & doctors, and the reason could be friendliness, easy approach, easy access to medical records anywhere and anytime, security &amp; privacy of health information records, and less wait in the waiting room.</p>
                            <p>For pregnant women, kids, and physically challenged &amp; aged patients, waiting in the waiting room for hours is a nightmare. More crowds in the waiting room cause mental trauma &amp; fear of getting affected by airborne disease.</p>
                            <p>To accommodate more patients in the waiting room simultaneously, hospitals and clinics require more resources like space, infrastructure, and more front office staff.</p>
                            <p>When the Hospitals/Clinics don’t implement an Intelligent Queue technique in the future, it could cause a loss of customers altogether.</p>
                            <p>Minimizing the wait in the waiting room might look small. Still, it is a very sensitive and meaningful improvement to the service at the Hospital/Clinic, which could build trust in the convenience of the hospital.</p>

                        </div>
                    </div>
                </div>
                <div className='bg-section'>
                    <div className='container-90' id='improve_patientsatisfaction'>
                        <div className='row m-0 align-items-center'>
                            <div className='col-lg-6 col-12 slide-left visible'>
                                <h5>Improve patient satisfaction</h5>
                                <h1>Improve the quality of <br /> service to boost Patient <br /> Satisfaction</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/Benifits4.png")} className='w70 h70 ml-5 d-lg-none d-block' />
                                </div>
                                <p>The patients expect a better experience and quality of service from their healthcare providers and hospitals/Clinics are striving to deliver eminence service at their facility. Failure to meet patients needs can lead to negative feedback and loss of business opportunities.</p>
                                <p>Our KlinicQ Clinical Management System token & appointment scheduling function saves visitors and physicians time effectively customers by allowing them to check their token status at their own convenience on their mobile devices so that the visitors use their time efficiently rather than wait in the waiting room.</p>
                                <p>Our Clinical Management System is designed based on understanding the customer’s needs, and best practices followed across most hospitals/Clinics, which helps your business create an opportunity to build a long-lasting relationship with the customers.</p>
                            </div>
                            <div className='col-lg-6 col-12 slide-right visible'>
                                <img src={require("../../src/assets/images/website/Benifits4.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='container-90' id='no_waitingroom'>
                    <div className='row m-0 align-items-center'>
                        <div className='col-lg-6 col-12 slide-left visible'>
                            <img src={require("../../src/assets/images/website/Benifits5.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                        </div>
                        <div className='col-lg-6 col-12 slide-right visible'>
                            <h1>No wait at waiting room </h1>
                            <h5>Improve the quality of service to boost Patient Satisfaction</h5>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/Benifits5.png")} className='w70 h70 ml-5 d-lg-none d-block' />
                            </div>
                            <p>As per the study, Human beings spend approximately six months of their lives waiting in queues, and waiting is one of the non-value-added activities that is more uncomfortable for any customer; no one would say I like to wait in the queue.</p>
                            <p>With the traditional token system, hospitals could not provide accurate appointment times for each token. Visitors are frustrated that even though they have tokens, why should they physically wait in the waiting room for hours.</p>
                            <p>KlinicQ’ s advanced token management system algorithm schedules a virtual queue and allocates the token number to the visitors so that visitors don’t have to physically wait in the waiting room.</p>
                            <p>Social distancing is one of the best ways to minimize the spread of airborne disease, especially in the hospital/clinic waiting room; having more patients could potentially create an opportunity to spread the airborne disease.</p>
                        </div>
                    </div>
                </div> */}
                 <div className='bg-section'>
                <div className='container-90' id='increase_revenue'>
                    <div className='row m-0 align-items-center'>
                        <div className='col-lg-6 col-12  slide-left visible'>
                            <img src={require("../../src/assets/images/website/Benifits5.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            
                        </div>
                        <div className='col-lg-6 col-12'>
                            <h5>No wait at waiting room</h5>
                            <h1>Improve Data Security</h1>
                            {/* <div className='img-responsive'>
                            <img src={require("../../src/assets/images/website/Benifits1.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div> */}
                            <p>Data is information obtained from various sources like demographic information, the number of consultations per day/ week/ month, visiting hours pattern, activity, and behavior.</p>
                            <p>The famous quote by Peter Drucker, You can’t improve what you don’t measure. </p>
                            <p>For any business, without performing analytics, the collected data is basically useless, and the business can’t be able to improve the process efficiency, productivity, performance & revenue.</p>
                            <p>Analytical insights are incredibly effective and can be used to grow your business. Our Clinical Management System has been designed with various KPI reports and trends based on average waiting times, consulting time, clinical records, pharmacy &amp; laboratory records, etc., to do analysis and improve it.</p>
                        </div>
                    </div>
                </div>
                </div>
                {/* <div className='bg-section'>
                    <div className='container-90' id='improve_datasecurity'>
                        <div className='row m-0 align-items-center'>
                            <div className='col-lg-6 col-12 slide-left visible'>
                             <h5>No wait at waiting room</h5>
                                <h1>Improve Data Security</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/Benifits5.png")} className='w-100 h70 ml-5 d-lg-none d-block' />
                                </div>
                                <p>Our Clinical Management System is designed based on HIPAA guidelines. Personal identification information (PII) and Personal Health Information (PHI) are encrypted and stored in the database for data security, and they will not be shared with any third party. </p>
                                <p>Our Clinical Management System is designed based on HIPAA guidelines. Personal identification information (PII) and Personal Health Information (PHI) are encrypted and stored in the database for data security, and they will not be shared with any third party. </p>
                                <p>Our Clinical Management System has stringent access controls, and every registration record is verified by us and monitored to ensure the reliability of the data. Our technical team continuously follows intense technical protocols and vulnerability measures to provide secure service and protect customer data.</p>

                            </div>
                            <div className='col-lg-6 col-12 slide-right visible'>
                                <img src={require("../../src/assets/images/website/Benifits5.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-90' id='improve_dataanalytics'>
                    <div className='row m-0 align-items-center'>
                        <div className='col-lg-6 col-12 slide-left visible'>
                            <img src={require("../../src/assets/images/website/Benifits6.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                        </div>
                        <div className='col-lg-6 col-12 slide-right visible'>
                            <h5>No wait at waiting room </h5>
                            <h1>Data Analytics & Insight</h1>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/Benifits6.png")} className='w70 h70 ml-5 d-lg-none d-block' />
                            </div>
                            <p>Data is information obtained from various sources like demographic information, the number of consultations per day/ week/ month, visiting hours pattern, activity, and behavior.</p>
                            <p>The famous quote by Peter Drucker, You can’t improve what you don’t measure. </p>
                            <p>For any business, without performing analytics, the collected data is basically useless, and the business can’t be able to improve the process efficiency, productivity, performance & revenue.</p>
                            <p>Analytical insights are incredibly effective and can be used to grow your business. Our Clinical Management System has been designed with various KPI reports and trends based on average waiting times, consulting time, clinical records, pharmacy &amp; laboratory records, etc., to do analysis and improve it.</p>

                        </div>
                    </div>
                </div> */}
                <div className='bg-section'>
                    <div className='container-90' id='improve_sustainablility'>
                        <div className='row m-0 align-items-center'>
                            <div className='col-lg-6 col-12 slide-left visible'>
                            <h5>No wait at waiting room </h5>
                                <h1>Data Analytics & Insight</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/Benifits6.png")} className='w70 h70 ml-5 d-lg-none d-block' />
                                </div>
                                <p>Data is information obtained from various sources like demographic information, the number of consultations per day/ week/ month, visiting hours pattern, activity, and behavior.</p>
                            <p>The famous quote by Peter Drucker, You can’t improve what you don’t measure. </p>
                            <p>For any business, without performing analytics, the collected data is basically useless, and the business can’t be able to improve the process efficiency, productivity, performance & revenue.</p>
                            <p>Analytical insights are incredibly effective and can be used to grow your business. Our Clinical Management System has been designed with various KPI reports and trends based on average waiting times, consulting time, clinical records, pharmacy &amp; laboratory records, etc., to do analysis and improve it.</p>
                            </div>
                            <div className='col-lg-6 col-12 slide-right visible'>
                                <img src={require("../../src/assets/images/website/Benifits6.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-section mg-btm10'>
                <div className='container-90' id='increase_revenue'>
                    <div className='row m-0 align-items-center'>
                        <div className='col-lg-6 col-12  slide-left visible'>
                            <img src={require("../../src/assets/images/website/Benifits7.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            
                        </div>
                        <div className='col-lg-6 col-12'>
                            <h5>No wait at waiting room</h5>
                            <h1>Improve Sustainability</h1>
                            {/* <div className='img-responsive'>
                            <img src={require("../../src/assets/images/website/Benifits1.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div> */}
                           <p>KlinicQ application enhances the overall healthcare experience in a healthcare facility & it is easy to use and eliminates errors caused by handwriting and advanced e-prescribing clinical documentation capabilities.</p>
                                <p>KlinicQ application records are stored in cloud servers, and it provides all data in a single platform to reduce the expenses of an organization because of less paperwork and improved safety.</p>
                                <p>Patient care with a reduction in errors &amp; elimination of duplication. Our solution is customizable and scalable electronic medical records that can grow with your medical practice.</p>
                                <p>KlinicQ application can quickly transfer patient data from one system. It helps in the space-saving of a digital records environment, and it helps to reduce operational costs such as transcription services.</p>
                        </div>
                        {/* <div className='col-lg-6 col-12 slide-right visible'>
                                <img src={require("../../src/assets/images/website/Benifits7.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div> */}
                    </div>
                </div>
                </div>
                {/* <div className='bg-section'>
                    <div className='container-90' id='improve_sustainablility'>
                        <div className='row m-0 align-items-center'>
                            <div className='col-lg-6 col-12 slide-left visible'>
                            <h5>No wait at waiting room </h5>
                                <h1>Improve Sustainability</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/Benifits7.png")} className='w70 h70 ml-5 d-lg-none d-block' />
                                </div>
                                <p>KlinicQ application enhances the overall healthcare experience in a healthcare facility & it is easy to use and eliminates errors caused by handwriting and advanced e-prescribing clinical documentation capabilities.</p>
                                <p>KlinicQ application records are stored in cloud servers, and it provides all data in a single platform to reduce the expenses of an organization because of less paperwork and improved safety.</p>
                                <p>Patient care with a reduction in errors &amp; elimination of duplication. Our solution is customizable and scalable electronic medical records that can grow with your medical practice.</p>
                                <p>KlinicQ application can quickly transfer patient data from one system. It helps in the space-saving of a digital records environment, and it helps to reduce operational costs such as transcription services.</p>
                            </div>
                            <div className='col-lg-6 col-12 slide-right visible'>
                                <img src={require("../../src/assets/images/website/Benifits7.png")} className='w70 h70 ml-5 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
                        <Topmove />
                        <FooterWeb className="qrCodeFooter" />
                    </div>
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount = () => {
       /*  console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Benifits);