import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
// import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
// import BusinessUserSideBar from "../components/container/SideBar";
// import ClientSideBar from "../components/container/layout/client/SideBar";
// import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';

import Headerweb from './Headerweb';
import FooterWeb from './Footerweb';
import Topmove from './Topmove';


function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class HospitalManagment extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
            <div className="">

                <div className="">
                    {/* <section className="my-head"> */}
                        {/* <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div> */}
                    {/* </section> */}
                    <div className="">
                        {/* <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div> */}

                        {/* <section className="main-body hospital-profilegap "> */}
                            {/* <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            /> */}

                            {/* <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} /> */}

                            {/* <div className="container-fluid checkout-tabs"> */}
                                {/* <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                /> */}

                                {/* <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} /> */}

<div className='product-section'>
    <Headerweb/>
  
    
 
            <hr className='m-0' style={{ color: '#D9F4FF' }} />
            <section className='pt-5'>
           
            <div className='container-90' id='increase_revenue' style={{ marginTop: '50px' }}>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-6 col-12 slide-left visible'>
            <h1>Hospital & Clinical <br/> Management System</h1>
        </div>
        <div className='col-lg-6 col-12'>
            <p>A Clinical Management System (CMS) is a software solution designed to streamline and enhance the administrative and clinical functions within healthcare facilities. It plays a crucial role in managing patient information, improving efficiency, ensuring regulatory compliance, and enhancing overall patient care. Here are some key components and features typically found in a Clinical Management System</p>
        </div>
    </div>
</div>
                <div className=''>
                <div className='' id='increase_productivity' style={{ marginTop: '50px' }}>
    <div className='row m-0'>
        <div className='col-12'>
            <img src={require("../../src/assets/images/website/Hospitalmang1.png")} className='w-100 h-auto' alt="Increase Productivity" />
        </div>
    </div>
</div>
</div>
<div className='container-80 mt-5' id='increase_servicequality'>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-12 col-12 slide-right visible'>
           
            <p>The hospital portal is designed to manage multi-specialty and different consultation modes (Token Management System, Appointment Management System & Tele-consultation). Each hospital is registered with a unique location ID, which shows the hospital/clinic in the GoogleMaps view with the wait time based on the specialization. Also, the dashboard displays the travel distance between the visitor location and the hospital travel time to the hospital to arrive at the clinic on time to avoid no-shows.</p>
            <p>KlinicQ' s advanced token management system algorithm schedules a virtual queue and allocates the token number to the visitors so that visitors don't have to wait in the waiting room physically. 
            </p>
            <p>The KlinicQ token management system keeps track of each visitor's token position in a queue and updates the hospital and visitors on the progress. Clinics can operate with minimal resources and less space due to sequenced line, and, patients can use their time effectively instead of wait at the waiting room, and there is no need to have a larger area and the infrastructure at the Clinic waiting room.</p>
          
        </div>
    </div>
</div>
                <div className=''>
                <div className='container-90' id='improve_patientsatisfaction'>
    <div className='row'>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Hospitalmang2.png")} className='w-100 h-65' alt="Benefit 1" />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Hospitalmang3.png")} className='w-100 h-65' alt="Benefit 2" />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Hospitalmang4.png")} className='w-100 h-65' alt="Benefit 3" />
        </div>
    </div>
</div>
                </div>
               
                <div className='container-80' id='increase_servicequality'>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-12 col-12 slide-right visible'>
           
            <p>KlinicQ Clinical Management System fills the appointment schedule automatically without any manual intervention, and it handles appointment cancellation, allocates grace time for patients who arrive to their appointment late, and calculates the schedule based on the doctor's arrival time at the hospital so that minimal staff should be enough to manage the operations. </p>
            <p>As the KlinicQ Token management algorithm allocates the appointment time for each token, visitors could arrive at the hospital before their appointment time, which could limit the number of visitors in the waiting room.</p>
            <p>The KlinicQ' s algorithm has been developed to perform line balancing to schedule the workload based on the doctor's availability maintained in the shift planner, which could help optimize the schedule effectively. It simplifies the check-in process at the front desk. It digitally keeps the visitor's records, patient's discharge summary, medicine details, and clinic staff details so that clinic front office staff can focus on their work as patients join the virtual queue. It improves productivity and Operational efficiency.</p>
            <p>Patient's medical records like prescriptions, discharge summaries, and vital details of their previous consultation can also be scanned or uploaded the images of all their previous medical records digitally to access anytime when required & it helps the doctor to go through all reports to provide an effective treatment.</p>
            <p>The KlinicQ application has additional features like General health tips, BMI, and vital details, and also, the payment gateway is enabled for online payments, QR code easier, and touchless check-in process. KlinicQ mobile application ( IoS and Android apps) has five different language options - English, Tamil, Hindi, Telugu, and Kannada.</p>
        </div>
    </div>
</div>
              
            </section>
                        <Topmove />
                        <FooterWeb className="qrCodeFooter" />
                    </div>
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount = () => {
       /*  console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(HospitalManagment);