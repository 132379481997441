import React, { useState } from 'react';


const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    {
      src: require("../../src/assets/images/website/doctor2.png"),
      title: "Benefits of Patient",
      description: "The patient portal is designed to manage primary & family members under the same account with a unique ID for each member."
    },
    {
      src: require("../../src/assets/images/website/doctor5.png"),
      title: "Benefits of Physician",
      description: "A doctor portal is designed based on the physician's specialty and can work for any specialization."
    },
    {
      src: require("../../src/assets/images/website/doctor4.png"),
      title: "Benefits of Hospital",
      description: "A hospital portal is designed to manage multi-specialty and different consultation modes."
    },
    {
      src: require("../../src/assets/images/website/doctor6.png"),
      title: "Benefits of Pharmacy",
      description: "Pharmacy portals simplify medication management, allowing easy prescription refills and secure communication with pharmacists."
    },   
    {
      src: require("../../src/assets/images/website/doctor3.png"),
      title: "Benefits of Health",
      description: "Health portals provide a comprehensive view of patient health records."
    },
    {
      src: require("../../src/assets/images/website/doctor6.png"),
      title: "Benefits of Wellness",
      description: "Wellness portals help in tracking health and wellness activities."
    },
    
  ];

  const imagesPerPage = 3; 

  const nextImages = () => {
    setCurrentIndex((prevIndex) => (prevIndex + imagesPerPage) % images.length);
  };

  const prevImages = () => {
    setCurrentIndex((prevIndex) => (prevIndex - imagesPerPage + images.length) % images.length);
  };

  return (
    <div className='hospital-details py-5'>
    <div className='container col-lg-11'>
      <div className='row m-0 align-items-stretch justify-content-center'>
        <div className='col-12'>
          <div className='scroll-container'>
            <div className='row m-0 align-items-stretch justify-content-start'>
              {images.slice(currentIndex, currentIndex + imagesPerPage).map((image, index) => (
                <div key={index} className='col-lg-4 col-md-6 col-sm-6 col-12'>
                  <div className='patient'>
                    <div className='img-section'>
                      <img src={image.src} className='w-100' alt={image.title} />
                    </div>
                    <div className='doctor-content'>
                      <h1>{image.title}</h1>
                      <div className='inner-contentss'>
                        <div className=''>
                          <p>{image.description}</p>
                        </div>
                        <div className=''>
                          <div className='arrow-section'>
                            <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' alt='Arrow' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='row m-0 justify-content-center mt-4'>
              <div className='col-auto'>
                <div className='arrow-button' onClick={prevImages} style={{ backgroundColor: '#D3D3D3', padding: '10px', borderRadius: '5px' }}>
                  <img src={require("../../src/assets/images/website/Frame1.png")} className='icons' alt='Left Arrow' />
                </div>
              </div>
              <div className='col-auto'>
                <div className='arrow-button' onClick={nextImages} style={{ backgroundColor: '#D3D3D3', padding: '10px', borderRadius: '5px' }}>
                  <img src={require("../../src/assets/images/website/Frame.png")} className='icons' alt='Right Arrow' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ImageCarousel;