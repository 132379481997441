import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
// import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
// import BusinessUserSideBar from "../components/container/SideBar";
// import ClientSideBar from "../components/container/layout/client/SideBar";
// import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';

import Headerweb from './Headerweb';
import FooterWeb from './Footerweb';
import Topmove from './Topmove';


function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class PatientManagment extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
            <div className="">

                <div className="">
                    {/* <section className="my-head"> */}
                        {/* <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div> */}
                    {/* </section> */}
                    <div className="">
                        {/* <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div> */}

                        {/* <section className="main-body hospital-profilegap "> */}
                            {/* <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            /> */}

                            {/* <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} /> */}

                            {/* <div className="container-fluid checkout-tabs"> */}
                                {/* <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                /> */}

                                {/* <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} /> */}

<div className='product-section'>
    <Headerweb/>
  
    
 
            <hr className='m-0' style={{ color: '#D9F4FF' }} />
            <section className='pt-5'>
           
            <div className='container-90' id='increase_revenue' style={{ marginTop: '50px' }}>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-6 col-12 slide-left visible'>
            <h1>Patient Management <br/> System</h1>
        </div>
        <div className='col-lg-6 col-12'>
            <p>A Clincal Management System (CMS) is a software solution designed to streamline and enhance the administrative and clinical fuction within healthcare facilitites. It plays a crucial role in managing patient information,healthcare efficiency, ensuring regulatory compliance, and enchancing overall patient care.Here are some key components and features typically found in a  Clincal Management System.</p>
        </div>
    </div>
</div>
                <div className=''>
                <div className='' id='increase_productivity' style={{ marginTop: '50px' }}>
    <div className='row m-0'>
        <div className='col-12'>
            <img src={require("../../src/assets/images/website/Patientmang1.png")} className='w-100 h-auto' alt="Increase Productivity" />
        </div>
    </div>
</div>
</div>
<div className='container-80 mt-5' id='increase_servicequality'>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-12 col-12 slide-right visible'>
           
            <p>The patient portal is designed to manage primary and family members under the same account with unique ID for each member.Patient can access their health history,medical records, and dischrage summary and communicate with doctor and hospital anytime & anywhere. Patients Personal Identification Information (PII) and Patient Health Information (PHI) is encrypted as per  HIPAA guideline for data privacy & security</p>
            <p>KlinicQ application Patient module allows booking, rescheduling, or canceling the appointment through mobile apps to reduce no-shows & also if a patient is running late, then the system automatically provides grace time and adjusts the schedule automatically to avoid waiting in the waiting room for long time again.
            </p>
            <p>Patients can search the hospital/Clinic in our network based on various search options like Specialty of the doctor, Hospital, Gender, City, and Distance between your location and hospital. Our KlinicQ application is integrated with Google Maps so that you can view the live waiting time data in all the hospitals near your surroundings and update you with the distance to the healthcare provider's location.</p>
            <p>For pregnant women, kids, physically challenged & aged patients, waiting in the waiting room for hours is a nightmare; more crowds in the waiting room cause mental trauma & fear of getting affected by airborne disease. KlinicQ application's Token management system algorithm allocates the appointment time for each token. Visitors could arrive at the hospital just before their appointment time and use their precious time outside the waiting room instead of waiting at the waiting room, which could increase the opportunity for social distancing, and it is one of the best ways to minimize the spread of airborne disease.
            </p>
        </div>
    </div>
</div>
                <div className=''>
                <div className='container-90' id='improve_patientsatisfaction'>
    <div className='row m-0'>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Patientmang2.png")} className='w-100 h-65' alt="Benefit 1" />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Patientmang3.png")} className='w-100 h-65' alt="Benefit 2" />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Patientmang4.png")} className='w-100 h-65' alt="Benefit 3" />
        </div>
    </div>
</div>
                </div>
               
                <div className='container-80' id='increase_servicequality'>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-12 col-12 slide-right visible'>
           
            <p>The KlinicQ application is user-friendly, easy to use, easy to check doctor availability with accurate wait time, and secured & confidential. KlinicQ application sends appointment confirmation texts and notifications about the follow-up appointment to the patients, reducing no-shows. </p>
            <p>The KlinicQ application has the option of online consultation through video calling, so the patients not able to go to the Hospital/Clinic can book an appointment with the available doctor for online consultation. Patients can set their favorite healthcare providers for easier appointment booking.</p>
            <p>Patient's medical records like prescriptions, discharge summaries, and vital details of their previous consultation can also be scanned or uploaded the images of all their previous medical records digitally to access anytime when required & it helps the doctor to go through all reports to provide an effective treatment.</p>
            <p>The KlinicQ application has additional features like General health tips, BMI, and vital details, and also, the payment gateway is enabled for online payments, QR code easier, and touchless check-in process. KlinicQ mobile application ( IoS and Android apps) has five different language options - English, Tamil, Hindi, Telugu, and Kannada.</p>
        </div>
    </div>
</div>
              
            </section>
                        <Topmove />
                        <FooterWeb className="qrCodeFooter" />
                    </div>
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount = () => {
       /*  console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(PatientManagment);