import React, { Component } from 'react';

class Whatsapp extends Component {
    constructor(props) {
        super(props);
        this.whatsappNumber = '1234567890'; // Replace with the desired number
    }

    handleRedirect = () => {
        const whatsappURL = `https://wa.me/${this.whatsappNumber}`;
        window.open(whatsappURL, '_blank'); // Open in a new tab
    }

    render() {
        return (
            <div style={styles.container}>
                <button style={styles.whatsappButton} onClick={this.handleRedirect} >
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" style={styles.logo} />
                </button>
            </div>
        );
    }
}

const styles = {
    container: {
        position: 'fixed',
        left: '30px',
        bottom: '0px',
        transform: 'translateY(-50%)',
        zIndex: 1000,
    },
    whatsappButton: {
        padding: '15px',
        borderRadius: '50%',
        backgroundColor: '#25D366',
        border: 'none',
        cursor: 'pointer',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    logo: {
        width: '40px', // Adjust size as needed
        height: '40px', // Adjust size as needed
        color: 'white',
    },
};

export default Whatsapp;
