import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// image path 
import issue1 from '../assets/images/website/Hospitalslide1.png';
import issue2 from '../assets/images/website/Hospitalslide2.png';
import issue3 from '../assets/images/website/Hospitalslide3.png';

class WebdoctorSlider extends Component {
    constructor(props) {
        super(props);
        this.logos = [
            { 'image': issue1, 'name': 'Patient discharge summary' },
            { 'image': issue2, 'name': 'Medicine list' },
            { 'image': issue3, 'name': 'Patient List' },
            { 'image': issue1, 'name': 'Patient Visit trend' },
            { 'image': issue1, 'name': 'Pharmacy List' },
            { 'image': issue1, 'name': 'Facility List' },
            { 'image': issue1, 'name': 'Patient discharge summary' },
        ];

        this.owlOptions = {
            items: 6,
            autoplay: true,
            autoplayTimeout: 1000,
            loop: true,
            margin: 10,
            responsive: {
                0: {
                    items: 1,
                },
                300: {
                    items: 1,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 6,
                },
            },
        };
    }

    render() {
        return (
            <div style={{ backgroundColor: '#0C5B98', padding: '80px', color: 'white' }}>
                <h1 className='mb-5' style={{ color: 'white' }}>
                    The KlinicQ Clinical Management System <br /> developed with reporting capabilities to allow <br /> the download of the report to Microsoft Excel
                </h1>
                <OwlCarousel className="owl-theme" {...this.owlOptions}>
                    {this.logos.map((logo, index) => (
                        <div key={index} className='py-4 logo-slide text-center'>
                            <div className='logo-image'>
                                <img src={logo.image} alt={logo.name} className='w-25 h-100' />
                            </div>
                            <h2 className='mt-3 logo-name' style={{fontSize: '14px'}}>{logo.name}</h2> {/* Use a CSS class for styling */}
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        );
    }
}

export default WebdoctorSlider;