import React from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

class BatchSelectionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBatches: [], // State to track the selected batches
      selectedExpdates:[],
    };
  }

/*   handleCheckboxChange = (event, exp_date) => {
    const { id, checked } = event.target;
    this.setState((prevState) => {
      console.log(prevState);
        if (checked) {
            // Add the batch_no to selectedBatches and exp_date to selectedExpdates if checked
            return {
                selectedBatches: [...prevState.selectedBatches, id],
                selectedExpdates: [...prevState.selectedExpdates, exp_date] // Correctly update selectedExpdates
            };
        } else {
            // Remove the batch_no from selectedBatches and corresponding exp_date from selectedExpdates if unchecked
            return {
                selectedBatches: prevState.selectedBatches.filter(batch_no => batch_no !== id),
                selectedExpdates: prevState.selectedExpdates.filter(date => date !== exp_date) // Remove corresponding exp_date
            };
        }
    }, () => {
        console.log(this.state.selectedBatches);
        console.log(this.state.selectedExpdates); // Log selectedExpdates for debugging
       
        // Call the selectBatch function passed as a prop with the updated selectedBatches
        this.props.selectBatch(this.state.selectedBatches,this.state.selectedExpdates);
        
    });
   
}; */

handleCheckboxChange = (event, exp_date) => {
  const { id, checked } = event.target;
  this.setState((prevState) => {
    let newSelectedBatches = prevState.selectedBatches;
    let newSelectedExpdates = prevState.selectedExpdates;
    if (checked) {
      newSelectedBatches = [...prevState.selectedBatches, id];
      newSelectedExpdates = [...prevState.selectedExpdates, exp_date];
    } else {
      newSelectedBatches = prevState.selectedBatches.filter(batch_no => batch_no !== id);
      newSelectedExpdates = prevState.selectedExpdates.filter(date => date !== exp_date);
    }
    console.log("Selected Batches:", newSelectedBatches);
    console.log("Selected Expdates:", newSelectedExpdates);

    return {
      selectedBatches: newSelectedBatches,
      selectedExpdates: newSelectedExpdates
    };
  }, () => {
    console.log(this.state.selectedBatches);
    console.log(this.state.selectedExpdates);

    // Call the selectBatch function passed as a prop with the updated selectedBatches
    this.props.selectBatch(this.state.selectedBatches, this.state.selectedExpdates);
  });
};



  render() {
    const { show, handleBatchClose, batches,handleSelectBatchClick } = this.props;
    const { selectedBatches } = this.state;

    return (
      <Modal show={show} onHide={handleBatchClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Batches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Batch Number - Expiry Date  </p>
          <ListGroup>
            {batches.map(batch => (
              <ListGroup.Item key={batch.batch_no}>
                <Form.Check 
                  type="checkbox" 
                  id={batch.batch_no} // Use batch_no as the id
                  label={`${batch.batch_no}     -    ${new Date(batch.exp_date).toLocaleDateString()}`} // Display batch_no and expiry date
                  checked={selectedBatches.includes(batch.batch_no)} // Check if this batch_no is selected
                  onChange={(event) => this.handleCheckboxChange(event, batch.exp_date)} 
                />
             
              </ListGroup.Item>
              
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
     {/*    <Button onClick={handleSelectBatchClick}>Add Batch</Button> */}
          <Button variant="secondary" onClick={handleBatchClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

// PropTypes for validation
BatchSelectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleBatchClose: PropTypes.func.isRequired,
  batches: PropTypes.arrayOf(
    PropTypes.shape({
      batch_no: PropTypes.string.isRequired,
      exp_date: PropTypes.string.isRequired, // Assuming exp_date is a string in ISO format
    })
  ).isRequired,
  selectBatch: PropTypes.func.isRequired,
};

export default BatchSelectionModal;