import React, { Component } from 'react';
import Headerweb from './Headerweb';
import FooterWeb from './Footerweb';
// import './App.css'; // Make sure to create this CSS file for styling

class Solution extends Component {
    render() {
        return (

            <div className='Product-section'>
                 <Headerweb />
                <div className='sub-banner text-center'>
                <h1>Solution</h1>
                
            </div>
               
            <div className="container-80 grid-container">
                <div className="grid-item">
                    <h3>Token System</h3>
                    <p>Token System works with Online appointment booking and walk-in appointments and allows patients to book an appointment with a token number for a day and time. </p>
                    <p>Token System automatically gives patients priority in the queue for a given time slot, no matter how long the queue is.</p>
                    <p>Token System accepts walk-in appointments as soon as scheduled openings occur and can also allocate emergency appointments. </p>
                    <p>Token System algorithm updates the wait time for each clinic in our network and displays it in google maps for easy navigation.</p>
                    <p>Token System updates the token status, token time, and wait time and sends updates through mobile app and SMS text messages.</p>
                    <p>Reschedule or cancel the appointment in the mobile app to reduce no-shows, accordingly the token time and wait time will be updated.</p>
                </div>
                <div className="grid-item">
                    <h3>Appointment slot systems</h3>
                    <p>The KlinicQ appointment management system is designed to manage the effective planning of appointments for patients & doctors. </p>
                    <p>The KlinicQ appointment management system allows patients to book appointments for a future day and time.</p>
                    <p>The appointment management system works with Online appointments, and it also works with walk-in appointments based on availability. </p>
                    <p>Based on the cycle time defined by each hospital,   Appointment slots get split automatically.</p>
                    <p>Appointment management system updates the status of the upcoming appointment and sends updates through mobile app and SMS text message.</p>
                    <p>Reschedule or cancel the appointment in a mobile app to reduce no-shows; the availability slots will be updated accordingly. </p>
                    <p>For appointment services, online payment or pay-at-clinic options are defined by the individual hospitals, and accordingly, the workflow triggers the payment options</p>
                </div>
                <div className="grid-item">
                    <h3>Teleconsultation:</h3>
                    <p>With the rapid growth of technology, utilizing digital solutions in the healthcare industry will be helpful for healthcare providers and patients. </p>
                    <p>Teleconsultation is one of the options to connect healthcare professionals with patients through video conferences instead of meeting in person. Patients need to go for regular check-ups, but due to some circumstances, they cannot go to the hospital/clinic in person, or any first aid treatment is required during nighttime or odd hours when the Hospital/ clinic is not open.</p>
                    <p>Tele-consultation is the best option to get a doctor’s advice on time to save lives. Tele-consultation is significantly beneficial for patients living in rural areas that do not have hospitals/clinics and for people who do not have transport facilities, older adults & physically challenged people who cannot travel because the KlinicQ application helps connect with doctors quickly from anywhere, and anytime.</p>
                   
                </div>
                <div className="grid-item">
                   
                </div>
            </div>
            <FooterWeb />
            </div>
        );
    }
}

export default Solution;