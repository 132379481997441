import React, { Component,Fragment } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import Heading4 from "../../components/UI/Heading4";
import InputField from "../UI/InputField";
import TextArea from "../UI/TextArea";
import CheckboxOrRadioGroup from "../UI/CheckboxOrRadioGroup";
// import Button from '../UI/Button'
import axios from "axios";
import { updateProfilePharmacy, updateavatar } from "../../config/url.json";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Label from "../UI/Label";
import Validation from "../../actions/Validation";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import SpanField from "../UI/SpanField";
import Select from "react-select";
import { AUTH_CONFIG, CONFIG, imageconfig } from "../../config/data";
import { getPharmacyByUserId } from "../../config/url.json";
import ErrorAlert from "../UI/ErrorAlert";
import S3 from "react-aws-s3";
import DatePicker from "react-date-picker";
import * as moment from "moment";
// const token = localStorage.getItem("token");
function mapStateToProps(state) {
  console.log(state.appdata);

  return {
    appdata: state.AppData,
  };
}
const google = window.google;
const scheduleType = [{ label: "Token", value: "Queue" }, { label: "Appointment", value: "Appointment" }];
const working_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class PharmacySignup1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSchedule: [],
      file: "",
      imagePreviewUrl: "",
      pharmacyData: {
        user_id: "",
        pharmacy_name: "",
        email: "",
        // password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        user_type: "pharmacy",
        phone_number: "",
        phone_code: "",
        fax_number: "",
        website: "",
        year_of_build: "",
        capacity: "",
        schedule_type: "",
        selected_category: "",
        payment_type:"",
        working_hours: {
          morning_opens_at: '',
          morning_close_at: '',
          evening_opens_at: '',
          evening_close_at: '',
          hours_24: false,
        },

        working_days: [],
        working_all_days: false,
       // speciality: [],
        facility: [],
        pharmacist_name:"",
        pharmacist_license:"",
       // pharmacist_license_expirydate:"",
        selected_firm:"",
        pharmacist_owner:"",
        shopact_license:"",
       // shopact_license_expirydate:"",
        cin:"",
        gstreg_type:"",
        pan:"",
        gst_no:"",
        gst_country:"",
        gst_state:"",
        food_licence: "",
        food_expirydate:"",
        drug_licence1: "",
        licence1_expirydate:"",
        drug_licence2: "",
        licence2_expirydate:"",
        drug_licence3: '',
        licence3_expirydate:"",
        profile_summary: "",
        google_loc_id:"",
      },
      errors: {
        pharmacy_name: "",
        email: "",
        // password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        fax_number: "",
        website: "",
        year_of_build: "",
        capacity: "",
        schedule_type: "",
        working_hours_morning: "",
        working_hours_evening: "",
        working_days: "",
       // speciality: "",
        facility: "",
        profile_summary: "",
        phone_number:"",
      },
      error: "",
    };
    // this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.autocomplete = null;
    this.handleWorkhoursChange = this.handleWorkhoursChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { pharmacyData,errors } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    // console.log(place);
    // console.log(place.name);
    var lat = place.geometry.location.lat();
    // get lng
    var lng = place.geometry.location.lng();
    pharmacyData.latitude = lat.toString();
    pharmacyData.longitude = lng.toString();
    pharmacyData.google_loc_id = place.url;
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1")
            pharmacyData.state = val;
        if (addressType === "locality")
            pharmacyData.city = val;
        if (addressType === "country") pharmacyData.country = val;
        // if (addressType === "street_number") {

        pharmacyData.address1 = place.name;
        // }
        if (addressType === "sublocality_level_1") pharmacyData.address2 = val;
        if (addressType === "postal_code") pharmacyData.zip_code = val;
      }
    }
      errors.state="";
      errors.city="";
      errors.zip_code="";
    this.setState({
        pharmacyData,errors
    });
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);

    axios
      .post(
        getPharmacyByUserId,
        { user_id: this.props.userdata.id },
        AUTH_CONFIG
      )
      .then((response) => {
        console.log("userData");
        console.log(response.data);
        if (response.data.status === "1") {
          //   this.setState({});
          //   console.log(response.data.data);
          let data = response.data.data[0];
          console.log(data);
         /*  let speciality = [];
          let specialities_array = this.props.appdata
            ? this.props.appdata[0].specialities
            : [];
          if (data.speciality) {
            let arr1=data.speciality.split(',');
           arr1.forEach((e1,i)=>specialities_array.forEach((element, index) => {
              if (e1==element.id) {
                speciality.push({
                  value: element.id,
                  label: element.name,
                });
              }
            }
           ))
          }
          data.speciality = speciality;

          let facility = [];
          let facilities_array = this.props.appdata
            ? this.props.appdata[0].facilities
            : [];
          if (data.facility) {
            facilities_array.forEach((element, index) => {
              if (data.facility.indexOf(element.id) > -1) {
                facility.push({
                  value: element.id,
                  label: element.facility_name,
                });
              }
            });
          }


          data.facility = facility; */

          let working_hours = {
            morning_opens_at: '',
            morning_close_at: '',
            evening_opens_at: '',
            evening_close_at: '',
            hours_24: false,
          };
          if ("null" === data.working_hours || null == data.working_hours || "" === data.working_hours) {
            data.working_hours = working_hours;
          } else {
            data.working_hours = data.working_hours;
          }

        //  data.schedule_type = data.schedule_type ? data.schedule_type : "";
          // data.working_all_days = data.working_all_days;
          // if (data.working_days !== null) {
          //   data.working_all_days = false;
          // } else if (data.working_days.length === 7) {
          //   data.working_all_days = true;
          // }

          /* Working Days section */
          if (null == data.working_days) {
            data.working_days = [];
          } else {
            data.working_days = data.working_days;
            if (7 === data.working_days.length) {
              data.working_all_days = true;
            } else {
              data.working_all_days = false;
            }
          }

          if (null !== data.category) {
            this.category_array.forEach((element, index) => {
              if (data.category.indexOf(element.value) > -1) {
                data.selected_category = element;
              }
            });
          }
          data.selected_firm = {
            label: data.selected_firm,
            value: data.selected_firm,
          };
          data.gstreg_type = {
            label: data.gstreg_type,
            value: data.gstreg_type,
          };
         
          console.log(data);
          data.phone_number = data.phone_number.includes("+91") ? data.phone_number : data.phone_code.concat(data.phone_number);
           let pharmacist_license=JSON.parse(data.pharmacist_license);
         
           data.pharmacist_license=pharmacist_license.pharmacist_license;
           data.pharmacist_license_expirydate = pharmacist_license.pharmacist_license_expirydate;
  
           let food_licence = JSON.parse(data.food_licence);
           let shopact_license = JSON.parse(data.shopact_license);
           let drug_licence1 = JSON.parse(data.drug_licence1);
           let drug_licence2 = JSON.parse(data.drug_licence2);
           let drug_licence3 = JSON.parse(data.drug_licence3);

 data.food_licence=food_licence.food_licence;
 data.food_expirydate = food_licence.food_expirydate;

 data.shopact_license=shopact_license.shopact_license;
 data.shopact_license_expirydate = shopact_license.shopact_license_expirydate;

 data.drug_licence1=drug_licence1.drug_licence1;
 data.licence1_expirydate = drug_licence1.licence1_expirydate;

 data.drug_licence2=drug_licence2.drug_licence2;
 data.licence2_expirydate = drug_licence2.licence2_expirydate;
 
 data.drug_licence3=drug_licence3.drug_licence3;
 data.licence3_expirydate = drug_licence3.licence3_expirydate;
         
          // var errors = Validation(data, this.state.errors);
          // data.phone_number = data.phone_code.concat(data.phone_number);
          this.setState({ pharmacyData: data });
        } else {
          this.setState({ error: response.data.message });
        }
      })
      .catch((e) => {
        console.log("business user getbyid failed",e);
      });
  }
  handlePaymentSelect = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  }
  handleInput = (e) => {
    let data = this.state.pharmacyData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
let errors = this.state.errors
    if (
      name === "capacity" ||
      name === "year_of_build" ||
      name === "pharmacy_name" ||
      name === "address1" ||
      name === "address2" ||
      name === "city" ||
      name === "zip_code" ||
      name === "fax_number" ||
      name === "email" ||
      name === "website"||
      name === "phone_number"
     
      
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name]=""
    }
    this.setState({ pharmacyData: data,errors });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.pharmacyData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      // const value1 = value.slice(0, max);
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ pharmacyData: data, errors: errors });
  }
  /* for country detail change */
  selectCountry(val) {
    let data = this.state.pharmacyData;
    data.country = val;
    let errors = this.state.errors
    errors.country = ""
    this.setState({ pharmacyData: data,errors });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.pharmacyData;
    data.state = val;
    let errors = this.state.errors
    errors.state=""
    this.setState({ pharmacyData: data,errors });
  }
  handlePhoneInput = (event) => {
    let pharmacyData = this.state.pharmacyData;
    pharmacyData.phone_number = event;
    let errors = this.state.errors
    errors.phone_number = ""
    this.setState({ pharmacyData,errors });
  };
  handleSechduleType = (e) => {
    let data = this.state.pharmacyData;
    data.schedule_type = [e.target.value];
    let errors = this.state.errors
    errors.schedule_type= ""
    this.setState({ pharmacyData: data,errors });
  };
  handleWorkingDays = (e) => {
    let data = this.state.pharmacyData;
    // let working_days_data = data.working_days;
    let working_all_days_data = data.working_all_days;
let errors = this.state.errors
    if ("allDaysId" === e.target.id) {
      working_all_days_data = !working_all_days_data;

      data.working_all_days = working_all_days_data;

      data.working_all_days
        ? (data.working_days = working_days)
        : (data.working_days = []);
    } else {
      const newSelection = e.target.value;
      let newSelectionArray;

      if (data.working_days.indexOf(newSelection) > -1) {
        newSelectionArray = data.working_days.filter((s) => s !== newSelection);
        console.log(newSelectionArray);
      } else {
        newSelectionArray = [...data.working_days, newSelection];
        console.log(newSelectionArray);
      }

      data.working_days = newSelectionArray;
      let wd = [];
      data.working_days.forEach((element, index) => {
        if (working_days.indexOf(element) > -1) {
          wd.push(element);
        }
      });

      data.working_days = wd;
    }
if(data.working_days.length>0)
errors.working_days = ""

    data.working_days.length === 7
      ? (data.working_all_days = true)
      : (data.working_all_days = false);

    this.setState({ pharmacyData: data,errors });
  };
  /* for work detail change */
  handleWorkhoursChange(field, value) {
    let data = this.state.pharmacyData;
    let working_hours_data = data.working_hours;
    let errors = this.state.errors;
    console.log(working_hours_data);
    if(working_hours_data == ""){
      working_hours_data =
      {
        morning_opens_at: '',
        morning_close_at: '',
        evening_opens_at: '',
        evening_close_at: '',
        hours_24: false,
      };
    }
    if ("24Hours" === field) {
      working_hours_data.hours_24 = !working_hours_data.hours_24;
      working_hours_data.morning_opens_at = '00:00'; errors.morning_opens_at="";
      working_hours_data.morning_close_at = '11:59';errors.morning_close_at="";
      working_hours_data.evening_opens_at = '12:00';errors.evening_opens_at="";
      working_hours_data.evening_close_at = '23:59';errors.evening_close_at="";
    } else {
      working_hours_data.hours_24 = false;
      working_hours_data[`${field}`] = value.target.value;
      errors[`${field}`] ="";
    }
    console.log(working_hours_data);
    data.working_hours = working_hours_data;

errors.working_hours = ""
    this.setState({ pharmacyData: data,errors });
  }

  selectgstCountry(val) {
    let data = this.state.pharmacyData;
    data.gst_country = val;
    this.setState({ pharmacyData: data });
  }
  selectgstState(val) {
    let data = this.state.pharmacyData;
    data.gst_state = val;
    this.setState({ pharmacyData: data });
  }
  handleDatePickerChange = (date, field) => {
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    
    this.setState(prevState => ({
      pharmacyData: {
        ...prevState.pharmacyData,
        [field]: offsetDate, // Update the specific expiry date field
      },
    }));
  };
 // Function to format date to dd-MM-YYYY
 formatDateToDDMMYYYY(date) {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2); // Pad single digit days with a leading zero
  const month = (`0${d.getMonth() + 1}`).slice(-2); // Pad single digit months with a leading zero (months are 0-based)
  const year = d.getFullYear();

  return `${day}-${month}-${year}`;
}



  handleselectChange = (selectedOptions, e) => {
    let data = this.state.pharmacyData;
    data[e.name] = selectedOptions;
let errors = this.state.errors
errors[e.name] = ""
    this.setState({ pharmacyData: data,errors });
  };
  handleFormSubmit = (e) => {
    e.preventDefault();

    let { pharmacyData, errors } = this.state;
    console.log(pharmacyData);
    pharmacyData.user_type = "pharmacysignup";

    errors = Validation(pharmacyData, errors);
    let pharmacist_license = {
      pharmacist_license:pharmacyData.pharmacist_license,
      pharmacist_license_expirydate:pharmacyData.pharmacist_license_expirydate
    };
    let shopact_license = {
      shopact_license:pharmacyData.shopact_license,
      shopact_license_expirydate:pharmacyData.shopact_license_expirydate
    };
    let food_licence = {
      food_licence:pharmacyData.food_licence,
      food_expirydate:pharmacyData.food_expirydate
    };
  
    let drug_licence1 = {
      drug_licence1:pharmacyData.drug_licence1,
      licence1_expirydate:pharmacyData.licence1_expirydate
    };
    let drug_licence2 = {
      drug_licence2:pharmacyData.drug_licence2,
      licence2_expirydate:pharmacyData.licence2_expirydate
    };
    let drug_licence3 = {
      drug_licence3:pharmacyData.drug_licence3,
      licence3_expirydate:pharmacyData.licence3_expirydate
    }; 
    this.setState({ errors: errors });

    if (errors.formIsValid) {
      console.log("form is valid");

    //  let speciality = [];
      let data = pharmacyData;

      /* if (data.speciality) {
        data.speciality.forEach((element) => speciality.push(element.value));
      } */

      //data.speciality = speciality.toString();

      data.category = data.selected_category
        ? data.selected_category.value
        : "";

      data.category = data.category.toString();

      /* data.selected_firm = data.selected_firm
      ? data.selected_firm.value
      : "";

    data.selected_firm = data.selected_firm.toString(); */


    //  data.schedule_type = data.schedule_type ? data.schedule_type : "";

   //   data.schedule_type = data.schedule_type.toString();

     /*  let facility = [];

      if (data.facility) {
        data.facility.forEach((element) => facility.push(element.value));
      }
 */
    //  data.facility = facility.toString();
     // data.payment_type=this.state.payment_mode;
    
      try {

        // data.phone_number = data.phone_number.replace(data.phone_code, '');
        data.phone_code = "+" + parsePhoneNumber(data.phone_number).countryCallingCode;
        data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;
        data.pharmacist_name=data.pharmacist_name;
        data.pharmacist_license =pharmacist_license;
        data.pharmacist_owner=data.pharmacist_owner;
        data.shopact_license = shopact_license;
       // data.selected_firm=data.selected_firm.label;
        data.cin=data.cin;
        data.gstreg_type = data.gstreg_type.label;
        data.gst_no = data.gst_no;
        data.gst_country = data.gst_country;
        data.gst_state = data.gst_state;
        data.pan = data.pan;
        data.food_licence =food_licence;
        data.drug_licence1 = drug_licence1;
        data.drug_licence2 = drug_licence2;
        data.drug_licence3 =drug_licence3;
        const result = axios.put(updateProfilePharmacy, data, AUTH_CONFIG);
        console.log(result);
        const p = Promise.resolve(result);

        let resultdata = p.then((value) => {
          // console.log(value.data.status);
          // console.log(value.data.data);
          if (value.data.status === "1") {
            // this.props.history.push("/dashboard");
            window.location.replace("/dashboard");
          }
          if (value.data.status === "0") {
            data.phone_number = data.phone_code.concat(data.phone_number);

            this.setState({ error: value.data.message, pharmacyData: data,errors:errors });
          }
        });
        // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        // dispatch(
        // addError(e.message)
        // )
      }
    }
  };
  imageUpload = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    let newfileName = event.target.files[0].name;

    // console.log(imageconfig);
    imageconfig.dirName = 'pharmacy/' + this.props.userdata.id
    console.log(imageconfig);

    const reacts3client = new S3(imageconfig);
    console.log(reacts3client)
    reacts3client.uploadFile(file, newfileName).then(data => {
      console.log(data)
      if (data.status === 204) {
        console.log('success');
        let resultdata = {
          'avatar': data.location,
          'user_id': this.props.userdata.id,
          'user_type': "pharmacy"
        }
        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        axios.post(updateavatar, resultdata, CONFIG).then((result) => {
          this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
        })
      } else {
        console.log('error');
        this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
      }
    });

  };
  render() {
    const { pharmacyData, errors, error} = this.state;
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let specialities = [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;

    let facilities_array = this.props.appdata
      ? this.props.appdata[0].facilities
      : [];
    let facilities = [];
    facilities_array.forEach((element, index) => {
      facilities.push({ value: element.id, label: element.facility_name });
    });
    facilities_array = facilities;

    let category_array = this.props.appdata.length
      ? this.props.appdata[0].category
      : [];
    let category = [];

    category_array.forEach((element, index) => {
      category.push({ value: element.id, label: element.category_name });
    });
    this.category_array = category;

    const gstreg_type = [
      { value: 'registered', label: 'Registered' },
      { value: 'non-registered', label: 'Non-Registered' }
    ];
    const firm_array = [
      { value: 'proprietory', label: 'Proprietory'},
      { value: 'partnership', label: 'Partnership'},
      { value: 'private limited', label: 'Private Limited'},
      { value: 'limited', label: 'Limited'}
    ];
    

    return (
      <form className="custom-validation" onSubmit={this.handleFormSubmit}>
        {error ? <ErrorAlert message={error} /> : null}

        <div className="col-lg-12">
          <div className="card  bghome">
            <div className="card-body  pd-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
                  <div>
                    <Heading4 classname="card-title" title="BASIC DETAILS" />
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Pharmacy name"}
                          readOnly={true}
                          name={"pharmacy_name"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.pharmacy_name
                              ? pharmacyData.pharmacy_name
                              : ""
                          }
                          placeholder={""}
                          max={35}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.pharmacy_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="row">Profile picture</label>
                        <input type="file" accept="image/*" name="uploadedimage" onChange={this.imageUpload} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          id="address1"
                          inputType={"text"}
                          title={"Address 1"}
                          name={"address1"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.address1 ? pharmacyData.address1 : ""
                          }
                          placeholder={""}
                          max={100}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.address1}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <InputField
                          // isMandatory={true}
                          inputType={"text"}
                          title={"Address 2"}
                          name={"address2"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.address2 ? pharmacyData.address2 : ""
                          }
                          placeholder={""}
                          max={100}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.address2}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"City"}
                          name={"city"}
                          controlFunc={this.handleInput}
                          content={pharmacyData.city ? pharmacyData.city : ""}
                          placeholder={""}
                          max={20}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.city}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <Label title="Country" isMandatory={true} />

                      <CountryDropdown
                        placeholder="Country"
                        name="country"
                        value={pharmacyData.country || ""}
                        className="form-control"
                        onChange={(val) => this.selectCountry(val)}
                      />
                      <SpanField
                        classname="text-danger"
                        title={errors.country}
                      />
                    </div>
                    <div className="col-sm-3">
                      <Label title="State" isMandatory={true} />

                      <RegionDropdown
                        name="state"
                        defaultOptionLabel="Select State"
                        country={pharmacyData.country}
                        value={pharmacyData.state || ""}
                        className="form-control"
                        onChange={(val) => this.selectState(val)}
                        placeholder="State"
                      />
                      <SpanField classname="text-danger" title={errors.state} />
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Zip/Pincode"}
                          name={"zip_code"}
                          controlFunc={this.handleInput}
                          content={pharmacyData.zip_code ? pharmacyData.zip_code : ""}
                          placeholder={""}
                          max={10}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.zip_code}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <Label title="Phone number" isMandatory={true} />
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-control">
                            <PhoneInput
                              international
                              defaultCountry="IN"
                              name="phone_number"
                              disabled="true"
                              placeholder="Enter phone number"
                              value={pharmacyData.phone_number}
                              onChange={this.handlePhoneInput}
                              error={
                                pharmacyData.phone_number
                                  ? isValidPhoneNumber(
                                    pharmacyData.phone_number
                                  )
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                              max={10}
                            />
                          </div>
                          <SpanField
                            classname="text-danger"
                            title={errors.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          inputType={"text"}
                          title={"Fax"}
                          name={"fax_number"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.fax_number
                              ? pharmacyData.fax_number
                              : ""
                          }
                          placeholder={""}
                          max={15}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.fax_number}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group ">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Email"}
                          name={"email"}
                          readOnly= {true}
                          controlFunc={this.handleInput}
                          content={pharmacyData.email ? pharmacyData.email : ""}
                          placeholder={""}
                          max={35}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.email}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          inputType={"text"}
                          title={"Website"}
                          name={"website"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.website ? pharmacyData.website : ""
                          }
                          placeholder={""}
                          max={35}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.website}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          inputType={"text"}
                          title={"Year of built"}
                          name={"year_of_build"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.year_of_build
                              ? pharmacyData.year_of_build
                              : ""
                          }
                          placeholder={""}
                          max={4}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.year_of_build}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          inputType={"text"}
                          title={"Capacity"}
                          name={"capacity"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.capacity ? pharmacyData.capacity : ""
                          }
                          placeholder={""}
                          max={6}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.capacity}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <Label title="Business category" isMandatory={true} />

                      <div className="form-group">
                        <div className="input-group sform-control">
                          <span className="input-group-addon">
                            <i className="glyphicon fa fa-search icontextb"></i>
                          </span>
                          {/* <input id="email" type="text" className="form-control" name="text" placeholder="Search" /> */}
                          <Select
                            maxMenuHeight={120}
                            className="form-control p-0"
                            name="selected_category"
                            value={pharmacyData.selected_category}
                            onChange={this.handleselectChange}
                            options={this.category_array}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <Label title="Name of the Registered Pharmacist" isMandatory={true} />

                      <div className="form-group">
                                                                      
                          <InputField
                          inputType={"text"}
                          name={"pharmacist_name"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.pharmacist_name ? pharmacyData.pharmacist_name : ""
                          }
                          placeholder={""}
                         
                        />
                        
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <Label title="Pharmacist License No." isMandatory={true} />

                      <div className="form-group">
                                                                      
                          <InputField
                          inputType={"text"}
                          name={"pharmacist_license"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.pharmacist_license ? pharmacyData.pharmacist_license : ""
                          }
                          placeholder={""}
                         
                        />
                        
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <Label title="Valid Till" isMandatory={true} />

                      <div className="form-group">
                                                                      
                      <DatePicker
          className="form-control"
          value={pharmacyData.pharmacist_license_expirydate}
          format="dd-MM-yyyy"
          onChange={(date) => this.handleDatePickerChange(date, 'pharmacist_license_expirydate')} 
          yearPlaceholder='YYYY'
          monthPlaceholder='MM'
          dayPlaceholder='DD'
        //  disabled={!pharmacyData.pharmacist_license_date}
        />
                        
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <Label title="Type of Firm Registration" isMandatory={true} />

                      <div className="form-group">
                        <div className="input-group sform-control">
                          <span className="input-group-addon">
                            <i className="glyphicon fa fa-search icontextb"></i>
                          </span>
                         
                          <Select
                            maxMenuHeight={120}
                            className="form-control p-0"
                            name="selected_firm"
                            value={pharmacyData.selected_firm}
                            onChange={this.handleselectChange}
                            options={firm_array}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <Label title="Name of Owner/Partner/Director" isMandatory={true} />

                      <div className="form-group">
                                                                      
                          <InputField
                          inputType={"text"}
                          name={"pharmacist_owner"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.pharmacist_owner ? pharmacyData.pharmacist_owner : ""
                          }
                          placeholder={""}
                        
                        />
                        
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <Label title="Shop Act License No." isMandatory={true} />

                      <div className="form-group">
                                                                      
                          <InputField
                          inputType={"text"}
                          name={"shopact_license"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.shopact_license ? pharmacyData.shopact_license : ""
                          }
                          placeholder={""}
                         
                        />
                        
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <Label title="Valid Till" isMandatory={true} />

                      <div className="form-group">
                                                                      
                      <DatePicker
          className="form-control"
          value={pharmacyData.shopact_license_expirydate}
          format="dd-MM-yyyy"
          onChange={(date) => this.handleDatePickerChange(date, 'shopact_license_expirydate')} 
          yearPlaceholder='YYYY'
          monthPlaceholder='MM'
          dayPlaceholder='DD'
         // disabled={!pharmacyData.shopact_license_date}
        />
                        
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <Label title="Corporate Identification No." isMandatory={true} />

                      <div className="form-group">
                                                                      
                          <InputField
                          inputType={"text"}
                          name={"cin"}
                          controlFunc={this.handleInput}
                          content={
                            pharmacyData.cin ? pharmacyData.cin : ""
                          }
                          placeholder={""}
                          max={6}
                        />
                        
                      </div>
                    </div>

                    <div className="col-sm-6"></div>
                  </div>
                  {/* <!-- end row --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card  bghome">
            <div className="card-body pd-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
                  <div>
                    <Heading4 classname="card-title" title="OTHER DETAILS" />
                  </div>
                  <div className="formlayout ml-mr0">
                    <div className="row">
                    <div className="col-sm-6">
                        
                        <div className="row">
                          <div className="col-sm-12">
                            <h5 className="txtss mt-0">Working hours *</h5>
                            <hr className="mrgtp" />
                          </div>
                          <div className="col-sm-2">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Morning : </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>From</label>

                                
                                  <input
                                    disabled={
                                        pharmacyData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="morning_opens_at"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_opens_at"
                                    )}
                                    value={pharmacyData.working_hours
                                      ? pharmacyData
                                        .working_hours
                                        .morning_opens_at
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.morning_opens_at}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>To</label>

                                
                                  <input
                                    disabled={
                                        pharmacyData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="morning_close_at"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_close_at"
                                    )}
                                    value={pharmacyData.working_hours
                                      ? pharmacyData
                                        .working_hours
                                        .morning_close_at
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.morning_close_at}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3 text-center hal">
                            <label className="dpns">&nbsp;</label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch33"
                                switch="none"
                                onChange={this.handleWorkhoursChange.bind(
                                  this,
                                  "24Hours"
                                )}
                                checked={pharmacyData.working_hours.hours_24}
                              />
                              <label
                                htmlFor="square-switch33"
                                data-on-label="24 hours"
                                data-off-label="24 hours"
                                className="dcte22 wid-115"
                              ></label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-2">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Evening : </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>From</label>

                                 
                                  <input
                                    disabled={
                                        pharmacyData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="evening_opens_at"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_opens_at"
                                    )}
                                    value={pharmacyData.working_hours
                                      ? pharmacyData
                                        .working_hours
                                        .evening_opens_at
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.evening_opens_at}
                                  />
                                
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>To</label>

                                 
                                  <input
                                    disabled={
                                        pharmacyData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="evening_close_at"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_close_at"
                                    )}
                                    value={pharmacyData.working_hours
                                      ? pharmacyData
                                        .working_hours
                                        .evening_close_at
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.evening_close_at}
                                  />
                                
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3 text-center hal"></div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-12">
                            <h5 className="txtss">Working days*</h5>
                            <hr />
                            <div className="mt-4 mt-lg-0">
                              <CheckboxOrRadioGroup
                            
                                setName={"working_days"}
                                type={"checkbox"}
                                controlFunc={this.handleWorkingDays}
                                options={working_days}
                                selectedOptions={pharmacyData.working_days}
                              />

                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="allDaysId"
                                  switch="none"
                                  onChange={this.handleWorkingDays}
                                  checked={pharmacyData.working_all_days}
                                />
                                <label
                                  htmlFor="allDaysId"
                                  data-on-label="All days"
                                  data-off-label="All days"
                                  className="dcte22 wid-115"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <SpanField
                            classname="text-danger"
                            title={errors.working_days}
                          />
                          <div className="col-sm-12">
                        
                          </div>
                        
                        </div>
                      </div>
                    <div className="col-sm-6">
   
   <div className="frllftfull">
     <div className="form-group frllft mbbre">
       <Label title="GST Reg Type" isMandatory={true} />
     </div>
     <div className="form-group frlrit">
       <div className="position-relative">
         <div className="input-group">
       
       <Select
               maxMenuHeight={120}
               className="selectcls p-0"
               name="gstreg_type"
               isMandatory={true}
               value={pharmacyData.gstreg_type}
               onChange={this.handleselectChange}
               options={gstreg_type}
               placeholder="Select GST Type" 
             />
                                          
         </div>
         <SpanField
           classname="text-danger"
           title={errors.gstreg_type}
         />
       </div>
     </div>
   </div>

   
     
  
   <div className="frllftfull">
     <div className="form-group frllft mbbre">
     
       <Label title="GST Registration No"/>
     </div>
     <div className="form-group frlrit">
       <div className="position-relative">
         <div className="input-group">
        
            <input
           type="text"
           name="gst_no"
           value={pharmacyData.gst_no}
           onChange={this.handleInput}
           readOnly={pharmacyData.gst_type === 'non-registered'} 
           className="form-control"
           max={50}
         />
         </div>
         <SpanField
           classname="text-danger"
           title={errors.gst_no}
         />
       </div>
     </div>
   </div>


<div className="frllftfull">
   <div className="form-group frllft mbbre">
     <Label title="GST Country" />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
       <CountryDropdown
          placeholder="Country"
          // name="country"
          value={ pharmacyData.gst_country || ""}
          className="form-control"
          onChange={(val) => this.selectgstCountry(val)}
        />
         
       </div>
       <SpanField
         classname="text-danger"
         title={errors.gst_country}
       />
     </div>
   </div>
   <div className="form-group frlrclfgf-1">
     <Label title="GST State" />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
       <RegionDropdown
         // name="state"
         defaultOptionLabel="Select State"
         country={pharmacyData.gst_country}
         value={pharmacyData.gst_state || ""}
         className="form-control"
         onChange={(val) => this.selectgstState(val)}
         placeholder="State"
       />
                                   
       </div>
       <SpanField
         classname="text-danger"
         title={errors.gst_state}
       />
     </div>
   </div>
 </div>

   <div className="frllftfull">
     <div className="form-group frllft mbbre">
       <Label title="PAN" isMandatory={true} />
     </div>
     <div className="form-group frlrit">
       <div className="position-relative">
         <div className="input-group">
         <InputField
             inputType={"text"}
             name={"pan"}
             controlFunc={this.handleInput}
             content={pharmacyData.pan}
             placeholder={""}
             max={50}
             
             
           />
           
         </div>
         <SpanField
           classname="text-danger"
           title={errors.pan}
         />
       </div>
     </div>
   </div>


   <div className="frllftfull">
   <div className="form-group frllft mbbre">
     <Label title="Food Licence" />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
       <InputField
           inputType={"text"}
           name={"food_licence"}
           controlFunc={this.handleInput}
           content={pharmacyData.food_licence ? pharmacyData.food_licence : ""}
           placeholder={""}
           max={50}
         />
         
       </div>
       <SpanField
         classname="text-danger"
         title={errors.food_licence}
       />
     </div>
   </div>
   <div className="form-group frlrclfgf-1">
     <Label title="Valid Till" />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
     
        <DatePicker
         className="form-control"
         value={pharmacyData.food_expirydate}
         format="dd-MM-yyyy"
         onChange={(date) => this.handleDatePickerChange(date, 'food_expirydate')} 
         yearPlaceholder='YYYY'
         monthPlaceholder='MM'
         dayPlaceholder='DD'
         disabled={!pharmacyData.food_licence}
       />
                                               
       </div>
       <SpanField
         classname="text-danger"
         title={errors.food_expirydate}
       />
     </div>
   </div>
 </div>

   <div className="frllftfull">
   <div className="form-group frllft mbbre">
     <Label title="Drug Licence 1" />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
       <InputField
           inputType={"text"}
           name={"drug_licence1"}
           controlFunc={this.handleInput}
           content={pharmacyData.drug_licence1 ? pharmacyData.drug_licence1 : ""}
           placeholder={""}
           max={50}
         />
         
       </div>
       <SpanField
         classname="text-danger"
         title={errors.drug_licence1}
       />
     </div>
   </div>
   <div className="form-group frlrclfgf-1">
     <Label title="Valid Till" />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
     
          <DatePicker
           className="form-control"
           value={pharmacyData.licence1_expirydate}
           format="dd-MM-yyyy"
           onChange={(date) => this.handleDatePickerChange(date, 'licence1_expirydate')} 
           yearPlaceholder='YYYY'
           monthPlaceholder='MM'
           dayPlaceholder='DD'
           disabled={!pharmacyData.drug_licence1}
         />                                     
       </div>
       <SpanField
         classname="text-danger"
         title={errors.licence1_expirydate}
       />
     </div>
   </div>
 </div>

 <div className="frllftfull">
   <div className="form-group frllft mbbre">
     <Label title="Drug Licence 2" />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
       <InputField
           inputType={"text"}
           name={"drug_licence2"}
           controlFunc={this.handleInput}
           content={pharmacyData.drug_licence2 ? pharmacyData.drug_licence2 : ""}
           placeholder={""}
           max={50}
         />
         
       </div>
       <SpanField
         classname="text-danger"
         title={errors.drug_licence2}
       />
     </div>
   </div>
   <div className="form-group frlrclfgf-1">
     <Label title="Valid Till" />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
     
        <DatePicker
         className="form-control"
         value={pharmacyData.licence2_expirydate}
         format="dd-MM-yyyy"
         onChange={(date) => this.handleDatePickerChange(date, 'licence2_expirydate')} 
         yearPlaceholder='YYYY'
         monthPlaceholder='MM'
         dayPlaceholder='DD'
         disabled={!pharmacyData.drug_licence2}
       />
                                               
       </div>
       <SpanField
         classname="text-danger"
         title={errors.licence2_expirydate}
       />
     </div>
   </div>
 </div>

 <div className="frllftfull">
   <div className="form-group frllft mbbre">
     <Label title="Drug Licence 3"  />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
       <InputField
           inputType={"text"}
           name={"drug_licence3"}
           controlFunc={this.handleInput}
           content={pharmacyData.drug_licence3 ? pharmacyData.drug_licence3 : ""}
           placeholder={""}
           max={50}
         />
       </div>
       <SpanField
         classname="text-danger"
         title={errors.drug_licence3}
       />
     </div>
   </div>
   <div className="form-group frlrclfgf-1">
     <Label title="Valid Till"  />
   </div>
   <div className="form-group frlrclfgf">
     <div className="position-relative">
       <div className="input-group">
     
       <DatePicker
         className="form-control"
         value={pharmacyData.licence3_expirydate}
         format="dd-MM-yyyy"
         onChange={(date) => this.handleDatePickerChange(date, 'licence3_expirydate')} 
         yearPlaceholder='YYYY'
         monthPlaceholder='MM'
         dayPlaceholder='DD'
         disabled={!pharmacyData.drug_licence3}
       />

       </div>
       <SpanField
         classname="text-danger"
         title={errors.licence3_expirydate}
       />
     </div>
   </div>
 </div>



   </div>

                     
                    </div>
                  </div>

                {/*   <div className="formlayout ml-mr0">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5 className="txtss">Speciality* </h5>
                        <hr />
                        <div className="row mb-10">
                          <div className="col-sm-12">
                            <div className="input-group">
                            
                              <Select
                                maxMenuHeight={120}
                                className="form-control p-0"
                                name="speciality"
                                isMulti
                                value={pharmacyData.speciality}
                                onChange={this.handleselectChange}
                                options={specialities_array}
                              />
                              <SpanField
                                classname="text-danger width-100"
                                title={errors.speciality}
                              />
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>

                       <div className="col-sm-6">
                        <h5 className="txtss">Facilities</h5>
                        <hr />
                        <div className="row mb-10">
                          <div className="col-sm-12">
                            <div className="input-group">
                          
                              <Select
                                maxMenuHeight={120}
                                className="form-control p-0 border-0"
                                name="facility"
                                isMulti
                                value={pharmacyData.facility}
                                onChange={this.handleselectChange}
                                options={facilities_array}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                         
                        </div>
                      </div> 
                    </div>
                  </div> */}

                  <div>&nbsp;&nbsp;&nbsp;</div>
                  <div>&nbsp;&nbsp;&nbsp;</div>
                {/*    <div className="frllftfull">
                        <div className="form-group frllft">
                          <label>Payment Type</label>
                        </div>




                        <Fragment className="form-group frlrit">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-check fltx">

                                <input
                                  checked={this.state.payment_mode ==="Online"}
                                  className="form-check-input"
                                  type="radio"
                                  id="Online"
                                  value="Online"
                                  name="payment_mode"

                                  onChange={this.handlePaymentSelect}


                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay online"
                                >
                                  Pay online
                                </label>

                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="Direct"
                                  name="payment_mode"
checked={this.state.payment_mode ==="Direct"}
                                  value="Direct"
                                  onChange={this.handlePaymentSelect}

                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay at Clinic"
                                >
                                  Pay at Clinic
                                </label>
                              </div>
                            </div>
                          </div>
                        </Fragment>

                      </div>  */}
                  {/* <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <Label title="Profile summary " />

                        <TextArea
                         
                          rows={5}
                          resize={false}
                          content={
                            pharmacyData.profile_summary
                              ? pharmacyData.profile_summary
                              : ""
                          }
                          name={"profile_summary"}
                          controlFunc={this.handleInput}
                          placeholder={""}
                          maxLength={500}
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* <!-- end row --> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 btncent">
            <div className="form-group signupformbtn">
              <a href="pharmacy-profile">
                <button className="signupbtn">Sign Up</button>
              </a>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(connect(mapStateToProps)(PharmacySignup1));
