import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import axios from "axios";
import { Popup } from '../components/UI/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getPharmacyList,
  getAllPharmacyDrugs,
  getAllPharmacyItemList,
  getAllPharmacyVendorList,
  addPharmacy,
  addVendor,
  addPurchaseEntryData,
  updatePharmacy,
  deletePharmacy,
  getTimeoutbycategory,
  searchPharmacy,
  hospitalpharmacyimport,
  getitemlistforPharmacyDrug,
  getgststatebyVendorname,
  getPharmacyShortDetail,
  openingstockimport
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";
import Header from "../components/container/layout/pharmacy/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import Validation from "../actions/Validation";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Tooltip from '@mui/material/Tooltip';
import Label from "../components/UI/Label";
import PharmacyListExport from "./Business/PharmacyListExport";
import PharmacyListTemplate from "./Business/PharmacyListTemplate";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import readXlsxFile from 'read-excel-file';
import { Popupinfo } from "../components/UI/Popupinfo";
import FooterSection from "../components/UI/FooterSection";
import { renameKey } from "../constants/globalLevelFunctions";
import DatePicker from "react-date-picker";
import OpeningStockTemplate from "./OpeningStockTemplate";
var converter = require("number-to-words");

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}
const google = window.google;
const onlineOptions = [{ value: 1, label: "Yes" }, { value: 0, label: "No" }];
let record = {
    s_no: "",
    generic_name:"",
    pack:"",
    batch_no:"",
    manu_date:"",
    exp_date:"",
    quantity:"",
    free: "",
    p_rate:"",
    mrp:"",
    disc: "",
    sub_total:0,
    cgst: "",
    sgst:"",
    igst:"",
    total:0,
   
  };
class OpeningStockUpload extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;
    this.autocomplete = null;
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,

      records: [],
      searchrecords: [],
      addPopup: false,
      actions: "add",
      search_action: false,
      newDatatoAdd: {},
     searchErrorMessage: "",
     purchaseEntryVoucher:{
     drug_name:"",
    // defaultEnterSize: 20,
      records: [],
       supplies: [],
     },
    
    
      OpeningStockData:{
        drug_id:"",
        generic_name:"",
        pack:"",
        batch_no:"",
        manu_date:"",
        exp_date:"",
        quantity:"",
        p_rate:"",
        mrp:"",
        sub_total:0,
        total:0,
        transaction_type:"",
        transaction_date:"",
        vendor_name:"",
        customer_name:"",
        notes:""
      },
      totalAmtSec: {

        discAmt: 0, 
        cgstAmt: 0, 
        sgstAmt: 0, 
        igstAmt: 0, 
        total: 0,
        sub_total:0,
        totalAmtWords: "",
      },

      errors: {
        purchaseentry_id: "",
        vendor_name:"",
        address:"",
        created_by:"",
        remarks:"",
        voucher_no:"",
        voucher_date:"",
        vendorinvoice_no:"",
        invoice_date:"",
        poref_no:""
      },
      tableSuccess: {
        message: "",
        status: ""
      },
      formSuccess: {
        message: "",
        status: ""
      },
      defaultEnterSize: 25,
      records: [],
      supplies: [],
    };
 
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }
  handlePhoneInput = (event) => {
    let pharmacyData = this.state.pharmacyData;
    let errors=this.state.errors;
    pharmacyData.phone_number = event;
    errors.phone_number="";
    this.setState({ pharmacyData,errors });
  };
 
  handleClose() {
    this.setState({ showModal: false })
  }

  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  };

  _onAction(e) {
    // console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

 
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
  
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
/* 
  handleInput =(index,record) => {
    console.log(e);
    console.log(index);
    console.log(record);
    record[e.target.name] = e.target.value;
  }; */

  handleAdd = (e) => {
    let data = this.state.purchaseEntryData;
    let errors = this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (
    
      name === "vendor_name" ||
      name === "address" ||
      name === "created_by"||
      name === "remarks"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = ""
    }
    this.setState({ purchaseEntryData: data, errors,searchErrorMessage: "" });
  };

  handleInput = (index, field) => (event) => {
    const { value } = event.target;
    const records = [...this.state.records]; // Create a copy of the records
    records[index][field] = value; // Update the specific field for the specific index
   // console.log(value);
  //  console.log(index,field);
    console.log(records);
    this.setState({ records }); // Update the state with the new records
  };

  handleDatePickerChange = (date, field) => {
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    
    this.setState(prevState => ({
      purchaseEntryData: {
        ...prevState.purchaseEntryData,
        [field]: offsetDate, // Update the specific expiry date field
      },
    }));
  };
  
  handleInputChange = (index, key) => event => {
    let updatedData = [...this.state.records];
    updatedData[index][key] = event.target.value ? parseFloat(event.target.value) : '';
    this.setState({ records: updatedData }, () => {
        this.calculateSubTotal(index);
    });
};

calculateSubTotal = (index) => {
    let updatedData = [...this.state.records];
    let p_rate = updatedData[index].p_rate || 0;
    let quantity = updatedData[index].quantity || 0;
    let disc= updatedData[index].disc;
    updatedData[index].sub_total = quantity*(p_rate-((disc/100)*p_rate));
    
    this.setState({ records: updatedData }, () => {
      
        this.calculateTotal(index);
        this.calculateDiscountAmount(index);
        this.calculateIgstAmount(index);
        this.calculateCgstAmount(index);
        this.calculateSgstAmount(index);
    });
};

calculateTotal = (index) => {
    let updatedData = [...this.state.records];
    let sub_total = updatedData[index].sub_total || 0;
    let igst = updatedData[index].igst || 0;
    updatedData[index].total = sub_total+((igst/100)*sub_total);
    console.log(igst);
    console.log(updatedData[index].total);
    this.setState({ records: updatedData }, () => {
        this.calculateSection();
    });
};

calculateDiscountAmount = (index) => {
    let updatedData = [...this.state.records];
    let p_rate = updatedData[index].p_rate || 0;
    let quantity = updatedData[index].quantity || 0;
    let disc= updatedData[index].disc;
    updatedData[index].discAmt=((quantity*p_rate)*(disc/100));
    this.setState({ records: updatedData }, () => {
        this.calculateSection();
      
    });
};
calculateCgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let cgst= updatedData[index].cgst;
    let sub_total=updatedData[index].sub_total;
    updatedData[index].cgstAmt=(sub_total*(cgst/100));
    this.setState({ records: updatedData }, () => {
        this.calculateSection();
      
    });
};
calculateSgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let sgst= updatedData[index].sgst;
    let sub_total=updatedData[index].sub_total;
    updatedData[index].sgstAmt=(sub_total*(sgst/100));
    this.setState({ records: updatedData }, () => {
        this.calculateSection();
      
    });
};
calculateIgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let igst= updatedData[index].igst;
    console.log(igst);
    let sub_total=updatedData[index].sub_total;
    updatedData[index].igstAmt=(sub_total*(igst/100));
    this.setState({ records: updatedData }, () => {
        this.calculateSection();
      
    });
};
handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;
    let max = event.target.max;
    let value=event.target.value;
     if (
       event.target.name === "searchpharmacy_id"
 
     ) {
       if (value.length > max) {
         const value1 = value.slice(0, max);
         record.pharmacyid_error  = "Maximum Length has exceeded.";
         record[event.target.name] = value1;
       }
      else {
       record.pharmacyid_error  = "";
       record[event.target.name] = event.target.value;
     }
   }
    this.UpdateSearchColumnChange(record);
  };





  UpdateColumnChange = (record) => {
    
        let records = this.state.records;
        var recIndex = findWithAttr(records, "id", record.id);
    
        records[recIndex].pharmacy_id = record.pharmacy_id?record.pharmacy_id:"";
        records[recIndex].pharmacy_name = record.pharmacy_name;
        records[recIndex].pharmacy_location = record.pharmacy_location;
        records[recIndex].address = record.address;
        records[recIndex].city = record.city;
        records[recIndex].country = record.country;
        records[recIndex].state = record.state;
        records[recIndex].zip_code = record.zip_code;
        // records[recIndex].phone_number = record.phone_number;
    
        this.setState({ records: records });
      }
  UpdatePharmacy = (e) => {
    e.preventDefault();
    let { pharmacyData, errors } = this.state;
    pharmacyData.type = "pharmacyList";
    
    errors = Validation(pharmacyData, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      let data = this.state.pharmacyData;
      data.user_id = this.props.userdata.id;
      data.online_prescription = data.online_prescription_obj.label;
      if (this.state.actions === "add") {  
      if(data.pharmacy_location=="")
      {
       data.pharmacy_location = data.address;
       data.pharmacy_verification="Not Verified";
       }else{
      
        data.pharmacy_verification="Verified";
      
       }
      }else
      {

              
        if(data.address==data.pharmacy_location)
        {
          data.pharmacy_verification=data.pharmacy_verification;
          console.log(data.pharmacy_verification);
        }
        else
        {
          data.address=data.address;
          data.pharmacy_verification="Not Verified";
          console.log(data.pharmacy_verification);
        }
      }
       console.log(data.pharmacy_location);
       console.log(data.address);
      
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      let url = this.state.actions === "add" ? addPharmacy : updatePharmacy;
      if (this.state.actions === "add") {
        axios.post(url, data, CONFIG).then((list) => {
          if (0 == list.data.status) {
            toast.error("Pharmacy could not be added ", {
              className: "toast-error"
            });
            this.setState({ errors: { pharmacy_id: list.data.message } });
          } else {
            let addPharmacy = {};
            addPharmacy.pharmacy_id = data.pharmacy_id;
            addPharmacy.pharmacy_name = data.pharmacy_name;
            addPharmacy.online_prescription = data.online_prescription_obj.label;
            addPharmacy.id = list.data.data[0].id;
            addPharmacy.address = data.address;
            addPharmacy.phone_number = data.phone_number;
            addPharmacy.email = data.email;
            if(data.pharmacy_location="")
            {
            addPharmacy.pharmacy_location = data.address;
            addPharmacy.pharmacy_verification=data.pharmacy_verification;
            }else
            {
              addPharmacy.pharmacy_location = data.pharmacy_location;
              addPharmacy.pharmacy_verification=data.pharmacy_verification;
            }
            addPharmacy.city = data.city;
            addPharmacy.country = data.country;
            addPharmacy.state = data.state;
            addPharmacy.zip_code = data.zip_code;
            addPharmacy.online_prescription = data.online_prescription;
            let records = this.state.records;
            addPharmacy.s_no = records.length + 1;
            records.push(addPharmacy);

            this.setState({ records });
            // this.setState({ formSuccess: list.data });
            toast.success("The Pharmacy details have been added successfully.", {
              className: "toast-success"
            });
            this.clearMessage();
            this.cancel();
          }
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(getPharmacyList, { user_id: this.props.userdata.id }, CONFIG)
            .then((list) => {
              let pharmacyList = list.data.data;
              pharmacyList.forEach((element, index) => {
                let pharmacy = {};
                pharmacy.s_no = index + 1;
                pharmacy.pharmacy_id = element.pharmacy_id;
                pharmacy.pharmacy_name = element.pharmacy_name;
                pharmacy.id = element.id;
                pharmacy.online_prescription = element.online_prescription;
                pharmacy.pharmacy_location = element.pharmacy_location;
                pharmacy.phone_number = element.phone_number;
                pharmacy.email = element.email;
                pharmacy.address = element.address;
                pharmacy.city = element.city;
                pharmacy.country = element.country;
                pharmacy.state = element.state;
                pharmacy.zip_code = element.zip_code;
                pharmacy.online_prescription = element.online_prescription;
                pharmacy.pharmacy_verification=element.pharmacy_verification;
      
                pharmacyList[index] = pharmacy;
              });
      
              this.setState({ records: pharmacyList });
            })
            .catch((e) => { });
      

        });
      } else {

        axios.put(url, data, CONFIG).then((list) => {

          if (0 == list.data.status) {
            toast.error("Pharmacy could not be updated ", {
              className: "toast-error"
            });
            this.setState({ success: list.data });
          } else {
            let{editRecord}=this.state;
            let place = this.autocomplete.getPlace();
            let records = this.state.records;
            var addr = document.getElementById("address");
            
            // Get geocoder instance
            var geocoder = new google.maps.Geocoder();
             
            // Geocode the address
            geocoder.geocode({
                'address': addr.value
            }, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
             
                    // set it to the correct, formatted address if it's valid
                  //  addr.value = results[0].formatted_address;;
                //  data.pharmacy_verification="Verified";
                } else {
                         
                   // show an error if it's not
                //   alert("Invalid address");
                }
            });
            console.log(addr.value)
          //  console.log(place.formatted_address);
     /*        console.log(editRecord.address);
          if(data.address==editRecord.address)
          {
            data.address=data.address;
            data.pharmacy_verification=editRecord.pharmacy_verification;
          }else
          {
            data.address=place.formatted_address;
            data.pharmacy_verification="Verified";
                        
          }
      */       var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].pharmacy_id = data.pharmacy_id;
            records[recIndex].pharmacy_name = data.pharmacy_name;
            records[recIndex].online_prescription = data.online_prescription_obj.label;
            records[recIndex].phone_number = data.phone_number;
            records[recIndex].email = data.email;
            records[recIndex].address = data.address;
            records[recIndex].pharmacy_location = data.address;
            records[recIndex].city = data.city;
            records[recIndex].country = data.country;
            records[recIndex].state = data.state;
            records[recIndex].zip_code = data.zip_code;
            records[recIndex].online_prescription = data.online_prescription;
            records[recIndex].pharmacy_verification = data.pharmacy_verification;
            records[recIndex].id = data.id;

            this.setState({ records });
            //  this.setState({  formSuccess: list.data });
            toast.success("The Pharmacy details have been updated successfully. ", {
              className: "toast-success"
            });
            this.clearMessage();
            this.cancel();
          }
        });
      }

    }
  };

  clearErrors = (errors) => {

   
    errors.address = ""
    errors.created_by = ""
    errors.voucher_no = ""
    errors.voucher_date = ""
    errors.invoice_date = ""
    errors.remarks = ""
    errors.vendorinvoice_no = ""
    errors.poref_no= ""
    return errors
  }

  cancel = () => {
    
    let data = this.state.purchaseEntryData;
   
    data.id = ""
    data.user_id = ""

    data.vendor_name = {
      label:"",value:""
    }
    data.address = ""
    data.created_by = ""
    data.voucher_no = ""
    data.voucher_date = ""
    data.invoice_date = ""
    data.remarks = ""
    data.vendorinvoice_no = ""
    data.poref_no = ""
   

    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ purchaseEntryData: data, actions: "add",voucher_no:"", errors: errors, searchErrorMessage: "", records: [], 
      search_action: false, 
      totalAmtSec: {  
        discAmt: 0, 
        cgstAmt: 0, 
        sgstAmt: 0, 
        igstAmt: 0, 
        total: 0,
        sub_total:0,
       
      },

    });
  }
 
  clearimport=()=>{
    this.setState({ file:"" });
  };
 
  clearMessage = () => {
    setTimeout(() => this.setState({
      formSuccess: { message: "", },
      tableSuccess: { message: "" }
    }), 5000);
  }

 /*  calculateSection = () => {
    let records = this.state.records;
    let totalAmtSec = this.state.totalAmtSec;
    let amt = 0.0;
    let taxAmt = 0.0;
    let taxVal = 0.0;
    let totalAmt = 0.0;
    let sub_total=0.0;
   console.log(records);

    records.map((data, i) => {
      if (undefined !== data.fee) {
        amt = Number(amt) + Number(data.fee);
      }
      if (undefined !== data.gst_amount) {
        taxAmt = Number(taxAmt) + Number(data.gst_amount);
      }
      if (undefined !== data.taxable_value) {
        taxVal = Number(taxVal) + Number(data.taxable_value);
      }
      if (undefined !== data.total_amount) {
        totalAmt = Number(totalAmt) + Number(data.total_amount);
      }
      if (undefined !== data.quantity) {
        sub_total = Number(data.quantity) * Number(data.p_rate);
      }
    });

    totalAmtSec.amt = amt;
    totalAmtSec.taxAmt = taxAmt;
    totalAmtSec.taxVal = taxVal;
    totalAmtSec.totalAmt = totalAmt;
    totalAmtSec.sub_total=sub_total;
    totalAmtSec.totalAmtWords = converter.toWords(totalAmt)+" rupees only";
    this.setState({ totalAmtSec });

    console.log(totalAmtSec);
  }; 
 */

  calculateSection = () => {
    let records = this.state.records;
    let totalAmtSec = { ...this.state.totalAmtSec };
 
    let discAmt = 0.0;
    let cgstAmt = 0.0; 
    let sgstAmt = 0.0; 
    let igstAmt = 0.0; 
    let total = 0.0;
    let sub_total = 0.0;

    console.log(records);

    records.forEach((data, i) => {
    
        if(data.discAmt!== undefined)
        {
        discAmt += Number(data.discAmt);
        }
       if(data.cgstAmt!== undefined)
        {
            cgstAmt += Number(data.cgstAmt);
        }
        if(data.sgstAmt!== undefined)
            {
                sgstAmt += Number(data.sgstAmt);
            }
            if(data.igstAmt!== undefined)
                {
                    igstAmt += Number(data.igstAmt);
                }
      if (data.total !== undefined) {
        total += Number(data.total);
      }
      if (data.sub_total !== undefined) {
        sub_total += Number(data.sub_total); // Sum up the sub_total for all records
      }
    });

    totalAmtSec.discAmt = discAmt;
    totalAmtSec.cgstAmt = cgstAmt;
    totalAmtSec.sgstAmt = sgstAmt;
    totalAmtSec.igstAmt = igstAmt; 
    totalAmtSec.total = total;
    totalAmtSec.sub_total = sub_total;
    totalAmtSec.totalAmtWords = converter.toWords(total) + " rupees only";
    
    this.setState({ totalAmtSec });

    console.log(totalAmtSec);
}; 

addPurchaseEntryData() {
   
  
    let { purchaseEntryData, errors } = this.state;
    purchaseEntryData.type = "purchaseEntryData";
    errors = Validation(purchaseEntryData, errors);
    this.setState({ errors: errors });
  
    if (errors.formIsValid) {
      this.setState({ loading: true });
       
      let data = {
         user_id: this.props.userdata.id,
         vendor_name: purchaseEntryData.vendor_name.label,
          address: purchaseEntryData.address,
           created_by: purchaseEntryData.created_by,
            remarks: purchaseEntryData.remarks,
             voucher_date: purchaseEntryData.voucher_date,
              vendorinvoice_no: purchaseEntryData.vendorinvoice_no,
               invoice_date: purchaseEntryData.invoice_date,
                poref_no: purchaseEntryData.poref_no,
                 records: this.state.records 
                };
                console.log(data);
      let records = this.state.records;
      
        axios
        
          .post(addPurchaseEntryData, data, CONFIG)
          .then((addVendorResponse) => {
            let res = addVendorResponse.data;
            console.log(res)
            if (res.status === "1") {
          //  console.log(res.data[0].voucher_no);
            
              this.setState({
                records: records,
                searchTableSuccess: res,
                loading: false,
                editformSuccess: res,
               voucher_no:res.data[0].voucher_no
              });
              toast.success("The Purchase Entry voucher have been added successfully.", {
                className: "toast-success"
              });
              this.clearMessage();
              //this.clearAll();
            } else {
              this.setState({
                searchrecords: records,
                searchTableSuccess: res,
                editformSuccess: res,
                loading: false,
              });
              toast.error("Purchase Entry voucher could not be Added. ", {
                className: "toast-error"
              });
              this.clearMessage();
            }
          })
          .catch(() => { });
      
    }
  }

handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { purchaseEntryData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    console.log(place);
    purchaseEntryData.address = place.formatted_address;
    

    this.setState({
      purchaseEntryData,
    });
  }
  componentDidMount = () => {
    this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("address"),
        {}
      );
      this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  
   
    axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getAllPharmacyItemList, { user_id: this.props.userdata.id }, CONFIG)
        .then((result) => {
          let supplies = result.data.data;
          console.log(supplies);
          //to rename json key object
          supplies.forEach((obj) => renameKey(obj, "drug_id", "value"));
          supplies.forEach((obj) => renameKey(obj, "generic_name", "label"));

          //set first record as consultation fee by default
         /*  let consultation_fee_record = {};
          consultation_fee_record.s_no = 1;
          consultation_fee_record.description = "Consultation Fee";
          consultation_fee_record.fee = completion_data.consultation_fee.replace(/[^0-9]/g, '');
          consultation_fee_record.disc = "";
          consultation_fee_record.taxable_value = "";
          consultation_fee_record.gst_rate = "";
          consultation_fee_record.gst_amount = "";
          consultation_fee_record.total_amount = completion_data.consultation_fee.replace(/[^0-9]/g, '');
*/
          let records = [];
         // records.push(consultation_fee_record); 

          let defaultEnterSize = this.state.defaultEnterSize;

          for (let i = 1; i <= defaultEnterSize; i++) {
            let default_rec = Object.assign({}, record);
            default_rec.s_no = i;
            records.push(default_rec);
          }

          this.setState(
            {
              supplies: supplies,
              loading: false,
              records: records,
            },
            () => {
              this.calculateSection();
            }
          );
        })
        .catch((e) => {
          this.setState({ loading: false });
        });

   
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
      .post(getAllPharmacyVendorList, { user_id: this.props.userdata.id }, CONFIG)
      .then(({ data }) => {
        const mastervendorNameList = data.data;
        const uniqueDrugNames = new Set();
        const mastervendorNames = mastervendorNameList
          .filter((element) => {
            
            if (!uniqueDrugNames.has(element.vendor_name)) {
              uniqueDrugNames.add(element.vendor_name); 
              return true; 
            }
            return false; 
          })
          .map((element) => ({
            value: element.vendor_id,
            label: element.vendor_name,
          
          }));
    
        this.setState({ mastervendorNames });
      })
      .catch((error) => {
        console.error("Error fetching drug data:", error);
       
      });

    axios
    .post(getPharmacyShortDetail,{user_id:this.props.userdata.id}, CONFIG)
    .then((Respons) => {
      let data = Respons.data.data[0];
      console.log(data.state);
   
      if (data.status == "1")
        this.setState({
       State_value:data.state

        });
    }).catch((e) => { });
  

   

  };
  onDeleteClick(record) {
    
    this.setState({ addPopup: true, record: record });
  }
  deleteRecord(record) {
    
    let userId = record.id;

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deletePharmacy, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records
            .map((item) => item.id)
            .indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "" });
        toast.error("The Pharmacy detail has been deleted successfully.", {
          className: "toast-error"
        });
        //  this.setState({ tableSuccess: res.data });
        this.clearMessage();
        this.cancel();
      })
      .catch((e) => { });
  }
  onFileUpload = () => {

    if (this.state.OpeningStockData.length) {
       axios.post(openingstockimport, {data: this.state.OpeningStockData,user_id:this.props.userdata.id}).then((result) => {
           console.log(result);
           console.log(result.data.message);
           this.setState({ formSuccess: result.data.message, file: "", OpeningStockData: [] });
           if(result.data.status='1')
           {
           toast.success(result.data.message, {
            className: "toast-success"
          });
        }else{
          toast.error(result.data.message, {
            className: "toast-error"
          });

        }
            this.clearMessage();
       });
   }
 
 };
  handleselectChange = (index, key) => selectedOption => {
    // Create a copy of the current table data
    let updatedData = [...this.state.records];
    
    // Update the specific row's key with the selected option value
    updatedData[index][key] = selectedOption ? selectedOption.label : "";

    // Set the state with the updated data
    this.setState({ records: updatedData });

    // Fetch the state value
    axios
        .post(getPharmacyShortDetail, { user_id: this.props.userdata.id }, CONFIG)
        .then((response) => {
            let data = response.data.data[0];
           

            if (response.data.status === "1") {
                this.setState({ State_value: data.state }, () => {
                    // Fetch item details after state is updated
                    console.log("State : ", this.state.State_value);
                    console.log("GST State: ", this.state.gst_state);
                    this.fetchItemDetails(index, selectedOption);
                });
            }
        })
        .catch((error) => {
            console.error("Error fetching state details:", error);
        });
};

       fetchItemDetails = (index, selectedOption) => {
    axios
        .post(getitemlistforPharmacyDrug, { user_id: this.props.userdata.id, generic_name: selectedOption.label }, CONFIG)
        .then((drugResponse) => {
            let itemList = drugResponse.data.data;
            console.log("Fetched Item List: ", itemList); // Verify the API response

            // Ensure the API response contains the `pack` value
            let item = itemList.find(item => item.generic_name === selectedOption.label);
            if (item) {
                // Create a copy of the current table data again
                let updatedData = [...this.state.records];
               
                // Update the specific row with additional details
                updatedData[index] = {
                    ...updatedData[index],
                    pack: item.unit_of_measure,
                    cgst: this.state.State_value === this.state.gst_state ? item.cgst  : "",
                    sgst: this.state.State_value === this.state.gst_state ? item.sgst  : "",
                    igst: this.state.State_value !== this.state.gst_state?item.igst  : "",
                    sub_total:updatedData[index].quantity * updatedData[index].p_rate,
                };
                console.log("Updated Record: ", updatedData[index]); // Verify the updated record
               console.log(updatedData[index].quantity);
                // Set the state with the updated records
                this.setState({ records: updatedData });
               
            }
            this.setState({ record }, () => {
                this.calculateSection();
              });
        })
        .catch((error) => {
            console.error("Error fetching item details:", error);
        });
        };

  
  
  handleColumnChange = (record) => {
    record.online_prescription = ("Yes" === record.online_prescription) ? "No" : "Yes";
    this.UpdateColumnChange(record);
  }
  validateMaxLength(name, value, max) {
    let data = this.state.purchaseEntryData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ purchaseEntryData: data, errors: errors });
  }

 
  onFileChange = event => {

    // Update the state
    this.setState({ file: event.target.files[0] });
    readXlsxFile(event.target.files[0]).then((rows) => {
        console.log(rows);
        console.log("rows");
        this.setState({ OpeningStockData: rows });
        // `rows` is an array of rows
        // each row being an array of cells.
    })

};


handlevendorselectChange = async (selectedOptions, e) => {
    let data = this.state.purchaseEntryData;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;

    
    this.setState({ purchaseEntryData: data, errors,searchErrorMessage: "" });

    axios
    .post(getgststatebyVendorname,{user_id:this.props.userdata.id,vendor_name:selectedOptions.label}, CONFIG)
    .then((Respons) => {
      let resdata = Respons.data;
      let data=resdata.data[0];
      console.log(resdata.status);
    
        if (resdata.status == "1") 
        {
         this.setState({ gst_state: data.gst_state }, () => {
          console.log("Updated GST State: ", this.state.gst_state); }); }
            
    }).catch((e) => { });

}
  render() {

    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    const { purchaseEntryData,OpeningStockData,records, errors, formSuccess, tableSuccess, hospitaltimeout,mastervendorNames,gst_state,State_value,searchErrorMessage,searchTableSuccess,loading } = this.state;
    return (

      <div className="col-sm-12">
   <IdleTimeOutModal
      showModal={this.state.showModal}
      handleClose={this.handleClose}
      handleLogout={this.handleLogout}
      />
   <IdleTimer
      ref={ref =>
   { this.idleTimer = ref }}
   element={document}
   onActive={this.onActive}
   onIdle={this.onIdle}
   onAction={this.onAction}
   debounce={250}
   timeout={hospitaltimeout} />
   <div className="main-content bacfot">
   <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <BusinessUserSideBar />
              </div>
    {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
    
    
            <section className="main-body hospital-profilegap">
            <div className="container-fluid">
   <div className="row checkout-tabs">
      <div className="col-sm-12">
         <h4 className="font-weight-bold"> Opening Stock Upload </h4>
      </div>
      <div className="col-sm-12 pr0">
         <div className="card b-12-hite">
         <div class="card-header">
             <div class="card-title text-center fs-2"> 
             Opening Stock Upload
                     </div> 
                    
                     </div>
            <div className="card-body">
               <div className="user-notification">
                  <SpanField
                  classname={('0' == formSuccess.status) ? "text-danger user-notification" :
                  "text-success user-notification"}
                  title={formSuccess.message}
                  />
               </div>
             
                  </div>

                                           

                                    
                                
                                    <div className="card">
                                            <div className="card-header"> <div className="card-title">Import Opening Stock</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                      
                                                        <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    "0" === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div>
                                                        <div className="form-group d-flex justify-content-center mg-btm5">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                        <div className="float-right btn btn2 "> <OpeningStockTemplate /></div>
                              
                                                        <div className="row">
                                                                
                                                            <div className="col-sm-4" >
                                                                <div className="custom-file text-left">
                                                                    
                                                                    <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" type="file" onChange={this.onFileChange} name="file" className="custom-file-input" id="file" />
                                                                    <label className="custom-file-label" htmlFor="file">{this.state.file ? this.state.file.name : ""}</label>
                                                                   
                                                                
                                                                </div>
                                                             
                                                            </div>

                                                       
                                                           
                                                        </div>
                                                    </div>
                                                  {/*   <div className="form-group"><label className="control-label">{this.state.file ? this.state.file.name : ""}</label></div> */}
                                                </div>
                                            </div>
                                    </div>
                                    </div>
   
</div>
</div>
 </section>          )}
 <FooterSection />
 
</div>
</div>
</div>
</div>

    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpeningStockUpload));

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
