import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
// import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
// import BusinessUserSideBar from "../components/container/SideBar";
// import ClientSideBar from "../components/container/layout/client/SideBar";
// import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import Headerweb from './Headerweb';
import FooterWeb from './Footerweb';
import Topmove from './Topmove';
// import ProfileImage3 from './assets/images/profile3.png';
// import myProfilePic from '../../../assets/images/profile3.png'; 

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class ListOfService extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
            <div className="">

                <div className="">
                    {/* <section className="my-head"> */}
                        {/* <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div> */}
                    {/* </section> */}
                    <div className="">
                        {/* <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div> */}

                        {/* <section className="main-body hospital-profilegap "> */}
                            {/* <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            /> */}

                            {/* <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} /> */}

                            {/* <div className="container-fluid checkout-tabs"> */}
                                {/* <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                /> */}

                                {/* <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} /> */}

<div className='product-section'>
    <Headerweb />
    <hr className='m-0' style={{ color: '#D9F4FF' }} />
    <section className='py-5'>
        <div className='container-90' id='increase_revenue' style={{ marginTop: '50px' }}>
            <div className='row m-0 align-items-center'>
                <div className='col-lg-6 col-12 slide-left visible'>
                    <h1>Product Feature</h1>
                    <div className='find-doctor text-center py-3'>
                        <a href="/listofservice" style={{ textDecoration: 'none' }}>
                            <button>
                                List of service
                                <img src={require("../../src/assets/images/website/arrowup.png")} className='ml-2' alt="Arrow Up" />
                            </button>
                        </a>
                    </div>
                </div>
                <div className='col-lg-6 col-12'>
                    <p> Clinical Management System (CMS) is a software solution designed to streamline and enhance the administrative and clinical functions within healthcare facilities. It plays a crucial role in managing patient information, improving efficiency, ensuring regulatory compliance, and enhancing overall patient care. Here are some key components and features typically found in a Clinical Management System</p>
                </div>
            </div>
        </div>
        <div className=''>
                <div className='' id='increase_productivity' style={{ marginTop: '50px', marginBottom: '50px' }}>
    <div className='row m-0'>
        <div className='col-12'>
            <img src={require("../../src/assets/images/website/ProductFeature1.png")} className='w-100 h-auto' alt="Increase Productivity" />
        </div>
    </div>
</div>
</div>

        {/* New Section for Three Images with Text */}
        
        <div className='hospital-details py-5'>
          <div className='container-90'>
            <div className='row m-0 align-items-stretch justify-content-center'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 slide-left visible'>
                <div className='patient '>
                  <div className=''>
                    <img src={require("../../src/assets/images/website/patient8.png")} className='w-100 ' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content '>
                    <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Patient</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>The patient portal is designed to manage primary & family members under the same account with a unique ID for each member.</p>
                      </div>
                      </div>
                      {/* <div className=''> */}
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <i class="fa-solid fa-arrow-right"  style={{ color: '#00275A' }} ></i> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img className='icons' /> */}
                        {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-0 my-sm-0 my-2'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/profile3.png")} className='w-100' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content'>
                  <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Doctor</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>A doctor portal is designed based on the physician's specialty and can work for any specialization.</p>
                      </div>
                      </div>
                      <div className=''>
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img  className='icon' /> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-2 my-sm-2 slide-right visible'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/profile1.webp")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                  <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Hospital</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>A hospital portal is designed to manage multi-specialty and different consultation modes.</p>
                      </div>
                      </div>
                      <div className=''>
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                        </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <div className='hospital-details py-5'>
          <div className='container-90'>
            <div className='row m-0 align-items-stretch justify-content-center'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 slide-left visible'>
                <div className='patient '>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/patient8.png")} className='w-100 ' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content '>
                    <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Patient</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>The patient portal is designed to manage primary & family members under the same account with a unique ID for each member.</p>
                      </div>
                      </div>
                      {/* <div className=''> */}
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <i class="fa-solid fa-arrow-right"  style={{ color: '#00275A' }} ></i> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img className='icons' /> */}
                        {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-0 my-sm-0 my-2'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/profile3.png")} className='w-100' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content'>
                  <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Doctor</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>A doctor portal is designed based on the physician's specialty and can work for any specialization.</p>
                      </div>
                      </div>
                      <div className=''>
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img  className='icon' /> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-2 my-sm-2 slide-right visible'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/profile1.webp")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                  <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Hospital</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>A hospital portal is designed to manage multi-specialty and different consultation modes.</p>
                      </div>
                      </div>
                      <div className=''>
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                        </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hospital-details py-5'>
          <div className='container-90'>
            <div className='row m-0 align-items-stretch justify-content-center'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 slide-left visible'>
                <div className='patient '>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/patient8.png")} className='w-100 ' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content '>
                    <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Patient</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>The patient portal is designed to manage primary & family members under the same account with a unique ID for each member.</p>
                      </div>
                      </div>
                      {/* <div className=''> */}
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <i class="fa-solid fa-arrow-right"  style={{ color: '#00275A' }} ></i> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img className='icons' /> */}
                        {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-0 my-sm-0 my-2'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/profile3.png")} className='w-100' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content'>
                  <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Doctor</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>A doctor portal is designed based on the physician's specialty and can work for any specialization.</p>
                      </div>
                      </div>
                      <div className=''>
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                          {/* <img  className='icon' /> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-2 my-sm-2 slide-right visible'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/profile1.webp")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                  <div className='white-box1'>
                    <h1 style={{ color: 'black' }}>Hospital</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p style={{ color: 'gray' }}>A hospital portal is designed to manage multi-specialty and different consultation modes.</p>
                      </div>
                      </div>
                      <div className=''>
                        {/* <div className='arrow-section'> */}
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' /> */}
                        </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add your existing content here... */}
    </section>
</div>

                                {/* <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="font-weight-bold">QR Code</h4>
                                    </div> */}


                                {/* </div> */}

                                {/* <div className="card pt-4">
                                    <div className="card-body">
                                        <div className="tab-content text-center" id="v-pills-tabContent">
                                            <h5 className="hedtxt2 ">Open the built-in camera app and point the camera at the QR Code Scanner for Check-in purposes.</h5>

                                            <div
                                                className="tab-pane fade show active qrcode pd-15"
                                                id="v-pills-shipping"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-shipping-tab"

                                            >


                                                <QRCode value={this.props.userdata.phone_number} />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <Footerad /> */}
                            {/* </div>
                          
                        </section> */}
                        <Topmove />
                        <FooterWeb className="qrCodeFooter" />
                    
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount = () => {
     /*    console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ListOfService);