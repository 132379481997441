import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
// import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
// import BusinessUserSideBar from "../components/container/SideBar";
// import ClientSideBar from "../components/container/layout/client/SideBar";
// import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import Headerweb from './Headerweb';
import FooterWeb from './Footerweb';
import Topmove from './Topmove';
// import ProfileImage3 from './assets/images/profile3.png';
// import myProfilePic from '../../../assets/images/profile3.png'; 

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class Webphysician extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
          
<div className='product-section'>
    <Headerweb/>
    <div className='sub-banner text-center'>
                <h1>Physician</h1>
                {/* <p>A Clinical Management System (CMS) is a software solution designed to streamline and enhance the administrative and clinical functions within healthcare facilities. It plays a crucial role in managing patient information, improving efficiency, ensuring regulatory compliance, and enhancing overall patient care. Here are some key components and features typically found in a Clinical Management System</p> */}
            </div>
            <hr className='m-0' style={{ color: '#D9F4FF' }} />
            <section className='py-5'>
                <div className='container-90'>
                    <div className='row m-0'>
                        <div className='col-lg-6 col-12 text-center slide-left visible'>
                            <img src={require("../../src/assets/images/website/product1.png")} className='w70 h70 ml-5 mt-50 d-lg-block d-none' />
                        </div>
                        <div className='col-lg-6 col-12 mt-lg-0 mt-5 slide-right visible'>
                            <h1>Computer aided or Handwritten e-Prescription</h1>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/product1.png")} className='w70 h70 ml-5 mt-50 d-lg-none d-block' />
                            </div>
                            <p>Improved accuracy: Reduce errors </p>
                            <p>Enhanced patient safety: Ensure that medications are prescribed correctly and accurately.</p>
                            <p>Increased efficiency: Streamline the prescription process and reduce administrative burden.</p>
                           
                        </div>
                    </div>
                </div>
                <div className='bg-section'>
                    <div className='container-90' id='integrated_solution'>
                        <div className='row m-0 flex-wrap-reverse' >
                            <div className='col-lg-6 col-12 mt-lg-5 pt-lg-5 mt-5 slide-left visible'>
                                <h1>Easy access to Electronic Medical Records</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/product2.png")} className='w70 h70 ml-5 mt-50 d-lg-none d-block' />
                                </div>
                                <p>Improved patient care: Access patient medical history, allergies, and medications at the point of care.</p>
                                <p>Enhanced coordination: Facilitate seamless communication and collaboration between healthcare providers.</p>
                                <p>Reduced errors: Minimize the risk of medication errors or adverse drug interactions.</p>
                                
                            </div>
                            <div className='col-lg-6 col-12 text-center slide-right visible'>
                                <img src={require("../../src/assets/images/website/product2.png")} className='w70 h70 ml-5 mt-50 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-90 pt-5'>
                    <div className='row m-0' id='no_expensive_hardware'>
                        <div className='col-lg-6 col-12 text-center slide-left visible'>
                            <img src={require("../../src/assets/images/website/product3.png")} className='w70 h70 ml-5 mt-50 d-lg-block d-none' />
                        </div>
                        <div className='col-lg-6 col-12 mt-lg-5 pt-lg-5 slide-right visible'>
                            <h1>Diagnostic Accuracy</h1>
                            <div className='img-responsive'>
                                <img src={require("../../src/assets/images/website/product3.png")} className='w70 h70 ml-5 mt-50 d-lg-none d-block' />
                            </div>
                            <p>Improved decision-making: Provide healthcare providers with accurate and timely diagnostic information.</p>
                            <p>Enhanced patient care: Deliver more effective and targeted treatments.</p>
                           
                        </div>
                    </div>
                </div>
                <div className='bg-section' >
                    <div className='container-90' id="easy_communication" >
                        <div className='row m-0 flex-wrap-reverse'>
                            <div className='col-lg-6 col-12 mt-lg-5 mt-5 slide-left visible'>
                                <h1>Easy to Customize screens based on specialty</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/product4.png")} className='w70 h70 ml-5 mt-50 d-lg-none d-block' />
                                </div>
                                <p>Enhanced user experience: Provide a more intuitive and personalized experience for healthcare providers.</p>
                                <p>Increased productivity: Save time and effort by accessing relevant information quickly and easily.</p>
                               
                            </div>
                            <div className='col-lg-6 col-12 text-center slide-right visible'>
                                <img src={require("../../src/assets/images/website/product4.png")} className='w70 h70 ml-5 mt-50 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>

                </div>

                <div className='container-90' id='user_friendly'>
               
                    <div className='row m-0 pt-5' >
                        <div className='col-lg-6 col-12 text-center slide-left visible'>
                            <img src={require("../../src/assets/images/website/product5.png")} className='w70 h70 ml-5 mt-50' />
                        </div>
                        <div className='col-lg-6 col-12 mt-lg-0 mt-5 slide-right visible'>
                        <h1 >Simplified Check-in & Check-out Process</h1>
                            <p>Reduced wait times: Streamline the check-in and check-out process, reducing patient frustration.</p>
                            <p>Improved efficiency: Improve the overall clinic workflow and productivity.</p>
                            <p>Enhanced patient experience: Create a more welcoming and efficient environment for patients.</p>
                            
                        </div>
                    </div>
                </div>
                <div className='bg-section' >
                    <div className='container-90' id="easy_communication" >
                        <div className='row m-0 flex-wrap-reverse'>
                            <div className='col-lg-6 col-12 mt-lg-5 mt-5 slide-left visible'>
                                <h1>Easier Specialty Physician Referral</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/feature-2.png")} className='w70 h70 ml-5 mt-3 d-lg-none d-block' />
                                </div>
                                <p>Improved patient care: Connect patients with appropriate specialists for their specific needs.</p>
                                <p>Enhanced access to care: Expand access to specialized care, especially in rural areas.</p>
                                <p>Reduced patient frustration: Streamline the referral process and minimize delays.</p>
                            </div>
                            <div className='col-lg-6 col-12 text-center slide-right visible'>
                                <img src={require("../../src/assets/images/website/feature-2.png")} className='w70 h70 ml-3 mt-3 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='container-90' id='user_friendly'>
               
               <div className='row m-0 pt-5 align-items-center' >
                   <div className='col-lg-6 col-12 text-center slide-left visible'>
                       <img src={require("../../src/assets/images/website/feature-3.png")} className='w70 h70 ml-5' />
                   </div>
                   <div className='col-lg-6 col-12 mt-lg-0 mt-5 slide-right visible'>
                   <h1 >
                   Easier Reminder setup for follow ups</h1>
                       <p>Improved patient adherence: Ensure that patients follow up on appointments, medications, or treatment plans.</p>
                       <p>Enhanced patient outcomes: Improve health outcomes by increasing adherence to treatment regimens.</p>
                       <p>Reduced missed appointments: Minimize no-shows and optimize clinic resources.</p>
                   </div>
               </div>
           </div>
           <div className='bg-section' >
                    <div className='container-90' id="easy_communication" >
                        <div className='row m-0 flex-wrap-reverse'>
                            <div className='col-lg-6 col-12 mt-lg-5 mt-5 slide-left visible'>
                                <h1>Improved Patient Engagement</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/product4.png")} className='w70 h70 ml-5 mt-50 d-lg-none d-block' />
                                </div>
                                <p>Increased satisfaction: Provide patients with a more convenient and personalized experience.</p>
                                <p>Enhanced communication: Facilitate communication between patients and healthcare providers.</p>
                                <p>Improved health outcomes: Encourage patients to take an active role in their own care.</p>
                            </div>
                            <div className='col-lg-6 col-12 text-center slide-right visible'>
                                <img src={require("../../src/assets/images/website/feature-4.png")} className='w70 h70 ml-5 mt-50 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='container-90' id='user_friendly'>
               
               <div className='row m-0 pt-5' >
                   <div className='col-lg-6 col-12 text-center slide-left visible'>
                       <img src={require("../../src/assets/images/website/feature-5.png")} className='w70 h70 ml-5 mt-50' />
                   </div>
                   <div className='col-lg-6 col-12 mt-lg-0 mt-5 slide-right visible'>
                   <h1 >
                   Seamless Integration between Pharmacy & Labs:</h1>
                      <p>Improved efficiency: Streamline the process of sending prescriptions and lab orders.</p>
                      <p>Reduced errors: Minimize errors associated with manual data entry.</p>
                      <p>Enhanced patient experience: Provide a more seamless and convenient experience for patients.</p>
                   </div>
               </div>
           </div>
           <div className='bg-section' >
                    <div className='container-90' id="easy_communication" >
                        <div className='row m-0 flex-wrap-reverse'>
                            <div className='col-lg-6 col-12 mt-lg-5 mt-5 slide-left visible'>
                                <h1>Teleconsultation:</h1>
                                <div className='img-responsive'>
                                    <img src={require("../../src/assets/images/website/feature-6.png")} className='w70 h70 ml-5 mt-5 d-lg-none d-block' />
                                </div>
                                <p class="text-justify">Increased accessibility: Expand access to healthcare services, especially in remote areas.</p>
                                <p class="text-justify">Improved convenience: Allow patients to consult with healthcare providers from the comfort of their homes.</p>
                                <p class="text-justify">Reduced healthcare costs: Lower the cost of healthcare by reducing travel time and expenses.</p>
                            </div>
                            <div className='col-lg-6 col-12 text-center slide-right visible'>
                                <img src={require("../../src/assets/images/website/feature-6.png")} className='w70 h70 ml-5 mt-5 d-lg-block d-none' />
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className='container-90' id='user_friendly'>
               
               <div className='row m-0 pt-5 align-items-center' >
                   <div className='col-lg-6 col-12 text-center slide-left visible'>
                       <img src={require("../../src/assets/images/website/feature-1.png")} className='w70 h70 ml-5' />
                   </div>
                   <div className='col-lg-6 col-12 mt-lg-0 mt-5 slide-right visible'>
                   <h1 >Privacy & Security</h1>
                   <p class="text-justify">KlinicQ application is designed per HIPAA (Health Insurance Portability and Accountability Act) rules. Personal identification information (PII) and protected health information (PHI) records are securely encrypted &amp; are safe and not for sale to any third parties.

</p>
<p class="text-justify">KlinicQ Clinical Management System role-based security is assigned to the doctors, hospitals, and patients to provide relevant authorization to control access. Privacy &amp; security are our number one priority, and we ensure that no selling of personal data.</p>
                   </div>
               </div>
           </div> */}
            </section>

                                {/* <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="font-weight-bold">QR Code</h4>
                                    </div> */}


                                {/* </div> */}

                                {/* <div className="card pt-4">
                                    <div className="card-body">
                                        <div className="tab-content text-center" id="v-pills-tabContent">
                                            <h5 className="hedtxt2 ">Open the built-in camera app and point the camera at the QR Code Scanner for Check-in purposes.</h5>

                                            <div
                                                className="tab-pane fade show active qrcode pd-15"
                                                id="v-pills-shipping"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-shipping-tab"

                                            >


                                                <QRCode value={this.props.userdata.phone_number} />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <Footerad /> */}
                            {/* </div>
                          
                        </section> */}
                        <Topmove />
                        <FooterWeb className="qrCodeFooter" />
                    </div>
                   
        );
    }
    componentDidMount = () => {
     /*    console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Webphysician);