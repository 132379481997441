import React, { Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import axios from "axios";
import { LoginUrl, forgotPasswordClient, verifyOTPClient, sendsms,sendemail, resendOTPClient, textNotificationURL, emailNotificationURL } from "../../config/url.json";
import { CONFIG } from "../../config/data";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import Headerweb from "../Headerweb";

import Validation from "../../../src/actions/Validation";
import { withRouter } from "react-router-dom";
import ErrorAlert from "../../components/UI/ErrorAlert";
import { fadeInDown } from "react-animations";
import styled, { keyframes } from "styled-components";
import { Spinner } from "../../components/UI/Spinner";
import { Fragment } from "react";
import OtpTimer from 'otp-timer'
import ChatBot from 'react-simple-chatbot';
import Dnaspinner from "../../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    userdata: state.AuthReducer,
  };
}

const FadeStyle = styled.div`
  animation: 1s ${keyframes`${fadeInDown}`};
`;

class login extends Component {
  constructor(props) {
    super(props);

    this.handleOtp = this.handleOtp.bind(this);
    this.state = {
      loading: false,
      forgotuser: {},
      resendsuccess: true,
      email: "",
      password: "",
      errors: {},
      error: "",
      registered_detail: "",
      isForgotPasswordClicked: false,
      isOTPField: false,
      isverified: false,
      issubmitted: false,
      unique_id: "",
      temp: "",
    };
  }
  componentDidMount = () => {

  };
  handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };
  handlepassword = (e) => {

$(".toggle-password").toggleClass("fa-eye fa-eye-slash");

let input = $('#password-field');
if (input.attr("type") == "password") {
  input.attr("type", "text");
} else {
  input.attr("type", "password");
}
  }
  handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    let errors = this.state.errors;
    if (this.state.registered_detail === "" || null == this.state.registered_detail) {
      errors.registered_detail = "Enter your registered Email or Phone number.";
      errors.formIsValid = false;
      this.setState({ errors: errors });
    } else {
      errors.registered_detail = "";
      errors.formIsValid = true;
      this.setState({ errors: errors });
    }
    const data = {
      email_phonenumber: this.state.registered_detail,
    };


    if (errors.formIsValid) {

      this.setState({ loading: true });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios.post(forgotPasswordClient, data, CONFIG).then((result) => {
        let resultData = result.data;
       
        if (resultData.status === "0") {
          this.setState({ error: resultData.message, loading: false });
          this.clearErrorMessage();
        }
         else
         {

          let user = resultData.data.user;
          this.setState({
            forgotuser: user,error:""
          }
          );
          console.log(user);
          console.log(user.first_name);
          let reqdata = {
            "to" : user.email,
            "subject" : "OTP VERIFICATION FOR RESET PASSWORD",
            "mailHeader" : "OTP VERIFICATION FOR RESET PASSWORD",
            "message": "Hello "+user.first_name+",<p>You have requested to reset the password of your KlinicQ account.</p><p>Please find the OTP code to change your password.</p><p>OTP is valid for 2 minutes.</p><p> "+resultData.data.otp+"</p><p>If you did not initiate this request,you can safely ignore this email.</p><p>Thanks,</p><p>KlinicQ Team.</p>"
        }
          let notificationdata = {
            "message_code": "SEND_OTP",
            "language_code": "en",
            "data": {
              "user_name": user.first_name,
              "otp": resultData.data.otp

            },
            "receiver": {
              "phoneNumber": user.phone_code.concat(user.phone_number)
            }
          };
          /*let notificationdata = {
                "message_code": "WLCME_MSG",
                "language_code": "en",
                "data": {
                  "business_name": "Meenakshi",
                  "customer_name": "Customer"
                },
                "receiver": {
                  "phoneNumber": "+919152414264"
                }
              };*/
          console.log(notificationdata);
          axios
          .post(sendemail, reqdata)
          .then((res) => {
            let resdata = res.data;  
            console.log(res);
          })
          axios
            .post(sendsms, notificationdata)
            .then((result) => {
              console.log(result)
            }).catch((err) => {
              console.log(err);
            })
          this.setState({
            data: data,
            unique_id: resultData.data.unique_id,
            isOTPField: true, issubmitted: true, temp: resultData.data.otp,
            loading: false
          });
          window.setTimeout(() => {
            this.setState({
              resendsuccess: false,
            });
          }, 5000);
        }

      })
        .catch((e) => { console.log(e) });
    }


  };
  handleForgotPasswordCancel = (e) => {
    e.preventDefault();

    this.setState({
      isOTPField: false,
      isverified: false,
      isForgotPasswordClicked: false,
      issubmitted: false,
      errors: {},
      error: ""
    });
  };

  handleOtp = (e) => {
    e.preventDefault();
    let errors = this.state.errors;
    if (this.state.otp === "" || null == this.state.otp) {
      errors.otp = "Please enter your otp";
      errors.formIsValid = false;
      this.setState({ errors: errors });
    } else {
      errors.otp = "";
      errors.formIsValid = true;
      this.setState({ errors: errors });
    }
    const data = {
      unique_id: this.state.unique_id,
      otp: this.state.otp
    };


    if (errors.formIsValid) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      data.otp_data=this.state.resendotpvalue;
      axios.post(verifyOTPClient, data, CONFIG).then((result) => {
        let resultData = result.data;
        console.log(resultData);
        console.log(resultData.status);
        
        if (resultData.status === "1") {
          this.setState({
            isOTPField: false,
            isverified: true,
            isForgotPasswordClicked: true,
          });

          // this.props.history.push('/forgot-password');
          this.props.history.push({
            pathname: "/reset-password",
            state: {
              registered_detail: this.state.registered_detail
            }
          });

         
        } else {

          this.setState({ error: resultData.message });
             this.clearErrorMessage();
        }

      })
     
    }


  };
  clearErrorMessage() {
  
      setTimeout(() => {
        this.setState({
        
          error:"",
        });
      }, 5000);
    
    }
  clearForm = () => {
    
    console.log("clear");
    
    this.setState({
   
      data: {
        otp: "",
        unique_id: "",
        type: "verification",
        otp_data: "",
      },
      errors: {
       
        otp: "",
      },
      error:"",
      temp: "",
      
    });
  };
  resendOtp = (e) => {
 //   e.preventDefault();
    this.clearForm();
    let email_phonenumber = "";
    let user = this.state.forgotuser;
    console.log(user);
    let url = "";
    let Notificationdata = {};
    if (user.phone_number !== null) {
      email_phonenumber = user.phone_number;
      url = textNotificationURL;
      Notificationdata.phoneNumber = email_phonenumber;
    } else if (user.email !== null) {
      email_phonenumber = user.email;
      url = emailNotificationURL;
      Notificationdata.email = email_phonenumber;
    }

    if (email_phonenumber !== "") {
      try {
        let data = {};
        /* const data = {
           unique_id: this.state.unique_id,
           otp: this.state.otp
         };
     */
        data.email_phonenumber = email_phonenumber;
        const result = axios.post(resendOTPClient, data, CONFIG);

        const p = Promise.resolve(result);

        let resultdata = p.then((value) => {
          if (value.data.status === "1") {
            this.props.setUserData(value.data.user);
            let data1 = resultdata.data;
            console.log(data1);
          //  data1.unique_id = value.data.unique_id;
           // data1.otp_data=value.data.otp;
            //  data1.unique_id = value.data.unique_id;
            // console.log(data1.first_name);
            let reqdata = {
              "to" : user.email,
              "subject" : "OTP VERIFICATION FOR RESET PASSWORD",
              "mailHeader" : "OTP VERIFICATION FOR RESET PASSWORD",
              "message": "Hello "+user.first_name+",<p>You have requested to reset the password of your KlinicQ account.</p><p>Please find the OTP code to change your password.</p> "+value.data.otp+ "<p> OTP is valid for next 2 minutes.</p><p>If you did not initiate this request,you can safely ignore this email.</p><p>Thanks,</p><p>KlinicQ Team.</p>"
          }
            let notificationdata = {
              "message_code": "SEND_OTP",
              "language_code": "en",
              "data": {
                "user_name": user.first_name,
                "otp": value.data.otp

              },
              "receiver": {
                "phoneNumber": user.phone_code.concat(user.phone_number)
              }
            };


            console.log(notificationdata);
            axios
            .post(sendemail, reqdata)
            .then((res) => {
              let resdata = res.data;  
              console.log(res);
            })
            axios
              .post(sendsms, notificationdata)
              .then((response) => {
                console.log(response);
                this.setState({
                  data: data1,
                  unique_id: value.data.unique_id,
                  resendsuccess: true,
                  isOTPField: true,
                  issubmitted: true,
                  temp: value.data.otp,
                  loading: false
                });
              }).catch((error) => {
                console.log(error);
                if (e.response) {
                  this.setState({ error: error.response.data.errors });
                  window.setTimeout(() => {
                    this.setState({
                      error: "",
                    });
                  }, 5000);
                }
              })

            window.setTimeout(() => {
              this.setState({
                resendsuccess: false,
              });
            }, 5000);
          }
          if (value.data.status === "0") {
            this.setState({ error: value.data.message });
            window.setTimeout(() => {
              this.setState({
                error: "",
              });
            }, 5000);
          }
        });

        // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        // dispatch(
        // addError(e.message)
        // )
      }
    }

  };


  handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: this.state.email,
      password: this.state.password,
      type: "login",
    };
    let errors = this.state.errors;
    errors = Validation(data, errors);

    this.setState({ errors: errors });
    if (errors.formIsValid) {
      axios
        .post(LoginUrl, data, CONFIG)
        .then((res) => {
          // cookie.set("token", res.data.access_token);
          if (res.data.status === "0") {
            this.setState({ error: res.data.message });
            window.setTimeout(() => {
              this.setState({
                error: "",
              });
            }, 5000);
          } else {
            if (res.data.data.user.otp_verified === "Yes") {
              window.setTimeout(() => {
                localStorage.setItem("token", res.data.data.access_token);
                console.log(localStorage.getItem("token"));
                // return false;
                // dispatch
                this.props.setLogin(res.data.data.user);
                this.props.setAuthToken(res.data.data.access_token);

                // this.props.history.push("/dashboard");
                (res.data.data.user.user_type === 'admin') ? (window.location.replace("/admindashboard")) : (window.location.replace("/dashboard"))
              }, 500);
            } else {
              // otp not verified yet
              this.props.setUserData(res.data.data.user);
              // return false;
              // this.props.history.push("/verify");
              window.location.href = "/verify";
            }
          }

          // if (res.data.data.user.is_active === "No")
          // this.props.history.push("/verify/" + res.data.data.user.unique_id);
        })
        .catch((e) => {

          this.setState({ error: e.message });
        });
    }
  };
  render() {
    const { errors, email, password, error, loading,data } = this.state;
    return (
      <div id="layout-wrapper">
  <Headerweb />
  <div className="main-content">
    <div className="mainpage">
      <section className="registerpage loginbg">
        <form className="col-md-12">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img
                  src={require("../../assets/images/website/Group8.png")}
                  alt="Description"
                  className="img-fluid hide-on-mobile"
                />
              </div>
              <div className="col-md-6">
                {this.state.isForgotPasswordClicked ? (
                  <Fragment>
                    {loading && <Dnaspinner />}
                    <FadeStyle>
                      {!this.state.issubmitted ? (
                        <div className="row">
                          <div className="col-sm-9">
                            <SpanField
                              classname={
                                errors.registered_detail ? "text-danger" : "text_black"
                              }
                              title={
                                <span style={{ fontSize: '18px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                                  {errors.registered_detail ? errors.registered_detail :
                                    "Enter your registered mobile number / email id"}
                                </span>
                              }
                            />
                            <p></p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row ">
                        <div className="col-sm-12 mb-2 ">
                          {!this.state.issubmitted ? (
                            <div className="input-group">
                              <InputField
                                isMandatory={true}
                                inputType={"text"}
                                name={"registered_detail"}
                                controlFunc={this.handleInput}
                                placeholder={""}
                                max={50}
                              />
                            </div>
                                                      ) : (
                            ""
                          )}
                          {this.state.issubmitted && !this.state.isverified ? (
                            <div className="col-sm-12 form-control graylight">
                              <span>{this.state.registered_detail}</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-5">
                          <div className="row mobiletop">
                            {!this.state.issubmitted ? (
                              <div className="col-sm-5 mr-2 mt-3">
                                <button
                                  type="button"
                                  className="submitbtn"
                                  style={{ background: '#0C5B98' }}
                                  onClick={this.handleForgotPasswordSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                            {!this.state.isverified ? (
                              <div className="col-sm-5 mt-3">
                                <button
                                  type="button"
                                  className="submitbtn"
                                  style={{ background: '#0C5B98' }}
                                  onClick={this.handleForgotPasswordCancel}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </FadeStyle>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="mb-5">
                      <h4 style={{ fontSize: "24px" }}>Login to your account</h4>
                      <p style={{ fontSize: "18px" }}>Welcome back.</p>
                    </div>
                    {error ? <ErrorAlert message={error} /> : null}
                    <div className="row mtlogin login-form">
  <div className="col-sm-12 mb-2">
    <div className="mobiletop1" style={{ fontSize: "15px" }}>
      <label>E-mail or Phone</label>
      <div className="position-relative">
        <div className="input-group3 mb-4">
          <i className="fa-regular fa-envelope fa-xl mr-1"></i>
          <InputField
            isMandatory={true}
            inputType={"text"}
            name={"email"}
            controlFunc={this.handleInput}
            content={email}
            placeholder={"Enter Email / Phone"}
          />
        </div>
        <SpanField classname="text-danger" title={errors.email} />
      </div>
    </div>
  </div>
  <div className="col-sm-12 mb-4">
    <div className="mobiletop1" style={{ fontSize: "15px" }}>
      <label>Password</label>
      <div className="position-relative">
        <div className="input-group3">
        <i class="fa-solid fa-lock fa-xl mr-1"></i>
          <InputField
            isMandatory={true}
            inputType={"password"}
            name={"password"}
            controlFunc={this.handleInput}
            content={password}
            id="password-field"
            placeholder={"Enter Password"}
          />
          <span
            toggle="#password-field"
            onClick={this.handlepassword}
            className="fa fa-fw fa-eye field-icon toggle-password mb-2"
          ></span>
        </div>
        <SpanField classname="text-danger" title={errors.password} />
      </div>
    </div>
  </div>

                      <div className="col-sm-12">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="rememberMe"
                            />
                            <label className="form-check-label" htmlFor="rememberMe">
                              Remember Me
                            </label>
                          </div>
                          <span
                            onClick={() =>
                              this.setState({
                                isForgotPasswordClicked: true,
                              })
                            }
                            style={{ cursor: "pointer", color: "black", fontSize: "16px" }}
                          >
                            Forgot Password?
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-4">
                        <div className="mobiletop d-flex justify-content-center">
                          <button
                            type="submit"
                            className="submitbtn1"
                            onClick={this.handleSubmit}
                          >
                            Sign in
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-4" style={{ fontSize: "16px" }}>
                      <span className="new-account">Don’t have an account? </span>
                                            <a
                        href="/register"
                        className="forget click-btn"
                        style={{ color: "black", textDecoration: "none" }}
                        id="createAccountLink"
                      >
                        <span>Create an account</span>
                      </a>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>

            {/* OTP Section */}
            {this.state.isOTPField ? (
              <Fragment>
                <FadeStyle>
                  <div className="row">
                    <div className="col-sm-12">
                      <h5> </h5>
                      <p className="text_black">
                        Your one-time password (OTP) has been sent to your registered
                        mobile/email.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="input-group">
                        <InputField
                          inputType={"text"}
                          name={"otp"}
                          controlFunc={this.handleInput}
                          placeholder={"Enter the 6 digit OTP here to verify"}
                        />
                      </div>
                      <SpanField classname="text-danger" title={errors.otp} />
                    </div>
                    <div className="col-sm-5">
                      <div className="row">
                        <div className="col-sm-8 otpVerify">
                          <div className="d-flex align-items-center">
                            <button
                              onClick={this.handleOtp.bind(this)}
                              className="signupbtn signupbtntp"
                              style={{ background: '#0C5B98' }}
                            >
                              Verify
                            </button>
                            <OtpTimer className="text_white" seconds={60} minutes={1} resend={this.resendOtp} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeStyle>
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </form>
      </section>
    </div>
  </div>
</div>
      
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) => dispatch({ type: "SET_LOGIN", payload: user }),
    setAuthToken: (token) =>
      dispatch({ type: "SET_ACCESS_TOKEN", payload: token }),
    setUserData: (user) => dispatch({ type: "SET_USERDATA", payload: user }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(login)
);
