import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './YourStylesheet.css'; // Ensure to import your CSS

class Webadslider extends Component {
    constructor(props) {
        super(props);
        // Adjust the array to only include the images for indices 6, 7, and 8
        this.adImages = [
            require('../assets/images/website/Frame 6.png'),
            require('../assets/images/website/Frame 7.png'),
            require('../assets/images/website/Frame 8.png'),
        ];

        this.owlOptions = {
            items: 3, // Show three images at a time
            autoplay: true,
            autoplayTimeout: 5000,
            loop: true,
            margin: 20,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
            },
        };
    }

    render() {
        return (
            <div className='ps-lg-5 ps-2 py-5'>
               

                <OwlCarousel className="owl-theme" {...this.owlOptions}>
                    {this.adImages.map((image, index) => (
                        <div key={index} className="item2">
                            <div className="">
                                <div className="row">
                                    <div className="">
                                        <img src={image} alt={`Advertisement ${index + 6}`}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        );
    }
}

export default Webadslider;