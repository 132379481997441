import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import axios from "axios";
import { Popup } from '../components/UI/Popup';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import {

  getAllSalePatientList,
  deleteSaleByBillnumber,
  getAllUniquePurchaseEnryList,
  deletePharmacy,
  getTimeoutbycategory,
  searchPharmacy,
  searchEmployee,
  searchSaleEntries,
  hospitalpharmacyimport,
  getitemlistforPharmacyDrug,
  deletePurchaseByVouchernumber,
  getgststatebyVendorname,
  getPharmacyShortDetail
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";
import Header from "../components/container/layout/pharmacy/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import Validation from "../actions/Validation";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Tooltip from '@mui/material/Tooltip';
import Label from "../components/UI/Label";
import PharmacyListExport from "./Business/PharmacyListExport";
import PharmacyListTemplate from "./Business/PharmacyListTemplate";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import readXlsxFile from 'read-excel-file';
import { Popupinfo } from "../components/UI/Popupinfo";
import FooterSection from "../components/UI/FooterSection";
import { renameKey } from "../constants/globalLevelFunctions";
import DatePicker from "react-date-picker";
var converter = require("number-to-words");
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}
const google = window.google;
const onlineOptions = [{ value: 1, label: "Yes" }, { value: 0, label: "No" }];
let record = {
  s_no: "",
  drug_name: "",
  pack: "",
  batch_no: "",
  manu_date: "",
  exp_date: "",
  quantity: "",
  free: "",
  p_rate: "",
  mrp: "",
  disc: "",
  sub_total: 0,
  cgst: "",
  sgst: "",
  igst: "",
  total: 0,

};
class StockandSalesReport extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;
    this.autocomplete = null;
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,

      reportList: [],
      records: [],
      addPopup: false,
      updatePopup: false,
      actions: "add",
      search_action: false,
      newDatatoAdd: {},
      searchErrorMessage: "",
      selectedRecord: null,
      saleRegister:
      {
        FromDate: "",
        ToDate: "",
        patient_name: "",
        bill_no: "",
        bill_date: ""
      },



      errors: {
        purchaseentry_id: "",
        vendor_name: "",
        address: "",
        created_by: "",
        remarks: "",
        voucher_no: "",
        voucher_date: "",
        vendorinvoice_no: "",
        invoice_date: "",
        poref_no: ""
      },
      tableSuccess: {
        message: "",
        status: ""
      },
      formSuccess: {
        message: "",
        status: ""
      },
      defaultEnterSize: 25,
      records: [],
      supplies: [],
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
        width: '20px',
      },
      {
        key: "category",
        text: "Category",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',
      },
      {
        key: "item_id",
        text: "Item ID",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '10px',
       
      },

      {
        key: "item_name",
        text: "Item Name",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',
      },
      {
        key: "pack",
        text: "Pack",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '10px',
      },

   
      {
        key: "mfg_name",
        text: "Mfg Name",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',

      },
      {
        key: "opening",
        text: "Opening",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',

      },
      {
        key: "purchase",
        text: "Purchase",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',
      },
      {
        key: "purchase_return",
        text: "Purchase Return",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',
      },
      {
        key: "sales",
        text: "Sales",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',
      },
      {
        key: "sales_return",
        text: "Sales Return",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',
      },
      {
        key: "closing",
        text: "Closing",
        className: "columnalign",
        align: "left",
        sortable: true,
        width: '20px',
      },
     /*  {
        key: "action",
        text: "Action",
        className: "action",
        width: '20px',
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <Tooltip title="view">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.viewRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-eye"></i>
                </button>
              </Tooltip>
              <Tooltip title="Edit">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.editRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-edit"></i>
                </button>
              </Tooltip>
              <Tooltip title="Delete">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => this.onDeleteClick(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </Tooltip>

            </Fragment>

          );
        },
      }, */
    ];

    this.extraButtons = [
      {
        className: "btn btn-primary buttons-pdf",
        title: "Export TEst",
        children: [
          <span>
            <i
              className="glyphicon glyphicon-print fa fa-print"
              aria-hidden="true"
            ></i>
          </span>,
        ],
        onClick: (event) => {
          console.log(event);
        },
      },
      {
        className: "btn btn-primary buttons-pdf",
        title: "Export TEst",
        children: [
          <span>
            <i
              className="glyphicon glyphicon-print fa fa-print"
              aria-hidden="true"
            ></i>
          </span>,
        ],
        onClick: (event) => {
          console.log(event);
        },
        onDoubleClick: (event) => {
          console.log("doubleClick");
        },
      },
    ];

    this.config = {
      page_size: 25,
      show_first: false,
      show_info: false,
      show_last: false,
      show_filter: false,
      show_length_menu: false,
      show_pagination: true,
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }

  handleClose() {
    this.setState({ showModal: false })
  }

  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  };

  _onAction(e) {
    // console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {


    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {

    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;

    if (isTimedOut === false) {
      // console.log("the time is over");
      this.setState({ showModal: true })
      console.log(this.state.showModal);
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })

    }
    if (isTimedOut === true) {
      localStorage.removeItem("token");
      this.props.logout();
    }
  }

  handleInput = (e) => {
    let data = this.state.saleRegister;
    let errors = this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (

      name === "patient_name" ||
      name === "bill_no"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = ""
    }
    this.setState({ saleRegister: data, errors, searchErrorMessage: "" });
  };


  handleDatePickerChange = (date, field) => {
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    this.setState(prevState => ({
      purchaseEntryData: {
        ...prevState.purchaseEntryData,
        [field]: offsetDate, // Update the specific expiry date field
      },
    }));
  };

  handleInputChange = (index, key) => event => {
    let updatedData = [...this.state.records];
    updatedData[index][key] = event.target.value ? parseFloat(event.target.value) : '';
    this.setState({ records: updatedData }, () => {
      this.calculateSubTotal(index);
    });
  };

  calculateSubTotal = (index) => {
    let updatedData = [...this.state.records];
    let p_rate = updatedData[index].p_rate || 0;
    let quantity = updatedData[index].quantity || 0;
    let disc = updatedData[index].disc;
    updatedData[index].sub_total = quantity * (p_rate - ((disc / 100) * p_rate));

    this.setState({ records: updatedData }, () => {

      this.calculateTotal(index);
      this.calculateDiscountAmount(index);
      this.calculateIgstAmount(index);
      this.calculateCgstAmount(index);
      this.calculateSgstAmount(index);
    });
  };

  calculateTotal = (index) => {
    let updatedData = [...this.state.records];
    let sub_total = updatedData[index].sub_total || 0;
    let igst = updatedData[index].igst || 0;
    updatedData[index].total = sub_total + ((igst / 100) * sub_total);
    console.log(igst);
    console.log(updatedData[index].total);
    this.setState({ records: updatedData }, () => {
      this.calculateSection();
    });
  };

  calculateDiscountAmount = (index) => {
    let updatedData = [...this.state.records];
    let p_rate = updatedData[index].p_rate || 0;
    let quantity = updatedData[index].quantity || 0;
    let disc = updatedData[index].disc;
    updatedData[index].discAmt = ((quantity * p_rate) * (disc / 100));
    this.setState({ records: updatedData }, () => {
      this.calculateSection();

    });
  };
  calculateCgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let cgst = updatedData[index].cgst;
    let sub_total = updatedData[index].sub_total;
    updatedData[index].cgstAmt = (sub_total * (cgst / 100));
    this.setState({ records: updatedData }, () => {
      this.calculateSection();

    });
  };
  calculateSgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let sgst = updatedData[index].sgst;
    let sub_total = updatedData[index].sub_total;
    updatedData[index].sgstAmt = (sub_total * (sgst / 100));
    this.setState({ records: updatedData }, () => {
      this.calculateSection();

    });
  };
  calculateIgstAmount = (index) => {
    let updatedData = [...this.state.records];
    let igst = updatedData[index].igst;
    console.log(igst);
    let sub_total = updatedData[index].sub_total;
    updatedData[index].igstAmt = (sub_total * (igst / 100));
    this.setState({ records: updatedData }, () => {
      this.calculateSection();

    });
  };
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;
    let max = event.target.max;
    let value = event.target.value;
    if (
      event.target.name === "searchpharmacy_id"

    ) {
      if (value.length > max) {
        const value1 = value.slice(0, max);
        record.pharmacyid_error = "Maximum Length has exceeded.";
        record[event.target.name] = value1;
      }
      else {
        record.pharmacyid_error = "";
        record[event.target.name] = event.target.value;
      }
    }
    this.UpdateSearchColumnChange(record);
  };

  handleFromDOBChange = (date) => {
    let saleRegister = this.state.saleRegister;
    let errors = this.state.errors;


    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    saleRegister.FromDate = offsetDate;

    this.setState({ saleRegister: saleRegister, errors: errors, FromDate: offsetDate });
  };
  handleToDOBChange = (date) => {
    let saleRegister = this.state.saleRegister;
    let errors = this.state.errors;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    saleRegister.ToDate = offsetDate;
    //errors.Todate="";
    this.setState({ saleRegister: saleRegister, errors: errors, ToDate: offsetDate });
  };




  clearErrors = (errors) => {


    errors.address = ""
    errors.created_by = ""
    errors.voucher_no = ""
    errors.voucher_date = ""
    errors.invoice_date = ""
    errors.remarks = ""
    errors.vendorinvoice_no = ""
    errors.poref_no = ""
    return errors
  }

  cancel = () => {

    let data = this.state.purchaseEntryData;

    data.id = ""
    data.user_id = ""

    data.vendor_name = {
      label: "", value: ""
    }
    data.address = ""
    data.created_by = ""
    data.voucher_no = ""
    data.voucher_date = ""
    data.invoice_date = ""
    data.remarks = ""
    data.vendorinvoice_no = ""
    data.poref_no = ""


    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({
      purchaseEntryData: data, actions: "add", voucher_no: "", errors: errors, searchErrorMessage: "", records: [],
      search_action: false,
      totalAmtSec: {
        discAmt: 0,
        cgstAmt: 0,
        sgstAmt: 0,
        igstAmt: 0,
        total: 0,
        sub_total: 0,

      },

    });
  }

  clearimport = () => {
    this.setState({ file: "" });
  };

  clearMessage = () => {
    setTimeout(() => this.setState({
      formSuccess: { message: "", },
      tableSuccess: { message: "" }
    }), 5000);
  }


  calculateSection = () => {
    let records = this.state.records;
    let totalAmtSec = { ...this.state.totalAmtSec };

    let discAmt = 0.0;
    let cgstAmt = 0.0;
    let sgstAmt = 0.0;
    let igstAmt = 0.0;
    let total = 0.0;
    let sub_total = 0.0;

    console.log(records);

    records.forEach((data, i) => {

      if (data.discAmt !== undefined) {
        discAmt += Number(data.discAmt);
      }
      if (data.cgstAmt !== undefined) {
        cgstAmt += Number(data.cgstAmt);
      }
      if (data.sgstAmt !== undefined) {
        sgstAmt += Number(data.sgstAmt);
      }
      if (data.igstAmt !== undefined) {
        igstAmt += Number(data.igstAmt);
      }
      if (data.total !== undefined) {
        total += Number(data.total);
      }
      if (data.sub_total !== undefined) {
        sub_total += Number(data.sub_total); // Sum up the sub_total for all records
      }
    });

    totalAmtSec.discAmt = discAmt;
    totalAmtSec.cgstAmt = cgstAmt;
    totalAmtSec.sgstAmt = sgstAmt;
    totalAmtSec.igstAmt = igstAmt;
    totalAmtSec.total = total;
    totalAmtSec.sub_total = sub_total;
    totalAmtSec.totalAmtWords = converter.toWords(total) + " rupees only";

    this.setState({ totalAmtSec });

    console.log(totalAmtSec);
  };

  editRecord(record) {
    console.log(record);// return false;
    /*  let purchaseRegister = this.state.purchaseRegister;
     purchaseRegister.vendor_name = record.vendor_name;
     purchaseRegister.voucher_no = record.voucher_no;
     purchaseRegister.voucher_date = record.voucher_date;
     purchaseRegister.voucher_date = moment(record.voucher_date).toDate();
     purchaseRegister.vendorinvoice_no = record.vendorinvoice_no;
     purchaseRegister.sub_total = record.sub_total;
     purchaseRegister.total = record.total;
     purchaseRegister.disc = record.disc;
     purchaseRegister.user_id=this.props.userdata.id;
     console.log(purchaseRegister);
     
     this.setState({
       editRecord: record,
       purchaseRegister: purchaseRegister,
       actions: "edit",
     }); */

    this.props.history.push({
      pathname: "/viewsaleentryvoucher/" + record.bill_no,
    });
    this.props.history.replace({
      pathname: "/viewsaleentryvoucher/" + record.bill_no,
      state: {
        patient_name: record.patient_name,
        bill_no: record.bill_no,
        bill_date: record.bill_date,
        doctorreg_no: record.doctorreg_no,
        patient_name: record.patient_name,
        phone_number: record.phone_number,
        doctor_name: record.doctor_name,
        address: record.address,
        created_by: record.created_by,
        remarks: record.remarks,
        disc: record.disc,
        gstAmt: record.gstAmt,
        discAmt: record.discAmt,
        cgstAmt5: record.cgstAmt5,
        sgstAmt5: record.sgstAmt5,
        taxAmt5: record.taxAmt5,
        sub_total5: record.sub_total5,
        cgstAmt12: record.cgstAmt12,
        sgstAmt12: record.sgstAmt12,
        taxAmt12: record.taxAmt12,
        sub_total12: record.sub_total12,
        cgstAmt18: record.cgstAmt18,
        sgstAmt18: record.sgstAmt18,
        taxAmt18: record.taxAmt18,
        sub_total18: record.sub_total18,
        payment_mode: record.payment_mode,
      }
    });

  }

  viewRecord(record) {
    this.props.history.push({
      pathname: "/viewsaleentryvoucher/" + record.bill_no,
    });
    this.props.history.replace({
      pathname: "/viewsaleentryvoucher/" + record.bill_no,
      state: {
        patient_name: record.patient_name,
        bill_no: record.bill_no,
        bill_date: record.bill_date,
        doctorreg_no: record.doctorreg_no,
        patient_name: record.patient_name,
        disc: record.disc,
        gstAmt: record.gstAmt,
        phone_number: record.phone_number,
        doctor_name: record.doctor_name,
        address: record.address,
        created_by: record.created_by,
        remarks: record.remarks,
        discAmt: record.discAmt,
        payment_mode: record.payment_mode,
        cgstAmt: record.cgstAmt,
        sgstAmt: record.sgstAmt,
        cgstAmt5: record.cgstAmt5,
        sgstAmt5: record.sgstAmt5,
        taxAmt5: record.taxAmt5,
        sub_total5: record.sub_total5,
        cgstAmt12: record.cgstAmt12,
        sgstAmt12: record.sgstAmt12,
        taxAmt12: record.taxAmt12,
        sub_total12: record.sub_total12,
        cgstAmt18: record.cgstAmt18,
        sgstAmt18: record.sgstAmt18,
        taxAmt18: record.taxAmt18,
        sub_total18: record.sub_total18,
        view_page: true
      }
    });

  }
  refreshData = () => {

    let purchaseRegister = this.state.purchaseRegister;
    purchaseRegister = { FromDate: "", ToDate: "", vendorinvoice_no: "", vendor_name: "", errors: "" };
    this.setState({
      purchaseRegister: purchaseRegister
    });

    this.getAllPurchaseEntryList(this.props.userdata.id);


  };

  getAllPurchaseEntryList = (user_id) => {

    if (user_id !== "") {
      let reqdata = {
        user_id: this.props.userdata.id

      }
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getAllSalePatientList, reqdata, CONFIG)
        .then((list) => {
          let VendorList = list.data.data;
          let DataArr = [];
          VendorList.forEach((element, index) => {
            let purchaseData = {};
            purchaseData.s_no = index + 1;
            purchaseData.user_id = this.props.userdata.id;
            purchaseData.bill_no = element.bill_no;
            purchaseData.bill_date = element.bll_date;
            purchaseData.disc = element.disc;
            purchaseData.sub_total = element.sub_total;
            purchaseData.total = element.total;
            purchaseData.customer_type = element.customer_type;
            purchaseData.phone_number = element.phone_number;
            purchaseData.discAmt = element.discAmt;
            purchaseData.doctor_name = element.doctor_name;
            purchaseData.payment_mode = element.payment_mode;
            purchaseData.cgstAmt5 = element.cgstAmt5;
            purchaseData.sgstAmt5 = element.sgstAmt5;
            purchaseData.taxAmt5 = element.taxAmt5;
            purchaseData.sub_total5 = element.sub_total5;
            purchaseData.cgstAmt12 = element.cgstAmt12;
            purchaseData.sgstAmt12 = element.sgstAmt12;
            purchaseData.taxAmt12 = element.taxAmt12;
            purchaseData.sub_total12 = element.sub_total12;
            purchaseData.cgstAmt18 = element.cgstAmt18;
            purchaseData.sgstAmt18 = element.sgstAmt18;
            purchaseData.taxAmt18 = element.taxAmt18;
            purchaseData.sub_total18 = element.sub_total18;
            DataArr[index] = purchaseData;
          });

          this.setState({ records: DataArr });
        })
        .catch(() => { });




    }
  }

  searchsaleRegister() {
    let saleRegister = this.state.saleRegister;

    let data = {};
    let errors = this.state;
    data.patient_name = saleRegister.patient_name ? saleRegister.patient_name.label : "";
    data.FromDate = saleRegister.FromDate;
    data.ToDate = saleRegister.ToDate;
    data.bill_no = saleRegister.bill_no;
    data.user_id = this.props.userdata.id;



    console.log(data);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      console.log(data);
      axios.post(searchSaleEntries, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data.data;
        console.log(result);
        if (result.length > 0) {
          // let records = result.data;

          let reportList = [];
          result.forEach((element, index) => {
            let report = {};
            report.s_no = index + 1;
            report.bill_no = element.bill_no;
            report.bill_date = element.bill_date;
            report.patient_name = element.patient_name;
            report.gstAmt = element.gstAmt;
            report.disc = element.disc;
            report.sub_total = element.sub_total;
            report.total = element.total;
            report.customer_type = element.customer_type;
            reportList[index] = report;
          });
          this.setState({ reportList: reportList, search_action: true });
        } else {
          this.setState({ reportList: [], search_action: true });
        }
      });
    });
    // }
  }


  createinvoice() {
    document.location.href = "/saleentryvoucher";
  }
  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { purchaseEntryData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    console.log(place);
    purchaseEntryData.address = place.formatted_address;


    this.setState({
      purchaseEntryData,
    });
  }
  componentDidMount = () => {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);



    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllSalePatientList, { user_id: this.props.userdata.id }, CONFIG)
      .then(({ data }) => {
        const mastervendorNameList = data.data;
        const uniqueDrugNames = new Set();
        const mastervendorNames = mastervendorNameList
          .filter((element) => {

            if (!uniqueDrugNames.has(element.patient_name)) {
              uniqueDrugNames.add(element.patient_name);
              return true;
            }
            return false;
          })
          .map((element) => ({
            value: element.patient_name,
            label: element.patient_name,

          }));

        this.setState({ mastervendorNames });
      })
      .catch((error) => {
        console.error("Error fetching drug data:", error);

      });

    axios
      .post(getPharmacyShortDetail, { user_id: this.props.userdata.id }, CONFIG)
      .then((Respons) => {
        let data = Respons.data.data[0];
        console.log(data.state);

        if (data.status == "1")
          this.setState({
            State_value: data.state

          });
      }).catch((e) => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllSalePatientList, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let VendorList = list.data.data;
        let DataArr = [];
        VendorList.forEach((element, index) => {
          let purchaseData = {};
          purchaseData.s_no = index + 1;
          purchaseData.user_id = this.props.userdata.id;
          purchaseData.bill_no = element.bill_no;
          purchaseData.bill_date = element.bill_date;
          purchaseData.patient_name = element.patient_name;
          purchaseData.customer_type = element.customer_type;
          purchaseData.gstAmt = element.gstAmt;
          purchaseData.disc = element.disc;
          purchaseData.sub_total = element.sub_total;
          purchaseData.total = element.total;
          purchaseData.phone_number = element.phone_number;
          purchaseData.discAmt = element.discAmt;
          purchaseData.cgstAmt5 = element.cgstAmt5;
          purchaseData.sgstAmt5 = element.sgstAmt5;
          purchaseData.taxAmt5 = element.taxAmt5;
          purchaseData.sub_total5 = element.sub_total5;
          purchaseData.cgstAmt12 = element.cgstAmt12;
          purchaseData.sgstAmt12 = element.sgstAmt12;
          purchaseData.taxAmt12 = element.taxAmt12;
          purchaseData.sub_total12 = element.sub_total12;
          purchaseData.cgstAmt18 = element.cgstAmt18;
          purchaseData.sgstAmt18 = element.sgstAmt18;
          purchaseData.taxAmt18 = element.taxAmt18;
          purchaseData.sub_total18 = element.sub_total18;
          purchaseData.doctor_name = element.doctor_name;
          purchaseData.payment_mode = element.payment_mode;
          DataArr[index] = purchaseData;
        });

        this.setState({ records: DataArr });
      })
      .catch(() => { });



  };
  onDeleteClick(record) {
    this.setState({ addPopup: true, selectedRecord: record });

  }

  handleDeleteVoucher = (record) => {
    console.log(record);
    const { bill_date } = record;
    const currentDate = new Date();
    const BillDate = new Date(bill_date);
    console.log(record.bill_date);
    // Calculate the difference in days
    // const timeDiff = Math.abs(currentDate.getTime() - voucherDate.getTime()); 
    // const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
    // Check if the voucher date is within 30 days or within the current month 
    if (BillDate.getMonth() !== currentDate.getMonth()) {
      this.setState({ updatePopup: true });
      return;
    }
    // Proceed with the update if validation passes 
    this.deleteRecord(record);
  };
  deleteRecord(record) {

    let userId = record.id;
    let bill_no = record.bill_no;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(deleteSaleByBillnumber, { bill_no: bill_no }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records
            .map((item) => item.id)
            .indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "" });
        toast.error("The Sale has been deleted successfully.", {
          className: "toast-error"
        });
        //  this.setState({ tableSuccess: res.data });
        this.clearMessage();
        this.cancel();
      })
      .catch((e) => { });
    let reqdata = {
      user_id: this.props.userdata.id

    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllSalePatientList, reqdata, CONFIG)
      .then((list) => {
        let VendorList = list.data.data;
        let DataArr = [];
        VendorList.forEach((element, index) => {
          let purchaseData = {};
          purchaseData.s_no = index + 1;
          purchaseData.user_id = this.props.userdata.id;
          purchaseData.bill_no = element.bill_no;
          purchaseData.bill_date = element.bll_date;
          purchaseData.disc = element.disc;
          purchaseData.sub_total = element.sub_total;
          purchaseData.total = element.total;
          purchaseData.customer_type = element.customer_type;
          purchaseData.phone_number = element.phone_number;
          purchaseData.discAmt = element.discAmt;
          purchaseData.cgstAmt5 = element.cgstAmt5;
          purchaseData.sgstAmt5 = element.sgstAmt5;
          purchaseData.taxAmt5 = element.taxAmt5;
          purchaseData.sub_total5 = element.sub_total5;
          purchaseData.cgstAmt12 = element.cgstAmt12;
          purchaseData.sgstAmt12 = element.sgstAmt12;
          purchaseData.taxAmt12 = element.taxAmt12;
          purchaseData.sub_total12 = element.sub_total12;
          purchaseData.cgstAmt18 = element.cgstAmt18;
          purchaseData.sgstAmt18 = element.sgstAmt18;
          purchaseData.taxAmt18 = element.taxAmt18;
          purchaseData.sub_total18 = element.sub_total18;
          purchaseData.doctor_name = element.doctor_name;
          purchaseData.payment_mode = element.payment_mode;
          DataArr[index] = purchaseData;
        });

        this.setState({ records: DataArr });
      })
      .catch(() => { });

  }
  /*  handleselectChange = (index, key) => selectedOption => {
    
     // Create a copy of the current table data
     let updatedData = [...this.state.records];
     
     // Update the specific row's key with the selected option value
     updatedData[index][key] = selectedOption ? selectedOption.label : "";
 
     // Set the state with the updated data
     this.setState({ records: updatedData });
 
     axios
     .post(getitemlistforPharmacyDrug,{user_id:this.props.userdata.id,drug_name:selectedOption.label}, CONFIG)
     .then((drugRespons) => {
       let ItemList = drugRespons.data.data;
     
       ItemList.forEach((element, index) => {
         ItemList.push({
          
           pack:element.pack,
           cgst: element.cgst,
           sgst:element.sgst,
           igst:element.igst
           
         });
         let records = this.state.records;
         var recIndex = findWithAttr(records, "s_no", record.s_no);
         if (null != record) {
         
           records[recIndex].pack = element.unit_of_measure;
           records[recIndex].cgst = element.cgst + " %";
           records[recIndex].gst_amount =
             (record.tax_percentage / 100) * record.amount;
           records[recIndex].total_amount =
             Number(records[recIndex].fee) +
             Number(records[recIndex].gst_amount);
         } else {
          
           records[recIndex].fee = "";
           records[recIndex].gst_rate = "";
           records[recIndex].gst_amount = "";
           records[recIndex].total_amount = "";
         }
   
         console.log(recIndex);
         console.log(records.length - 1);
         if (recIndex === records.length - 1) {
           let default_rec = Object.assign({}, record);
           default_rec.s_no = records.length + 1;
           records.push(default_rec);
         }
   
       });
      
     })
        
       
 
   
 
 
   }; */

  /*   handleselectChange = (index, key) => selectedOption => {
      // Create a copy of the current table data
      let updatedData = [...this.state.records];
      
      // Update the specific row's key with the selected option value
      updatedData[index][key] = selectedOption ? selectedOption.label : "";
  
      // Set the state with the updated data
      this.setState({ records: updatedData });
  
       axios
      .post(getPharmacyShortDetail,{user_id:this.props.userdata.id}, CONFIG)
      .then((Respons) => {
        let data = Respons.data.data[0];
        console.log(data.state);
     
        if (data.status == "1")
          this.setState({
         State_value:data.state
  
          });
      }).catch((e) => { });
    
  
      // Post request to fetch item details
      axios
      .post(getitemlistforPharmacyDrug, { user_id: this.props.userdata.id, drug_name: selectedOption.label }, CONFIG)
      .then((drugResponse) => {
          let itemList = drugResponse.data.data;
          console.log(itemList); // Verify the API response
  
          // Ensure the API response contains the `pack` value
          let item = itemList.find(item => item.drug_name === selectedOption.label);
          if (item) {
              // Update the specific row with additional details
              updatedData[index] = {
                  ...updatedData[index],
                  pack: item.unit_of_measure,
                  cgst: this.state.State_value === this.state.gst_state ? item.cgst + " %" : "",
                  sgst: this.state.State_value === this.state.gst_state ? item.sgst + " %" : "",
                  igst: item.igst + " %",
                  sub_total:updatedData[index].quantity*(updatedData[index].p_rate-(updatedData[index].disc*updatedData[index].p_rate)),
                 // gst_amount: (updatedData[index].tax_percentage / 100) * updatedData[index].amount,
                //  total_amount: Number(updatedData[index].fee) + Number(updatedData[index].gst_amount)
              };
              console.log(updatedData[index]); // Verify the updated record
          }
  
          // Set the state with the updated records
          this.setState({ records: updatedData });
      })
      .catch((error) => {
          console.error("Error fetching item details:", error);
      });
  }; */

  /*   handleselectChange = (index, key) => selectedOption => {
      // Create a copy of the current table data
      let updatedData = [...this.state.records];
      
      // Update the specific row's key with the selected option value
      updatedData[index][key] = selectedOption ? selectedOption.label : "";
  
      // Set the state with the updated data
      this.setState({ records: updatedData });
  
      // Fetch the state value
      axios
      .post(getPharmacyShortDetail, { user_id: this.props.userdata.id }, CONFIG)
      .then((response) => {
          let data = response.data.data[0];
        
          if (response.data.status == "1") {
              this.setState({ State_value: data.state }, () => {
                  // Fetch item details after state is updated
                    console.log(this.state.gst_state);
  
                  axios
                  .post(getitemlistforPharmacyDrug, { user_id: this.props.userdata.id, drug_name: selectedOption.label }, CONFIG)
                  .then((drugResponse) => {
                      let itemList = drugResponse.data.data;
                      console.log(itemList); // Verify the API response
  
                      // Ensure the API response contains the `pack` value
                      let item = itemList.find(item => item.drug_name === selectedOption.label);
                      if (item) {
                          // Update the specific row with additional details
                          updatedData[index] = {
                              ...updatedData[index],
                              pack: item.unit_of_measure,
                              cgst: this.state.State_value === this.state.gst_state ? item.cgst + " %" : "",
                              sgst: this.state.State_value === this.state.gst_state ? item.sgst + " %" : "",
                              igst: item.igst + " %",
                              sub_total: updatedData[index].quantity * (updatedData[index].p_rate - (updatedData[index].disc * updatedData[index].p_rate)),
                          };
                          console.log(updatedData[index]); // Verify the updated record
                      }
  
                      // Set the state with the updated records
                      this.setState({ records: updatedData });
                  })
                  .catch((error) => {
                      console.error("Error fetching item details:", error);
                  });
              });
          }
      })
      .catch((error) => {
          console.error("Error fetching state details:", error);
      });
  }; */

  handleselectChange = (index, key) => selectedOption => {
    // Create a copy of the current table data
    let updatedData = [...this.state.records];

    // Update the specific row's key with the selected option value
    updatedData[index][key] = selectedOption ? selectedOption.label : "";

    // Set the state with the updated data
    this.setState({ records: updatedData });

    // Fetch the state value
    axios
      .post(getPharmacyShortDetail, { user_id: this.props.userdata.id }, CONFIG)
      .then((response) => {
        let data = response.data.data[0];


        if (response.data.status === "1") {
          this.setState({ State_value: data.state }, () => {
            // Fetch item details after state is updated
            console.log("State : ", this.state.State_value);
            console.log("GST State: ", this.state.gst_state);
            this.fetchItemDetails(index, selectedOption);
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching state details:", error);
      });
  };

  fetchItemDetails = (index, selectedOption) => {
    axios
      .post(getitemlistforPharmacyDrug, { user_id: this.props.userdata.id, drug_name: selectedOption.label }, CONFIG)
      .then((drugResponse) => {
        let itemList = drugResponse.data.data;
        console.log("Fetched Item List: ", itemList); // Verify the API response

        // Ensure the API response contains the `pack` value
        let item = itemList.find(item => item.drug_name === selectedOption.label);
        if (item) {
          // Create a copy of the current table data again
          let updatedData = [...this.state.records];

          // Update the specific row with additional details
          updatedData[index] = {
            ...updatedData[index],
            pack: item.unit_of_measure,
            cgst: this.state.State_value === this.state.gst_state ? item.cgst : "",
            sgst: this.state.State_value === this.state.gst_state ? item.sgst : "",
            igst: this.state.State_value !== this.state.gst_state ? item.igst : "",
            sub_total: updatedData[index].quantity * updatedData[index].p_rate,
          };
          console.log("Updated Record: ", updatedData[index]); // Verify the updated record
          console.log(updatedData[index].quantity);
          // Set the state with the updated records
          this.setState({ records: updatedData });

        }
        this.setState({ record }, () => {
          this.calculateSection();
        });
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };



  validateMaxLength(name, value, max) {
    let data = this.state.saleRegister;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ saleRegister: data, errors: errors });
  }


  onFileChange = event => {

    // Update the state
    this.setState({ file: event.target.files[0] });
    readXlsxFile(event.target.files[0]).then((rows) => {
      console.log(rows);
      console.log("rows");
      this.setState({ pharmacyData: rows });
      // `rows` is an array of rows
      // each row being an array of cells.
    })

  };


  handlepatientselectChange = async (selectedOptions, e) => {
    let data = this.state.saleRegister;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;


    this.setState({ saleRegister: data, errors, searchErrorMessage: "" });

    /*   axios
      .post(getgststatebyVendorname,{user_id:this.props.userdata.id,vendor_name:selectedOptions.label}, CONFIG)
      .then((Respons) => {
        let resdata = Respons.data;
        let data=resdata.data[0];
        console.log(resdata.status);
      
          if (resdata.status == "1") 
          {
           this.setState({ gst_state: data.gst_state }, () => {
            console.log("Updated GST State: ", this.state.gst_state); }); }
              
      }).catch((e) => { }); */

  }
  PopupConfirm = () => {
    this.handleDeleteVoucher(this.state.selectedRecord);
    this.setState({ addPopup: false, selectedRecord: null });
  };
  render() {

    let PopupClose = () => this.setState({ addPopup: false, selectedRecord: null, updatePopup: false });
    // let PopupConfirm = () => this.handleDeleteVoucher(record);
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup: true })

    };
    const { saleRegister, records, errors, formSuccess, tableSuccess, hospitaltimeout, mastervendorNames, gst_state, State_value, searchErrorMessage, searchTableSuccess, loading } = this.state;
    return (

      <div className="col-sm-12">
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />
        <div className="main-content bacfot">
          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <BusinessUserSideBar />
              </div>
              {
                (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                  <div className="container-fluid">
                    <div className="checkout-tabs">

                      <div className="row">
                        <div className="col-sm-12 pr0">
                          <div className="card">
                            <div className="card-body a-page-1-hight-right ">
                              {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                              <Popupinfo
                                show={!this.state.hospitalpopup}
                                onHide={PopupHospitalClose}
                                title={"Alert!!"}
                                message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>) :
                  (


                    <section className="main-body hospital-profilegap">
                      <div className="container-fluid">
                        <div className="row checkout-tabs">
                          <div className="col-sm-12">
                            <h4 className="font-weight-bold"> Stock and Sales Report </h4>
                          </div>
                          <div className="col-sm-12 pr0">
                            <div className="card b-12-hite">
                              <div class="card-header">
                                <div class="card-title text-center fs-2">
                                  Stock and Sales Report
<div className="row outer-container">
<div className="">

<div className="form-group ">

  <div className="input-group">
    <div className="required mr-3 mt-1 ">
      {/* <label className=""><b>Search By: </b></label> */}
      <label>Period</label>
    </div>
    <DatePicker
      className="form-control"
      value={saleRegister.FromDate}
      format="dd-MM-yyyy"
      onChange={(date) =>
        this.handleFromDOBChange(date)
      }
      yearPlaceholder='YYYY'
      monthPlaceholder='MM'
      dayPlaceholder='DD'
    />
  </div>
</div>
</div>
<div className="">

<div className="form-group">

  <div className="input-group ">
    <div className="required ml-3 mr-3 mt-1">
      <label>to</label>
    </div>

    <DatePicker
      className="form-control"
      value={saleRegister.ToDate}
      minDate={this.state.from}
      format="dd-MM-yyyy"
      onChange={(date) =>
        this.handleToDOBChange(date)
      }
      yearPlaceholder='YYYY'
      monthPlaceholder='MM'
      dayPlaceholder='DD'
    />
  </div>
</div>
</div>
</div>
                                </div>

                              </div>
                              <div className="card-body">
                                <div className="user-notification">
                                  <SpanField
                                    classname={(0 == formSuccess.status) ? "text-danger user-notification" :
                                      "text-success user-notification"}
                                    title={formSuccess.message}
                                  />
                                </div>
                                <div>
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="ShiftPlanner">
                                        <div className="d-flex flex-wrap">

                                        

                                          <div className="">

                                            <div className="form-group">

                                              <div className="input-group">
                                                <div className="required ml-3 mr-3 mt-1">

                                                  <label>Category</label>
                                                </div>
                                                <Select maxMenuHeight={120}
                                                  className="selectcls p-0"
                                                  name="patient_name"
                                                  isMandatory={true}
                                                  value={saleRegister.patient_name}
                                                  onChange={this.handlepatientselectChange}
                                                  options={mastervendorNames}
                                                />
                                                <div className="required ml-3 mr-3 mt-1">

                                                  <label>Drug Name</label>
                                                </div>
                                                <InputField
                                                  inputType={"text"}
                                                  isMandatory={true}
                                                  name={"bill_no"}
                                                  controlFunc={this.handleInput.bind(
                                                    this
                                                  )}
                                                  value={saleRegister.bill_no}
                                                  placeholder={""}
                                                  max={10}

                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={errors.bill_no}
                                                />
                                                <div className="required ml-3 mr-3 mt-1">

                                                  <label>Mfg Name</label>
                                                </div>
                                                <InputField
                                                  inputType={"text"}
                                                  isMandatory={true}
                                                  name={"bill_no"}
                                                  controlFunc={this.handleInput.bind(
                                                    this
                                                  )}
                                                  value={saleRegister.bill_no}
                                                  placeholder={""}
                                                  max={10}

                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={errors.bill_no}
                                                />
                                              </div>

                                            </div>

                                          </div>

                                          <div className="ml-3">
                                            <button
                                              type="button"
                                              style={{ width: 110 }}
                                              className="signupbtn float-right"
                                              onClick={this.searchsaleRegister.bind(this)}
                                            >

                                              &nbsp;Generate
                                            </button>
                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >


                                    <div
                                      className="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div className="user-notification">
                                        <SpanField
                                          classname={
                                            "0" === tableSuccess.status
                                              ? "text-danger user-notification"
                                              : "text-success user-notification"
                                          }
                                          title={tableSuccess.message}
                                        />
                                      </div>


                                      <ReactDatatable
                                        className="saleregistertable table-bordered table-striped kas"
                                        config={this.config}
                                        records={this.state.reportList.length > 0 ? this.state.reportList : this.state.records}
                                        columns={this.columns}
                                      />



                                      {/* <div className="float-right">
                                  <table className="table table-bordered table-striped kas mobile_table">
          <tfoot>
            <tr>
            <td><input type="text" placeholder="Enter Sub Total" /></td>
              <td><input type="text" placeholder="Enter Discount" /></td>
              <td><input type="text" placeholder="Enter GST Amount" /></td>
              <td><input type="text" placeholder="Enter Net Amount" /></td>
            </tr>
          </tfoot>
        </table>
        </div> */}
                                      <Popup
                                        show={this.state.addPopup}
                                        onHide={PopupClose}
                                        onConfirm={this.PopupConfirm}
                                        title={"Confirmation"}
                                        message={
                                          "Are you sure you want to delete this record ?"
                                        }
                                      />
                                      <Popupinfo
                                        show={this.state.updatePopup}
                                        onHide={PopupClose}
                                        title={"Warning"}
                                        message={
                                          "Voucher date has crossed 30 days or is not within the current month."
                                        }
                                      />
                                      {/*   <Popup
                                        show={this.state.deletePopup}
                                        onHide={() =>
                                          this.setState({
                                            deletePopup: false,
                                          })
                                        }
                                        onConfirm={() =>
                                          this.deleteRecord(this.state.record)
                                        }
                                        title={"Confirmation"}
                                        message={
                                          "Are you sure you want to delete this record?"
                                        }
                                      /> */}
                                    </div>
                                  </div>

                                </div>
                              </div>






                            </div>
                          </div>

                        </div>
                      </div>
                    </section>)}
              <FooterSection />

            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockandSalesReport));

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
