import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
// import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
// import BusinessUserSideBar from "../components/container/SideBar";
// import ClientSideBar from "../components/container/layout/client/SideBar";
// import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';

import Headerweb from './Headerweb';
import FooterWeb from './Footerweb';
import Topmove from './Topmove';
import WebdoctorSlider from './WebdoctorSlider';


function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class DoctorManagment extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
            <div className="layout-wrapper">

                <div className="main-content">
                    {/* <section className="my-head"> */}
                        {/* <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div> */}
                    {/* </section> */}
                    <div className="mainpage">
                        {/* <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div> */}

                        {/* <section className="main-body hospital-profilegap "> */}
                            {/* <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            /> */}

                            {/* <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} /> */}

                            {/* <div className="container-fluid checkout-tabs"> */}
                                {/* <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                /> */}

                                {/* <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} /> */}

<div className='product-section'>
    <Headerweb/>
  
    
 
            <hr className='m-0' style={{ color: '#D9F4FF' }} />
            <section className='pt-5'>
           
            <div className='container-90' id='increase_revenue' style={{ marginTop: '50px' }}>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-6 col-12 slide-left visible'>
            <h1>Doctor Management <br/> System</h1>
        </div>
        <div className='col-lg-6 col-12'>
            <p>A Clinical Management System (CMS) is a software solution designed to streamline and enhance the administrative and clinical functions within healthcare facilities. It plays a crucial role in managing patient information, improving efficiency, ensuring regulatory compliance, and enhancing overall patient care. Here are some key components and features typically found in a Clinical Management System</p>
        </div>
    </div>
</div>
                <div className=''>
                <div className='' id='increase_productivity' style={{ marginTop: '50px' }}>
    <div className='row m-0'>
        <div className='col-12'>
            <img src={require("../../src/assets/images/website/Doctormang1.png")} className='w-100 h-auto' alt="Increase Productivity" />
        </div>
    </div>
</div>
</div>
<div className='container-80 mt-5' id='increase_servicequality'>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-12 col-12 slide-right visible'>
           
            <p>A doctor portal is designed based on the physician's specialty and can work for any specialization. It contains information about their education qualification, specialization area, physicians associated with the hospital, and the working hours and consultation mode (Token Management System, Appointment Management System & Tele-consultation)</p>
            <p>Our Clinical Management System is designed to manage the physician schedule based on availability, as the KlinicQ application is a cloud-based solution (AWS - amazon web services) so that physicians can access the data allocated to the hospital from anywhere.
            </p>
            <p>As the consultation time varies for each patient, the KlinicQ application's token management system considers the takt time for calculating appointment time instead of the cycle time. Each hospital/Clinic can set its cycle time in the dashboard based on specialty and previous consultation history.</p>
            <p>Clinical Management System is an integrated application. Physicians can send follow-up actions, instructions to the nurses and admin staff, and prescriptions to the pharmacy directly. Bi-directional communication is enabled between the laboratory and hospital for sending requests and getting the lab results. Also, follow-up appointment reminders can be sent to the patients, enhancing customer engagement. </p>
        </div>
    </div>
</div>
                <div className=''>
                <div className='container-90' id='improve_patientsatisfaction'>
    <div className='row m-0 mt-5'>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Doctormang2.png")} className='w-100 h-65' alt="Benefit 1" />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Doctormang3.png")} className='w-100 h-65' alt="Benefit 2" />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={require("../../src/assets/images/website/Doctormang4.png")} className='w-100 h-65' alt="Benefit 3" />
        </div>
    </div>
</div>
                </div>
               
                <div className='container-80' id='increase_servicequality'>
    <div className='row m-0 align-items-center'>
        <div className='col-lg-12 col-12 slide-right visible'>
           
            <p>Analytical insights are extremely effective and can be used to grow your business. Our Clinical Management System has been designed with various KPI reports and trends based on average waiting times, consulting time, clinical records, pharmacy & laboratory records, etc., to do analysis and improve it. The analytics can help to optimize the business process to make critical decisions.</p>
            <p>As the consultation time varies for each patient, the KlinicQ application's token management system considers the takt time for calculating appointment time instead of the cycle time. Each hospital/Clinic can set its cycle time in the dashboard based on specialty and previous consultation history.</p>
            <p>KlinicQ application works with walk-in appointments, mobile app check-in & teleconsultation. The application can send referrals to other physicians and the prescription to the selected pharmacy. Hospitals/Clinics can add their pharmacy/ laboratory associated with them to send the prescription directly.</p>
            <p>In the Check-in process, the staff could update the patient's vital details, the reason for the visit, and medical condition; then, the physician can review the details for each patient, and it is easy to update the diagnosis details with few clicks. The KlinicQ application database has thousands of medicines for physicians to prescribe easily. Also, the clinic can add their specific medicine records with simple drug IDs for easy selection.</p>
            <p>Patients can view their medical records, previous prescriptions, discharge summaries, and vital details of their consultation history digitally. Physicians can send follow-up reminders to patients for upcoming visits, and the payment gateway is enabled for online payments.</p>
        </div>
    </div>
</div>
<div className='logo-slider container-90 slide-right visible'>
          <WebdoctorSlider />
        
        </div>
              
            </section>
                        <Topmove />
                        <FooterWeb className="qrCodeFooter" />
                    </div>
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount = () => {
       /*  console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DoctorManagment);