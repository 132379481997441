import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/mystyle.css';

class Topmove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const scrollY = window.scrollY;
        const threshold = 10; // You can adjust this value based on when you want the button to appear

        if (scrollY > threshold) {
            this.setState({ isVisible: true });
        } else {
            this.setState({ isVisible: false });
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    render() {
        return (
            <div>
                {this.state.isVisible && (
                    <button onClick={this.scrollToTop} style={{ position: 'fixed', bottom: '20px', right: '30px' }} className='top-btn'>
                        {/* <FontAwesomeIcon icon={faArrowCircleUp} bounce style={{ color: '#FFF' }} /> */}
                        <i class="fa-solid fa-angles-up fa-2xl" style={{ color: '#FFF' }}></i>
                    </button>
                )}
            </div>
        );
    }
}

export default Topmove;