import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';


function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class Createlogin2 extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

        

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
            <div className="main-content bacfot">

                <div className="mainpage">
                   
                    <div className="main-box">   

                          {/* <div className='d-lg-none d-block'>
                <div className='login-section'>
                    <div className='container-fuild'>
                        <div className='row m-0 justify-content-center'>
                            <div className='col-11'>
                                <div className='row gx-0'>
                                    <div className='col-12'>
                                        <div className='login-left form-wrapper'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <img src={require("../../src/assets/images/website/Logo.png")} />
                                                    <h4>Login in to your account</h4>
                                                    <p>Welcome back! Select method to login.</p>
                                                    <form class="row g-3">
                                                        <div class='full-input col-12'>
                                                            <div className='row align-items-center'>
                                                             
                                                                <div className='col-12'>
                                                                <div className='form-group has-error has-danger'> */}
                                                                {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                    {/* <input type='email' placeholder='Email Address' className='form-control p-1 ' />
                                                                </div>
                                                       
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='full-input col-12'>
                                                            <div className='row align-items-center'>
                                                              
                                                                <div className='col-12'>
                                                                <div className='form-group has-error has-danger'> */}
                                                                {/* <FontAwesomeIcon icon={faLock} /> */}
                                                                    {/* <input type='password' placeholder='Password' className='form-control p-1 ' />
                                                                </div>
                                                          
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-md-6 col-sm-6 col-12 p-0'>
                                                                <div class="form-check remember">
                                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    <label class="form-check-label" for="flexCheckDefault">
                                                                        Remember me
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6 col-sm-6 col-12 mt-md-0 mt-sm-0 mt-2 text-md-end text-sm-end text-start p-0'>
                                                                <label className='forget'>Forgot Password ?</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <button type="button" className="btn-ghost" >Login</button> */}
                                                            {/* <button type="button" className="" onClick={userLogin}>Login</button> */}
                                                        {/* </div>
                                                        <div className='text-center'>
                                                            <span className='new-account'>Don’t have an account? </span>
                                                            <span className='forget click-btn' >Create an account</span> */}
                                                            {/* <span className='forget click-btn' onClick={createUser}>Create an account</span> */}
                                                        {/* </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    */}

<div className='d-lg-block d-none'>
    <div className='login-section'>
        <div className='container'> 
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row gx-0'>
                        {/* Image on the left */}
                        <div className='col-lg-6 col-12 left-side'>
                            <div className='login-right text-center'>
                                <img 
                                    src={require("../../src/assets/images/website/Group8.png")} 
                                    className='pt-lg-5 pt-0 img-fluid' 
                                    style={{ width: '100%', height: 'auto', maxHeight: '600px' }}
                                />
                            </div>
                        </div>
                        {/* Form on the right */}
                        <div className='col-lg-6 col-12 right-side'>
                            <div className='login-left form-wrapper'>
                                <div className='row justify-content-start'>
                                    <div className='col-12'>
                                        <div className="progress-container">
                                            <div className="progress-step active" style={{ backgroundColor: '#007bff' }}></div>
                                            <div className="progress-bar" style={{ backgroundColor: '#007bff' }}></div>
                                            <div className="progress-step" style={{ backgroundColor: '#ccc' }}></div>
                                        </div>

                                        <div className="progress-content-container">
                                            <div className="progress-content left">
                                                <h5>Personal Info</h5>
                                                <p>Explain your step here.</p>
                                            </div>

                                            <div className="progress-content right">
                                                <h5>Business Info</h5>
                                                <p>Explain your step here:</p>
                                            </div>
                                        </div>       
                                        <form className="row g-3">
                                            {/* Business Category */}
                                            <div className='full-input col-12 mt-1'>
                                                <div className='form-group'>
                                                    <label htmlFor='business-category' className='form-label' style={{fontSize: '16px'}}>Business Category</label>
                                                    <input 
                                                        type='text' 
                                                        id='business-category' 
                                                        placeholder='Select Your Business Category' 
                                                        className='form-control p-3' 
                                                        style={{ width: '100%' }} 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            {/* Business Name */}
                                            <div className='full-input col-12 mt-1'>
                                                <div className='form-group'>
                                                    <label htmlFor='business-name' className='form-label' style={{fontSize: '16px'}}>Business Name</label>
                                                    <input 
                                                        type='text' 
                                                        id='business-name' 
                                                        placeholder='Enter Your Business Name' 
                                                        className='form-control p-3' 
                                                        style={{ width: '100%' }} 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            {/* Country */}
                                            <div className='full-input col-12 mt-1'>
                                                <div className='form-group'>
                                                    <label htmlFor='country' className='form-label' style={{fontSize: '16px'}}>Country</label>
                                                    <input 
                                                        type='text' 
                                                        id='country' 
                                                        placeholder='Country' 
                                                        className='form-control p-3' 
                                                        style={{ width: '100%' }} 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            {/* Address */}
                                            <div className='full-input col-12 mt-1'>
                                                <div className='form-group'>
                                                    <label htmlFor='address' className='form-label' style={{fontSize: '16px'}}>Address</label>
                                                    <input 
                                                        type='text' 
                                                        id='address' 
                                                        placeholder='Address' 
                                                        className='form-control p-3' 
                                                        style={{ width: '100%' }} 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            {/* Terms and Conditions Checkbox */}
                                            <div className='full-input col-12'>
                                                <div className='form-group'>
                                                    <input type="checkbox" id="terms" required />
                                                    <label htmlFor="terms" className='form-label' style={{fontSize: '14px', marginLeft: '8px'}}>I agree to our terms and conditions and privacy policy</label>
                                                </div>
                                            </div>

                                            {/* Opt-in for Offers Checkbox */}
                                            <div className='full-input col-12 '>
                                                <div className='form-group'>
                                                    <input type="checkbox" id="offers"/>
                                                    
                                                    <label htmlFor="offers" className='form-label' style={{fontSize: '14px', marginLeft: '8px'}}>Opt-in for relevant offers and promotions from KlinicQ</label>
                                                </div>
                                            </div>

                                            <div className="col-12 d-flex justify-content-between align-items-center ">
                                                <div className='text-start'> 
                                                    <span className='new-account'>Already have an account? </span>
                                                    <a href='/testlogin' className='forget click-btn' style={{color: 'black'}}>Log in</a>
                                                    {/* <span className='forget click-btn' style={{color: 'black'}}>Log in</span>  */}
                                                </div>
                                                <button type="submit" className="btn login-button">Finish</button> 
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
          

                        {/* <FooterSection className="qrCodeFooter" /> */}
                    </div>

                </div>
            </div>
        );
    }
    componentDidMount = () => {
        console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { });
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Createlogin2);