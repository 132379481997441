import React, { Component } from 'react';

// Import other necessary icons and assets here

class Webblog extends Component {
  render() {
    return (
      <div>
        <hr className='m-0' style={{ background: '#D3F2FF' }} />
        <div className='hospital-details mg-bt py-5'>
          <div className='container col-lg-11'>
           
            <div className='d-flex justify-content-between align-items-center mb-5'>
              <div>
                 <h5 style={{ color: '#0C5B98', textAlign: 'left' }}>Our Blog</h5>
                <h1 style={{ color: '#0C101B', textAlign: 'left', fontSize: '42px' }}>Health Insight and Updates</h1>
                
              </div>
              <div className='d-flex'>
                <div className='mx-2'>
                  <div className='arrow-button' 
                       style={{ backgroundColor: '#D3D3D3', padding: '15px', borderRadius: '30px' }} 
                       onClick={this.handleLeftClick}
                       aria-label="Previous">
                    <img src={require("../../src/assets/images/website/Frame1.png")} className='icons' alt='Left Arrow' />
                  </div>
                </div>
                <div>
                  <div className='arrow-button' 
                       style={{ backgroundColor: '#D3D3D3', padding: '15px', borderRadius: '30px' }} 
                       onClick={this.handleRightClick}
                       aria-label="Next">
                    <img src={require("../../src/assets/images/website/Frame.png")} className='icons' alt='Right Arrow' />
                  </div>
                </div>
              </div>
            </div>
            
            
            <div className='row m-0 align-items-stretch justify-content-center'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 slide-left visible'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/doctor6.png")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                    <div className='white-box1'>
                      <h1 style={{ color: 'black' }}>What to do if you think your <br/>child has the flu?</h1>
                      <div className='inner-contentss'>
                        <div className=''>
                          <p style={{ color: 'gray' }}>The patient portal is designed to manage primary & family members under the same account with a unique ID for each member.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-0 my-sm-0 my-2'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/doctor3.png")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                    <div className='white-box1'>
                      <h1 style={{ color: 'black' }}>WHO Investment Round <br/>Culminating moment at G20 <br/>Summit as leader Pledge</h1>
                      <div className='inner-contentss'>
                        <div className=''>
                          <p style={{ color: 'gray' }}>A doctor portal is designed based on the physician's specialty and can work for any specialization.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-2 my-sm-2 slide-right visible'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/doctor1.png")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                    <div className='white-box1'>
                      <h1 style={{ color: 'black' }}>Exclusive breastfeeding <br/>celebrating successes and <br/>accelerating progress by 2030 </h1>
                      <div className='inner-contentss'>
                        <div className=''>
                          <p style={{ color: 'gray' }}>A hospital portal is designed to manage multi-specialty and different consultation modes.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Webblog;